export default [
  {
    identifier: "WM",
    currency: "usd",
    bank: {
      recipient: "Lemon Way",
      recipientAddress: "8 Rue du Sentier",
      recipientCity: "Paris",
      recipientPostCode: "75002",
      recipientCountry: "France",
      name: "Barclays Bank Ireland Frankfurt",
      bankAddress: "Taunusturm Taunustor 1",
      bankCity: "Frankfurt",
      bankPostCode: "60310",
      bankCountry: "Germany",
      bic: "BARCDEFFXXX",
      iban: "DE32503104000438134914",
    },
  },
  {
    identifier: "WM",
    currency: "zar",
    bank: {
      recipient: "Pieter Goosen Attorneys Conveyancers and Notaries",
      recipientAddress: "",
      recipientCity: "",
      recipientPostCode: "",
      recipientCountry: "",
      name: "Capitec Business",
      bankAddress: "The Edge Building, Charl Cronje Drive, 3 Howick Close, Tyger Waterfront, Bellville",
      bankCity: "Tygerberg",
      bankPostCode: "7530",
      bankCountry: "South Africa",
      bic: "CABLZAJJ",
      iban: "",
      bankBranchCode: "450105",
      bankAccountNo: "1050949927",
      bankAccountType: "Current",
    },
  },
];
