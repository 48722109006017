import { takeLatest, put, call, take, race } from 'redux-saga/effects';

import entityService from '../../../api/entity.service';
import { USER_TYPES, SESSION_TYPES } from '../../types';
import Action from '../../actions/helpers/action';
import UserActions from '../../actions/user.actions';
import SessionActions from '../../actions/session.actions';
import SessionStore from '../../../utils/session-store';
import SessionContext from '../../../utils/session-context';
import UserService from '../../../api/user.service';

function* awaitRefreshToken() {
  yield put(SessionActions.refreshToken());

  const { success, fail } = yield race({
    success: take(SESSION_TYPES.REFRESH_TOKEN_SUCCESS),
    fail: take(SESSION_TYPES.REFRESH_TOKEN_FAILURE),
  });

  if (fail) {
    yield put(SessionActions.signout());
  }
}

/**
 * Redux Saga generator function for getting user entities
 */
function createNewCompanyEntity() {
  return function* (options) {
    try {
      const token = SessionStore.getSession();
      const userId = SessionContext.get(token).sub;

      const countryIsoCode = options.payload.countryIsoCode;
      const investorType = options.payload.investorType;
      const investorTypeCertified = options.payload.investorTypeCertified;
      const investorRiskAcceptedDate = options.payload.investorRiskAcceptedDate;
      const entityType = options.payload.entityType;
      const entityName = options.payload.entityName;
      const registrationNumber = options.payload.registrationNumber;


      const body = yield call(() => entityService.createCompanyEntityForUser(
        token,
        userId,
        countryIsoCode,
        investorType,
        investorTypeCertified,
        investorRiskAcceptedDate,
        entityType,
        entityName,
        registrationNumber
      ));

      const result = body.data[0];

      // Need to investigate where this link is used ("circular reference" because entity contains an 'owner' which is a user '_id')
      yield call(() => UserService.addUserEntity(
        token,
        userId,
        result._id, // eslint-disable-line
      ));

      yield awaitRefreshToken();

      const action = UserActions.createCompanyEntitySuccess(result);
      yield put(UserActions.getCanInvest(result._id)); //eslint-disable-line
      yield put(UserActions.selectActiveEntity(result));
      yield put(action);
    } catch (error) {
      const failureAction = UserActions.createCompanyEntityFailure(error);
      const apiErr = error.apiErrors.find(ele => ele.code === 1);
      if (error.code === 409 && error.apiErrors && apiErr) {
        yield put(Action.withDisplayMessage(failureAction, `${ apiErr.message }`));
      } else {
        yield put(failureAction);
      }
    }
  };
}

export const newEntity = createNewCompanyEntity();

export function* newCompanyEntityWatcher() {
  yield takeLatest(USER_TYPES.CREATE_COMPANY_ENTITY_BEGIN, newEntity);
}
