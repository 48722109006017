import { takeLatest, put, call } from 'redux-saga/effects';
import _ from 'lodash';

import { WALLETS_TYPES } from '../../types';
import WalletActions from '../../actions/wallets.actions';
import walletTransactions from '../../../api/wallet-transactions.service';
import productService from '../../../api/product.service';
import altInvService from '../../../api/alt-investment.service';
import SessionStore from '../../../utils/session-store';

import NumberExtensions from '../../../utils/NumberExtensions';
import DateExtensions from '../../../utils/DateExtensions';

/**
 * Redux Saga generator function for getting products
 */
function createGetWalletTransactions() {
  return function* (options) {
    try {
      const token = SessionStore.getSession();
      const entity_id = options.payload.entity_id;
      
      const body = yield call(() => walletTransactions.getTransactionsByEntityID(token, entity_id, options.payload.currency));

      const getCreditDescription = (type) => {
        switch (type.toLowerCase()) {
          case 'funding':
            return 'Funded wallet';
          case 'wallet':
            return 'Money transferred to wallet';
          case 'blocktrade':
            return 'Proceeds from block trade';
          case 'product':
            return 'Cancellation of IOI';
          case 'dividend':
            return 'Dividends received';
          case 'voucher':
            return 'Voucher received';
          case 'escrowdeposit':
            return 'Escrow deposit received';
          case 'interest':
            return 'Interest received';
          case 'returnofcapital':
            return 'Return of capital';
          case 'returnofcapitalexit':
            return 'Return of capital (exit)';
          case 'noncashflowreturnofcapitalexit':
            return 'Non-cashflow return of capital (exit)';
          case 'capitalgain':
            return 'Capital gain';
          case 'affiliatecommission':
            return 'Affiliate Commission';
          case 'reversal':
            return 'Reversal';
          default:
            return 'No transaction description available.';
        }
      };
      
      const getDebitDescription = (type) => {
        switch (type.toLowerCase()) {
          case 'wallet':
            return 'Money transferred from wallet';
          case 'withdraw':
            return 'Money withdrawn from wallet';
          case 'product':
            return 'Indication of interest';
          case 'blocktrade':
            return 'Indication of interest';
          case 'owneraffiliatefee':
            return 'Owner affiliate fee';
          case 'affiliatefee':
            return 'Affiliate fee';
          case 'productbasefee':
            return 'Product base fee';
          case 'productstructurefee':
            return 'Product structure fee';
          case 'marketingsalesfee':
            return 'Marketing sales fee';
          case 'cancellationfee':
            return 'Cancellation fee';
          case 'successfee':
            return 'Success fee';
          case 'capitalgainsuccessfee':
            return 'Capital gain success fee';
          case 'blocktradefee':
            return 'Initiation fee';
          case 'reversal':
            return 'Reversal';
          case 'initiationfee':
            return 'Initiation fee';
          case 'noncashflowdebttoequityconversion':
            return 'Non-cashflow debt to equity conversion';
          default:
            return 'No transaction description available.';
        }
      };

      const getReversalDescription = (type) => {
        const re = /([A-Z][^A-Z]+)/g //regex to find PascalCased reversal transaction types and split them into multiple words
        const desc = getDebitDescription(type)
        return desc === 'No transaction description available.' ? type.match(re).join(" ") : desc
      }

      const isValidInitiationFee = (type) => {
          switch (type.toLowerCase()) {
            case 'marketingsalesfee':
            case 'productstructurefee':
            case 'productbasefee':
              return true
            default:
              return false
          }
      }

      const rollUpInitiationFees = (trans) => {
        const excludeTransactionIds = []

        let totalFee = 0
        let trackedIdx = -1

        for (let index = 0; index < trans.length; index++) {
          const tran = trans[index];
          if (isValidInitiationFee(tran.destination?.type)) {             
             if (trackedIdx < 0)
                trackedIdx = index

             if (tran.destination.product_id === trans[trackedIdx]?.destination?.product_id && 
                DateExtensions.epochDateConverter(parseInt(tran.timestamp)) === DateExtensions.epochDateConverter(parseInt(trans[trackedIdx]?.timestamp))) {

                if (trackedIdx !== index)
                  excludeTransactionIds.push(tran._id)

            } else {
              if (totalFee > 0 ) {
                trackedIdx = index
                totalFee = 0
              }
            }
            totalFee = totalFee + tran.destination?.amount
            trans[trackedIdx].destination.type = 'InitiationFee'
            trans[trackedIdx].destination.amount = totalFee
          } else {
            if (totalFee > 0 ) {
              trackedIdx = -1
              totalFee = 0
            }
          }
        }
        
        return trans.filter(t => excludeTransactionIds.includes(t._id) === false)
      }

      const rollUpInitiationFeesReversal = (trans) => {
        const excludeTransactionIds = []

        let totalFee = 0
        let trackedIdx = -1
        for (let index = 0; index < trans.length; index++) {
          const tran = trans[index];
          if ((tran.source.type.toLowerCase() === 'reversal' && isValidInitiationFee(tran.source?.reversal_type))) {

             if (trackedIdx < 0)
                trackedIdx = index

             if (tran.source.product_id === trans[trackedIdx]?.source?.product_id && 
                DateExtensions.epochDateConverter(parseInt(tran.timestamp)) === DateExtensions.epochDateConverter(parseInt(trans[trackedIdx]?.timestamp))) {
                  if (trackedIdx !== index) excludeTransactionIds.push(tran._id)
              } else {
                if (totalFee > 0 ) {
                  trackedIdx = index
                  totalFee = 0
                }
              } 
              totalFee = totalFee + tran.source?.amount
              trans[trackedIdx].source.reversal_type = 'InitiationFee'
              trans[trackedIdx].source.amount = totalFee
              trans[trackedIdx].destination.amount = totalFee
          } else {
            if (totalFee > 0 ) {
              trackedIdx = -1
              totalFee = 0
            }
          } 
        }
        
        return trans.filter(t => excludeTransactionIds.includes(t._id) === false)
      }
      
      const applyInitiationFees = (trans) => {
        let rollUp = rollUpInitiationFees(trans) 
        return rollUpInitiationFeesReversal(rollUp)
      }

      const transactions =  applyInitiationFees(body.data)      

      // fetch all the unique products that is used in the transactions even if they are from another subsystem
      function onlyUnique(value, index, self) { 
        return self.indexOf(value) === index;
      }

      const ProductFeeTypes = [
        'MarketingSalesFee',
        'ProductStructureFee',
        'ProductBaseFee',
        'Cancellationfee',
        'InitiationFee', // this is a fake type to rollup totals
        'SuccessFee',
        'CapitalGainSuccessFee',
        'BlockTradeFee'
      ];

      const ProductInTypes = [
        'Dividend',
        'Interest',
        'ReturnOfCapital',
        'ReturnOfCapitalExit',
        'NonCashflowReturnOfCapitalExit',
        'CapitalGain',
        'Reversal'
      ];

      const uniqueProductIds = transactions.map(t => {
        if (t.destination.type === 'Product')
          return t.destination.id;

        if (ProductInTypes.includes(t.source.type))
          return t.source.product_id;

        return t.destination.product_id
      })?.filter(onlyUnique);

      const products_resp = yield call(() => productService.getProductsByIdCollection(token, uniqueProductIds))
      const alt_inv_resp = yield call(() => altInvService.getAltInvestmentsByIdCollection(token, uniqueProductIds))
      const products = products_resp?.data.concat(alt_inv_resp?.data);
      
      const processed_transactions = transactions.map(trans => {
        const isCredit = trans.destination.id === entity_id;
        const isProductOut = trans.destination.type === 'Product';
        const isBlockTrade = trans.destination.type === 'BlockTrade'
        const isProductFee = ProductFeeTypes.includes(trans.destination.type);
        const isProductIn = ProductInTypes.includes(trans.source.type);

        let productId = undefined;

        if(isProductOut) {
          productId = trans.destination.id;
        };
        
        if(isProductFee || isBlockTrade) {
          productId = trans.destination.product_id;
        };

        if(isProductIn) {
          productId = trans.source.product_id;
        };

        const transferType = isCredit ? isBlockTrade ? "blocktrade" : trans?.source?.type : trans?.destination?.type;
        const description = isCredit ? getCreditDescription(transferType) : getDebitDescription(transferType);

        const currency = trans.source.currency_iso;
        const amount = trans.destination.amount || 0;
        const type = isCredit ? 'credit' : 'debit';
        const displayDate = DateExtensions.epochDateConverter(parseInt(trans.timestamp));
        
        const getProductName = (i , p) => {
          if(i.length > 0 && p) {
            const product = i?.find((item) => item._id === p);
            const id = product?._id;
            const product_name = product ? product?.product_name < 20 ? product?.product_name : `${ product?.product_name?.substring(0, 20) }...` : '';
            return {
              id,
              product_name,
              published: product?.published,
            };
          };
          return undefined;
        }

        return {
            date: displayDate,
            product: isProductOut || isProductIn || isProductFee || isBlockTrade ? getProductName(products, productId) : '',
            transaction: `${description}${trans?.source?.reversal_type ? `: ${getReversalDescription(trans?.source?.reversal_type)}` : ''}`,
            status: trans.state,
            currency: currency,
            type: type,
            amount: NumberExtensions.toFixedString(amount),
        }
      });

      //GROUP TRANSACTIONS BY CURRENCY_ISO
      const trans = _.chain(processed_transactions).groupBy("currency").map((value, key) => ({currency_iso: key, transactions: value})).value()
      const grouped = trans.map(i => (
        {
          currency_iso: i.currency_iso,
          transactions: _.head(trans.filter(o => o.currency_iso === i.currency_iso))?.transactions || []
        }
        )
      )
      
      const action = WalletActions.successTransactions(grouped);
      yield put(action);
    } catch (error) {
      const failureAction = WalletActions.errorTransactions(error);
      yield put(failureAction);
    }
  };
}

export const getTransactions = createGetWalletTransactions();

export function* getWalletTransactionsWatcher() {
  yield takeLatest(WALLETS_TYPES.GETWALLETTRANSACTIONS_BEGIN, getTransactions);
}
