class Effect {
  /**
   * Creates a new effect for use with Redux
   * @example
   * Effect.Create(function* (options) { yield; })
   */
  static create = (
    onSuccessFn = function () { },
    onErrorFn = undefined) => {
    return {
      onSuccess: onSuccessFn,
      onError: onErrorFn,
    };
  };

  /**
   * Trys to get an effect or defaults
   * @example
   * Effect.Create(function* (options) { yield; })
   */
  static getOrDefault = (effect) => {
    if (effect) {
      return effect;
    }

    return function* () { yield; };
  };
}

export default Effect;
