import { LISTER_TYPES } from '../types';
import Action from './helpers/action';

export default {
  fetchListerBegin: () => {
    return Action.create(LISTER_TYPES.FETCH_LISTER_BEGIN);
  },
  fetchListerSuccess: (payload) => {
    return Action.create(LISTER_TYPES.FETCH_LISTER_SUCCESS, { payload });
  },
  fetchListerError: (errorObj) => {
    return Action.createError(LISTER_TYPES.FETCH_LISTER_ERROR, { error: errorObj });
  },
  fetchListerFinish: () => {
    return Action.createError(LISTER_TYPES.FETCH_LISTER_FINISH);
  }
}
