import { takeLatest, put, call, take, race } from 'redux-saga/effects';

import entityService from '../../../api/entity.service';
import { USER_TYPES, SESSION_TYPES } from '../../types';
import UserActions from '../../actions/user.actions';
import SessionActions from '../../actions/session.actions';
import SessionStore from '../../../utils/session-store';
import SessionContext from '../../../utils/session-context';
import UserService from '../../../api/user.service';
import RulesService from '../../../api/rules.service';

function* awaitRefreshToken() {
  yield put(SessionActions.refreshToken());

  const { fail } = yield race({
    success: take(SESSION_TYPES.REFRESH_TOKEN_SUCCESS),
    fail: take(SESSION_TYPES.REFRESH_TOKEN_FAILURE),
  });

  if (fail) {
    yield put(SessionActions.signout());
  }
}

/**
 * Redux Saga generator function for getting user entities
 */
function createNewPersonalEntity() {
  return function* (options) {
    try {
      const token = SessionStore.getSession();
      const userId = SessionContext.get(token).sub;
      const {
        countryIsoCode,
        investorType,
        investorTypeCertified,
        investorRiskAcceptedDate,
        email,
        domainName,
        subsystem,
      } = options.payload;

      const body = yield call(() => entityService.createPersonalEntityForUser(
        token,
        userId,
        countryIsoCode,
        investorType,
        investorTypeCertified,
        investorRiskAcceptedDate,
        email));

      const result = body.data[0];

      //We don't expect newly created personal entities to have orders or a positive wallet balance, so only passing in the enity_id is fine when doing this can-invest check.
      const canInvestBody = yield call(() => RulesService.getCanInvestData(token, result._id)); //eslint-disable-line
      const canInvest = canInvestBody;

      // need to patch the user. (will be removed in a future version)
      yield call(() => UserService.addUserEntity(
        token,
        userId,
        result._id, // eslint-disable-line
      ));

      yield awaitRefreshToken();

      if (canInvest) {
        if (canInvest?.data?.have_access) {
          const newToken = SessionStore.getSession();
          const ss = subsystem || SessionStore.getSubsystem();
          yield call(() => UserService.emailVerifyRequest(newToken, userId, email, 'platform', ss, domainName));
        }
        yield put(UserActions.getCanInvestSuccess(canInvest));
      }
      const action = UserActions.createPersonalEntitySuccess(result);
      yield put(action);
    } catch (error) {
      const failureAction = UserActions.createPersonalEntityFailure(error);

      yield put(failureAction);
    }
  };
}

export const newEntity = createNewPersonalEntity();

export function* newPersonalEntitiesWatcher() {
  yield takeLatest(USER_TYPES.CREATE_PERSONAL_ENTITY_BEGIN, newEntity);
}
