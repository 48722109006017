import { takeLatest, put, call } from 'redux-saga/effects';

import { DOCUMENT_TYPES } from '../../types';
import DocumentActions from '../../actions/document.actions';
import documentService from '../../../api/document.service';
import SessionStore from '../../../utils/session-store';

/**
 * Redux Saga generator function for getting documents bi specific ids
 */
function createGetDocuments() {
  return function* (options) {
    try {
      const token = SessionStore.getSession();
      const ids = options.payload.ids;

      const body = yield call(() => documentService.getDocumentsById(token, ids));
      const documents = body.data;
      const action = DocumentActions.getDocumentsByIdSuccess(documents);

      yield put(action);
    } catch (error) {
      const failureAction = DocumentActions.getDocumentsByIdFailure(error);
      yield put(failureAction);
    }
  };
}

export const getDocuments = createGetDocuments();

export function* getDocumentsByIdWatcher() {
  yield takeLatest(DOCUMENT_TYPES.GETDOCUMENTS_BY_ID_BEGIN, getDocuments);
}
