export const PORTFOLIO_TYPES = {
  GET_PORTFOLIO_BEGIN: 'GET_PORTFOLIO_BEGIN',
  GET_PORTFOLIO_FAILURE: 'GET_PORTFOLIO_FAILURE',
  GET_PORTFOLIO_SUCCESS: 'GET_PORTFOLIO_SUCCESS',

  CREATE_IOI_BEGIN: 'CREATE_IOI_BEGIN',
  CREATE_IOI_ERROR: 'CREATE_IOI_ERROR',
  CREATE_IOI_CLEAR_ERROR: 'CREATE_IOI_CLEAR_ERROR',
  CREATE_IOI_SUCCESS: 'CREATE_IOI_SUCCESS',

  CANCEL_IOI_BEGIN: 'CANCEL_IOI_BEGIN',
  CANCEL_IOI_SUCCESS: 'CANCEL_IOI_SUCCESS',
  CANCEL_IOI_FAILURE: 'CANCEL_IOI_FAILURE',
};
