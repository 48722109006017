import { takeLatest, put, call } from 'redux-saga/effects';

import UserService from '../../../api/user.service';
import { USER_TYPES } from '../../types';
import Action from '../../actions/helpers/action';
import UserActions from '../../actions/user.actions';

import SessionStore from '../../../utils/session-store';
import SessionContext from '../../../utils/session-context';

/**
 * Redux Saga generator function for getting user entities
 */
function createSendVerificationEmail() {
  return function* (options) {
    try {
      const token = SessionStore.getSession();
      const subsystem = SessionStore.getSubsystem();
      const userId = SessionContext.get(token).sub;
      const email = options.payload.email;
      const domainName = options.payload.domainName;

      yield call(() => UserService.emailVerifyRequest(token, userId, email, 'platform', subsystem, domainName));

      const action = Action.withDisplayMessage(UserActions.sendVerificationEmailSuccess(),
        'Email verification link successfully sent.');

      yield put(action);
    } catch (error) {
      const failureAction = UserActions.sendVerificationEmailFailure(error);
      yield put(failureAction);
    }
  };
}

export const sendVerificationEmail = createSendVerificationEmail();

export function* sendVerificationEmailWatcher() {
  yield takeLatest(USER_TYPES.VERIFICATION_EMAIL_SEND_BEGIN, sendVerificationEmail);
}
