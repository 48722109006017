import { takeLatest, put, call } from 'redux-saga/effects';

import { USER_TYPES } from '../../types';
import SessionStore from '../../../utils/session-store';
import EntityService from '../../../api/entity.service';
import UserActions from '../../actions/user.actions';

/**
 * Redux Saga generator function for withdrawing funds
 */
function createGetBankAccounts() {
  return function* (options) {
    try {
      const token = SessionStore.getSession();
      const { entityId } = options.payload

      const body =  yield call(() => EntityService.getBankAccountsforEntity(token, entityId));
      yield put(UserActions.getBankAccountsSuccess(body.data));
    } catch (error) {
      const failureAction = UserActions.getBankAccountsFailure(error);
      yield put(failureAction);
    }
  };
}

export const getBankAccounts = createGetBankAccounts();

export function* getBankAccountsWatcher() {
  yield takeLatest(USER_TYPES.GET_BANK_ACCOUNT_BEGIN, getBankAccounts);
}
