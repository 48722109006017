import { takeLatest, put, call } from 'redux-saga/effects';

import { INVESTMENTS_TYPES } from '../../types';
import InvestmentsActions from '../../actions/investments.actions';
import rulesService from '../../../api/rules.service';
import SessionStore from '../../../utils/session-store';

export function* getMinimums(action) {
  try {
    const token = SessionStore.getSession();
    const subsystem = SessionStore.getSubsystem();
    const {
      entityId,
      productIds,
    } = action.payload;

    const minimums= yield call(() => rulesService.getMinimumInvestmentData(token, entityId, subsystem, productIds));

    yield put(InvestmentsActions.getMinimumsSuccess(minimums));

  } catch (error) {
    yield put(InvestmentsActions.getMinimumsError(error));
  }
}

export function* getMinimumsWatcher() {
  yield takeLatest(INVESTMENTS_TYPES.GET_PRODUCT_MINIMUMS_BEGIN, getMinimums);
}
