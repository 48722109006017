export default [
  {
    accepted_date: undefined,
    name: 'wealth-migrate',
    label: `I agree with the WealthPoint <a href="https://wealthpoint.app/terms-and-conditions/" target="_blank" rel="noreferrer nofollow">Terms & Conditions</a>`,
    revision_date: 1581340123679,
  },
  {
    accepted_date: undefined,
    name: 'lemon-way',
    label: `I agree with the Lemon Way <a href="https://www.lemonway.com/en/terms-and-conditions/" target="_blank" rel="noreferer nofollow">Terms & Conditions</a>`,
    revision_date: 1581340123681,
  },
];