import { takeLatest, put, call } from 'redux-saga/effects';

import DepositReferenceService from '../../../api/deposit-reference.service';
import { PLEDGE_TYPES } from '../../types/pledge.types';
import PledgeActions from '../../actions/pledge.actions';

import SessionStore from '../../../utils/session-store';

function deleteDepositReferences() {
  return function* (options) {
    try {
      const token = SessionStore.getSession();
      const referenceId = options.payload.referenceId;

      const body = yield call(() => DepositReferenceService.getDepositReferenceById(token, referenceId));
      yield call(() => DepositReferenceService.deleteDepositReference(token, referenceId));

      const result = body.data[0];

      yield put(PledgeActions.deleteDepositReferenceSuccess(result));
    } catch (error) {
      yield put(PledgeActions.deleteDepositReferenceFailure(error));
    }
  };
}

const newDeleteDepositReferences = deleteDepositReferences();

export function* deleteDepositReferencesWatcher() {
  yield takeLatest(PLEDGE_TYPES.DELETE_DEPOSIT_REFERENCE_BEGIN, newDeleteDepositReferences);
}
