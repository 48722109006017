import { takeLatest, put, call } from 'redux-saga/effects';

import userService from '../../../api/user.service';
import { SESSION_TYPES } from '../../types';
import SessionActions from '../../actions/session.actions';
import SessionStore from '../../../utils/session-store';
import Action from '../../actions/helpers/action';
/**
 * Redux Saga generator function for sign in
 */
function createVerifyEmail() {
  return function* (options) {
    try {
      const token = options.payload.token;

      const system = process.env.REACT_APP_API_SYSTEM;
      const subsystem = options.payload.subsystem || process.env.REACT_APP_API_SUBSYSTEM;

      const body = yield call(() => userService.emailVerifyComplete(token, system, subsystem));
      const result = body.data[0];

      const successAction = SessionActions.verifyEmailSuccess();

      yield put(successAction);

      SessionStore.setSession(result.access_token, result.refresh_token, subsystem);

      yield put(SessionActions.hydrateState()); //hydrating state here to refresh the entity's info
    } catch (error) {
      const failureAction = SessionActions.verifyEmailFailure(error);
      yield put(Action.withDisplayMessage(failureAction, 'Verification mails are only valid for 24 hours. It is possible your token has expired or is invalid.'));
    }
  };
}

export const verifyEmail = createVerifyEmail();

export function* getVerifyEmailWatcher() {
  yield takeLatest(SESSION_TYPES.VERIFY_EMAIL_BEGIN, verifyEmail);
}
