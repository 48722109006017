import PATH from '../paths';

const name = 'EquityMultiple';
const key = 'equitymultiple';
const imgUrl = `${ PATH.CDN }/whitelabels/${ key }`;
const images = {
  favicon: `${ imgUrl }/favicon.png`,
  logo: `${ imgUrl }/logo.png`,
  logoPDF: `${ imgUrl }/logo-pdf.png`,
  logoSidebar: `${ imgUrl }/logo-sidebar.png`,
  opengraph: false,
  backgrounds: [
    `${ imgUrl }/background.jpg`
  ],
};

export default {
  name,
  key,
  images,
  stylesheet: `
    /* COLOR VARIABLES +++++++++++++++++++++++++++++++++++++++++++++++++++++  */


    --color-em-blue:                  #062164;
    --color-em-blue-light:            #0f3893;
    --color-em-logo-blue:             #3390e2;
    --color-em-light:                 #f7f6f0;
    --color-em-cyan:                  #6ad7c7;
    --color-em-cyan-light:            #c9f5eb;
    --color-em-cyan-dark:             #1a7164;
    --color-em-sky-blue:              #00a9df;
    --color-em-sky-blue-lighter:      #56b9d9;

    --color-primary:                  var(--color-em-blue);
    --color-secondary:                var(--color-em-sky-blue);
    --color-accent:                   var(--color-white);

    /* END +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++  */

    /* TYPOGRAPHY ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++  */

    --color-link:                     var(--color-em-sky-blue);
    --color-link-hover:               var(--color-em-blue);

    /* END +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++  */
   
    /* LAYOUTS +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++  */

    /* ANONYMOUS LAYOUT */

    --color-body                      var(--color-em-light);

    --anonymous-logo-bg:              var(--color-white);
    --anonymous-copy-bg:              var(--color-em-sky-blue);
    --anonymous-heading-color:        var(--color-white);
    --anonymous-subheading-color:     var(--color-white);
    --anonymous-text-color            var(--color-white);
    --anonymous-logo-height:          70px;
    --anonymous-bg:                   url(${ images.backgrounds[0] });
    --anonymous-bg-size:              auto 92%;


    /* END */

    /* SIDEBAR */

    --sidebar-bg-color:                    var(--color-em-sky-blue);
    --sidebar-logo-bg-color:               var(--color-white);
    --sidebar-logo-height:                 45px;
    --sidebar-label-color:                 var(--color-white);
    --sidebar-border-color:                var(--color-white);
    --sidebar-button-color:                var(--color-white);
    --sidebar-button-color-active:         var(--color-em-sky-blue);
    --sidebar-button-color-hover:          var(--color-em-sky-blue);
    --sidebar-button-bg-color:             transparent;
    --sidebar-button-bg-color-active:      var(--color-white);
    --sidebar-button-bg-color-hover:       var(--color-white);
    --sidebar-button-border-color:         var(--color-white);
    --sidebar-button-border-color-active:  var(--color-white);
    --sidebar-button-border-color-hover:   transparent;

    /* END */

    /* END +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++  */

    /* BUTTONS +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++  */

    /* PRIMARY BUTTON */

    --btn-primary--bg:                var(--color-em-sky-blue);
    --btn-primary--border:            transparent;
    --btn-primary--color:             var(--color-white);
    --btn-primary--colorH:            var(--color-white);
    --btn-primary--bgH:               var(--color-em-sky-blue-lighter);
    --btn-primary--borderH:           transparent;

    /* END */

    /* SECONDARY BUTTON */

    --btn-secondary--bg:              var(--color-em-blue);
    --btn-secondary--border:          tranparent;
    --btn-secondary--color:           var(--color-white);
    --btn-secondary--bgH:             var(--color-em-blue-light);
    --btn-secondary--borderH:         transparent;
    --btn-secondary--colorH:          var(--color-white);

    /* END */

    /* END +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++  */
  `,
};
