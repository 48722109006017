import 'isomorphic-fetch';
import { fetchResource } from './fetch-resource';

import { API } from '../constants';

/**
 * Product API service
 */
class KYCService {
  static getCountryBankingFields = (jwt, iso_2_code) => {
    const requestConfig = {
      cache: 'no-cache',
      mode: 'cors',
      headers: {
        'content-type': 'application/json',
        'authorization': `bearer ${jwt}`,
      },
      method: 'GET',
    };

    return fetchResource(`${API.KYC}/countrybankingfields?iso_2_code=${iso_2_code}`, requestConfig);
  }
}

export default KYCService;
