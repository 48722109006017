import { takeLatest, put, call } from 'redux-saga/effects';

import UserService from '../../../api/user.service';
import { USER_TYPES } from '../../types';
import UserActions from '../../actions/user.actions';
import SessionStore from '../../../utils/session-store';

function createGetUserAgreements(){
  return function* (options) {
    try {
      const token = SessionStore.getSession()
      const body = yield call(() => UserService.getUserAgreements(token));
      const successAction = UserActions.getUserAgreementsSuccess(body.data);
      yield put(successAction);
    } catch (e) {
      const failureAction = UserActions.getUserAgreementsFailure({error: e});
      yield put(failureAction);
    }
  }
}

export const getUserAgreements = createGetUserAgreements();

export function* getUserAgreementsWatcher() {
  yield takeLatest(USER_TYPES.GET_USER_AGREEMENTS_BEGIN, getUserAgreements);
}