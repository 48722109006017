import React, { useState, useRef, useMemo } from 'react';
import propTypes from 'prop-types';
import Sticker from '@traaidmark/konstruct-part-sticker';
import { AngleRightIcon } from '@wealthmigrate/architect-icons';

import { useOnClickOutside, useHover } from '../../hooks';

import PopupNav from '../PopupNav';

import './stylesheet.css';

const EntitySwitcher = ({ 
  entities = [],
  activeEntity = undefined,
  switchActiveEntity,
}) => {

  const ref = useRef();

  const [isHidden, setState] = useState(true);

  useOnClickOutside(ref, () => setState(true));

  const handleClick = (entity) => {
    switchActiveEntity(entity);
    setState(true);
  };

  const kycClass = (status) => {

    if(status === 'Pending Verification') {
      return 'p-flag--aware';
    };

    if(status === 'KYC Ready' || status === 'KYC Approved') {
      return 'p-flag--new';
    };

    return 'p-flag--important';
  }

  const options = entities.map((i,k) => (
    <button
      onClick={ () => handleClick(i) } 
      key={ `entity-switcher-${ k }` }
      className={ i._id === activeEntity?._id ? 'isActive' : '' }
    >
      <h5>{ i.name.length > 25 ? i.name.slice(0, 25).concat('...') : i.name }</h5>
      { i.email && <small>{ i.email }</small> }
      <ul className="u-list-horizontal">
        <li>
          <small className="p-flag">{ i.content_type }</small>
        </li>
        <li>
          <small className={ `p-flag ${ kycClass(i.kyc_status) }` }>{ i.kyc_status }</small>
        </li>
      </ul>
      
    </button>
  ));

  const navItems = [
    <h5 data-pendo="tooltip-profile-switcher">Profile Switcher</h5>,
    ...options
  ]

  return (
    <div class="c-entity-switcher" ref={ ref }>
      <button className={ `c-entity-switcher__button ${ isHidden ? '' : 'c-entity-switcher__button--open' }` } onClick={ () => isHidden ? setState(false) : setState(true) }>
        <Sticker
          label="Viewing profile as:"
          value={ 
            activeEntity?.name ? activeEntity?.name : activeEntity?.email
          }
          placement="top"
          align="left"
          size="small"
          data-pendo="pendo-entity-switcher"
        />
        <AngleRightIcon />
      </button>
      <PopupNav
        isHidden={ isHidden }
        items={ navItems }
        orientation="right"
      />
    </div>
  );
};


EntitySwitcher.propTypes = {
  activeEntity: propTypes.object,
  entities: propTypes.array,
  switchActiveEntity: propTypes.func,
  getCanInvest: propTypes.func,
};

export default EntitySwitcher;
