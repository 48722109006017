import 'isomorphic-fetch';
import { fetchResource } from './fetch-resource';

import { API } from '../constants';

/**
 * Entity API service
 */
class EntityService {
  /**
   * Retrieves owned entities for a user
   * @example
   * getEntitiesForUser('someJwt', 'some-user-id').then(res => { return res.data }).then(...)
   * @returns {Promise} Returns a promise containing the result in json format.
   */
  static getEntitiesForUser = (jwt, userId) => {
    const requestConfig = {
      cache: 'no-cache',
      mode: 'cors',
      headers: {
        'content-type': 'application/json',
        'authorization': `bearer ${ jwt }`,
      },
      method: 'GET',
    };

    return fetchResource(`${ API.ENTITIES }?owner=${ userId }&limit=1000`, requestConfig);
  }

  static createBankAccountsforEntity = (jwt, bankAccount) => {
    const requestConfig = {
      cache: 'no-cache',
      mode: 'cors',
      headers: {
        'content-type': 'application/json',
        'authorization': `bearer ${ jwt }`,
      },
      body: JSON.stringify(bankAccount),
      method: 'POST',
    };

    return fetchResource(`${ API.ENTITIES }/bank_accounts`, requestConfig);
  }

  static getBankAccountsforEntity = (jwt, entityId) => {
    const requestConfig = {
      cache: 'no-cache',
      mode: 'cors',
      headers: {
        'content-type': 'application/json',
        'authorization': `bearer ${ jwt }`,
      },
      method: 'GET',
    };

    return fetchResource(`${ API.ENTITIES }/bank_accounts?owner=${ entityId }&limit=1000`, requestConfig);
  }

  static updateBankAccount = (jwt, bankAccountId, patches) => {
    const requestConfig = {
      cache: 'no-cache',
      mode: 'cors',
      headers: {
        'content-type': 'application/json',
        'authorization': `bearer ${ jwt }`,
      },
      body: JSON.stringify(patches),
      method: 'PATCH',
    };

    return fetchResource(`${ API.ENTITIES }/bank_accounts/${bankAccountId}`, requestConfig);
  }

  /**
   * Creates an entity for a user
   * @example
   * @returns {Promise} Returns a promise containing the result in json format.
   */
  static createPersonalEntityForUser = (jwt, userId, residentCountryIso, investorType, investorTypeCertified, investorRiskAcceptedDate, email) => {
    const body = {
      owner: userId,
      content_type: 'personal',
      email,
      content: {
        country: residentCountryIso,
        investor_type: investorType,
        investor_type_profile: investorTypeCertified,
        investor_risks_accepted_date: investorRiskAcceptedDate,
      },
    };

    const requestConfig = {
      cache: 'no-cache',
      mode: 'cors',
      headers: {
        'content-type': 'application/json',
        'authorization': `bearer ${ jwt }`,
      },
      method: 'POST',
      body: JSON.stringify(body),
    };

    return fetchResource(`${ API.ENTITIES }`, requestConfig);
  }

  static createCompanyEntityForUser = (jwt, userId, countryOfIncorp, investorType, investorTypeCertified, investorRiskAcceptedDate, entityType, name, registrationNumber) => {
    const body = {
      owner: userId,
      content_type: entityType,
      name,
      content: {
        registration_no: registrationNumber,
        country_of_incorporation: countryOfIncorp,
        investor_type: investorType,
        investor_type_profile: investorTypeCertified,
        investor_risks_accepted_date: investorRiskAcceptedDate,
      },
    };

    const requestConfig = {
      cache: 'no-cache',
      mode: 'cors',
      headers: {
        'content-type': 'application/json',
        'authorization': `bearer ${ jwt }`,
      },
      method: 'POST',
      body: JSON.stringify(body),
    };

    return fetchResource(`${ API.ENTITIES }`, requestConfig);
  }

  /**
   * Updates an entity
   * @example
   * @returns {Promise} Returns a promise containing the result in json format.
   */
  static updateEntity = (jwt, entityId, patches) => {
    const body = patches;

    const requestConfig = {
      cache: 'no-cache',
      mode: 'cors',
      headers: {
        'content-type': 'application/json',
        'authorization': `bearer ${ jwt }`,
      },
      method: 'PATCH',
      body: JSON.stringify(body),
    };

    return fetchResource(`${ API.ENTITIES }/${ entityId }`, requestConfig);
  }
  /**
   * gets an entity's access and investment permissions
   * @example
   * @returns {Promise} Returns a promise containing the result in json format.
   * @deprecated - Use the function defined in the Rules service instead
   */
  static getCanInvest = (jwt, entityId, product, orders, wallet) => {
    const body = {
      product,
      orders,
      wallet,
    }

    const requestConfig = {
      cache: 'no-cache',
      mode: 'cors',
      headers: {
        'content-type': 'application/json',
        'authorization': `bearer ${ jwt }`,
      },
      method: 'POST',
      body: JSON.stringify(body),
    };

    return fetchResource(`${ API.ENTITIES }/${ entityId }/caninvest`, requestConfig);
  }
}

export default EntityService;
