import { takeLatest, put, call } from 'redux-saga/effects';

import { DOCUMENT_TYPES } from '../../types';
import DocumentActions from '../../actions/document.actions';
import documentService from '../../../api/document.service';
import SessionStore from '../../../utils/session-store';

/**
 * Redux Saga generator function for uploading documents
 */
function createUploadDocument() {
  return function* (options) {
    try {
      const token = SessionStore.getSession();
      const file = options.payload.document;
      const associatedId = options.payload.associatedId;
      const renderInline = options.payload.renderInline;
      const tags = options.payload.tags;
      const country = options.payload.country;


      const body = yield call(() => documentService.uploadFile(token, file, associatedId, tags, country, true, renderInline));
      const document = body.data[0];
      const action = DocumentActions.uploadDocumentSuccess(document);

      yield put(action);
    } catch (error) {
      const failureAction = DocumentActions.uploadDocumentFailure(error);
      yield put(failureAction);
    }
  };
}

export const uploadDocument = createUploadDocument();

export function* getDocumentUploadWatcher() {
  yield takeLatest(DOCUMENT_TYPES.UPLOAD_DOCUMENT_BEGIN, uploadDocument);
}
