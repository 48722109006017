import { INVESTMENTS_TYPES } from '../types';
import Action from './helpers/action';

export default class InvestmentsActions {
  /**
   * Retrieve all investments
   * @example
   * getAll()
   */
  static getAll = (entityId) => {
    return Action.create(INVESTMENTS_TYPES.GET_INVESTMENTS_BEGIN, { entityId });
  }

  /**
   * Retrieve all investments succeeded
   * @example
   * success([{payload}])
   */
  static success = (investments) => {
    return Action.create(INVESTMENTS_TYPES.GET_INVESTMENTS_SUCCESS, { investments });
  }

  /**
   * Retrieve all investments failed.
   * @example
   * error(errorObj)
   */
  static error = (errorObj) => {
    return Action.createError(INVESTMENTS_TYPES.GET_INVESTMENTS_FAILURE, { error: errorObj });
  }

  static getMinimums = (entityId, productIds) => {
    return Action.create(INVESTMENTS_TYPES.GET_PRODUCT_MINIMUMS_BEGIN, { entityId, productIds });
  }
  static getMinimumsSuccess = (minimums) => {
    return Action.create(INVESTMENTS_TYPES.GET_PRODUCT_MINIMUMS_SUCCESS, { minimums });
  }
  static getMinimumsError = (errorObj) => {
    return Action.createError(INVESTMENTS_TYPES.GET_PRODUCT_MINIMUMS_FAILURE, { error: errorObj });
  }

  static getFees = ( productIds ) => {
    return Action.create(INVESTMENTS_TYPES.GET_PRODUCT_FEES_BEGIN, { productIds });
  }
  static getFeesSuccess = (fees) => {
    return Action.create(INVESTMENTS_TYPES.GET_PRODUCT_FEES_SUCCESS, { fees });
  }
  static getFeesError = (errorObj) => {
    return Action.createError(INVESTMENTS_TYPES.GET_PRODUCT_FEES_FAILURE, { error: errorObj });
  }
}
