import { takeLatest, put, call, all } from 'redux-saga/effects';
import _ from 'lodash';

import { PORTFOLIO_TYPES } from '../../types';

import bookbuildService from '../../../api/bookbuild.service';
import productService from '../../../api/product.service';
import altInvService from '../../../api/alt-investment.service';
import exitedInvService from '../../../api/portfolio-exited-investments.service';
import DepositReferenceService from '../../../api/deposit-reference.service';
import PortfolioActions from '../../actions/portfolio.actions';
import SessionStore from '../../../utils/session-store';
import SessionContext from '../../../utils/session-context';

/**
 * Redux Saga generator function for entity portfolio
 */
function createGetEntityPortfolio() {
  return function* (options) {
    try {

      const { entityId } = options.payload;

      const token = SessionStore.getSession();
      const userId = SessionContext.get(token)?.sub

      const {
        depositReferences,
        entityIndications,
        entityOrders, 
        exitedInvestments,
        } = yield all({
        depositReferences: call(() => DepositReferenceService.getDepositReferenceByQuery(token, [`user_id=${userId}`])),
        entityIndications: call(() => bookbuildService.getEntityIndications(token, entityId)),
        entityOrders: call(() => bookbuildService.getEntityOrders(token, entityId)),
        exitedInvestments: exitedInvService.getExitedInvestmentsByEntityID(token, entityId),
      });

      const pledges = depositReferences.data;
      const indications = entityIndications.data;
      const orders = entityOrders.data;
      const exitedInvs = exitedInvestments.data;

      const uniqueProdIds = _.uniq([
        ...pledges.map(p => p.product_id),
        ...indications.map(i => i.product_id),
        ...orders.map(f => f.product_id),
        ...exitedInvs.map(f => f.order?.product_id)
      ]);

      const {
        portfolioProducts,
        portfolioAltInv
      } = yield all({
        portfolioProducts: call(() => productService.getProductsByIdCollection(token, uniqueProdIds, ['_id', 'product_name', 'structure_provider', 'currency', 'irr', 'capital_gain', 'targeted_return', 'listing_date', 'closing_date', 'investment_term', 'projected_exit_date', 'status', 'published', 'partial'])),
        portfolioAltInv: call(() => altInvService.getAltInvestmentsByIdCollection(token, uniqueProdIds, ['_id', 'product_name', 'structure_provider', 'currency', 'irr', 'capital_gain', 'targeted_return', 'listing_date', 'closing_date', 'investment_term', 'projected_exit_date', 'status', 'published', 'partial'])) //TODO: enable by field query for Alt Inv BE
      });

      const products = portfolioProducts.data.concat(portfolioAltInv.data);

      const createProductAssociation = (object, productsCollection) => {
        return object.map(item => {
          return {
            ...item,
            // eslint-disable-next-line no-underscore-dangle
            associatedProduct: item.product_id ? productsCollection?.find(product => product._id === item.product_id) : productsCollection?.find(product => product._id === item.order.product_id),
          };
        });
      };

      const allPledges = createProductAssociation(pledges, products);
      const allIndications = createProductAssociation(indications, products);
      const allOrders = createProductAssociation(orders, products);
      const allExited = createProductAssociation(exitedInvs, products);

      const filterDeals = (items, byPortfolio = true) => {
        if (byPortfolio) {
          return items ? items?.filter(i => i?.associatedProduct?.structure_provider === 'Wealth Migrate') : [];
        }
        return items ? items?.filter(i => i?.associatedProduct?.structure_provider !== 'Wealth Migrate') : [];
      };

      const portfolioIndications = filterDeals(allIndications, true);
      const portfolioOrders = filterDeals(allOrders, true);
      const portfolioExited = filterDeals(allExited, true);

      const indieIndications = filterDeals(allIndications, false);
      const indieOrders = filterDeals(allOrders, false);
      const indieExited = filterDeals(allExited, false);

      const pi = _.chain(portfolioIndications).groupBy("currency_iso").map((value, key) => ({currency_iso: key, indications: value})).value()
      const po = _.chain(portfolioOrders).groupBy("currency_iso").map((value, key) => ({currency_iso: key, orders: value})).value()
      const pe = _.chain(portfolioExited).groupBy("order.currency_iso").map((value, key) => ({currency_iso: key, exited: value})).value()

      const indieObj = (allPledges.filter(p => p.entity_id === entityId).length > 0 || indieIndications.length > 0 || indieOrders.length > 0 || indieExited.length > 0) ? [{
        type: 'indie',
        pledges: allPledges.filter(p => p.entity_id === entityId),
        indications: indieIndications,
        orders: indieOrders,
        exited: indieExited
      }] : [];

      const portfolios = _.uniq([...pi, ...po, ...pe].map(gi => gi.currency_iso))
        .map( curr_iso => {
        const sum = (x, y) => x + y
        const indications = _.head(pi.filter(i => i.currency_iso === curr_iso))?.indications || [];
        const orders = _.head(po.filter(o => o.currency_iso === curr_iso))?.orders || [];
        const exited = _.head(pe.filter(e => e.currency_iso === curr_iso))?.exited || [];
        const i_sum = indications.map(i => i.amount).reduce(sum, 0)
        const o_sum = orders.map(i => i.amount).reduce(sum, 0)
        const e_sum = exited.map(i => i.order.amount).reduce(sum, 0)
        return {
          type: curr_iso,
          indications,
          orders,
          exited,
          totals: {
            indications: i_sum,
            orders: o_sum,
            exited: e_sum,
            total: i_sum + o_sum
          }
        }
      }).concat(indieObj);

      const successAction = PortfolioActions.success(portfolios);

      yield put(successAction);
    } catch (error) {
      const failureAction = PortfolioActions.error(error);
      yield put(failureAction);
    }
  };
}

export const getEntityPortfolio = createGetEntityPortfolio();

export function* getEntityPortfolioWatcher() {
  yield takeLatest(PORTFOLIO_TYPES.GET_PORTFOLIO_BEGIN, getEntityPortfolio);
}
