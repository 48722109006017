import { put, call, takeEvery } from 'redux-saga/effects';

import { DOCUMENT_TYPES } from '../../types';
import DocumentActions from '../../actions/document.actions';
import documentService from '../../../api/document.service';
import SessionStore from '../../../utils/session-store';

/**
 * Redux Saga generator function for getting documents
 */
function createGetDocuments() {
  return function* (options) {
    try {
      const token = SessionStore.getSession();
      const associatedId = options.payload.associatedId;

      const body = yield call(() => documentService.getDocumentsForListing(token, associatedId));
      const documents = body.data?.filter(doc => doc.render_inline === false);
      const action = DocumentActions.success(documents);

      yield put(action);
    } catch (error) {
      const failureAction = DocumentActions.error(error);
      yield put(failureAction);
    }
  };
}

export const getDocuments = createGetDocuments();

export function* getDocumentWatcher() {
  yield takeEvery(DOCUMENT_TYPES.GETDOCUMENTS_BEGIN, getDocuments);
}
