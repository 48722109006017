import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

const QuoteBlock = ({
  data = {},
}) => {

  return (
    <blockquote>
      { data.text && <p>"{ data.text }"</p> }
      <footer>
      { data.author && <h4>- { data.author }</h4> }
      { data.author_description && <small>{ data.author_description }</small> }
      </footer>
    </blockquote>
  );
};

QuoteBlock.propTypes = {
  data: PropTypes.object,
};

export default QuoteBlock;
