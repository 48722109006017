import { takeLatest, put, call } from 'redux-saga/effects';

import { SESSION_TYPES } from '../../types';
import SessionActions from '../../actions/session.actions';
import Effect from '../../actions/helpers/effect';

import UserService from '../../../api/user.service';

/**
 * Redux Saga generator function for getting user entities
 */
function createRequestPassword() {
  return function* (options) {
    try {
      const body = yield call(() => UserService.passwordResetRequest(options.payload.email, options.payload.system, options.payload.subsystem, options.payload.domainName));
      const action = SessionActions.requestResetPasswordSuccess();

      yield put(action);
    } catch (error) {
      const failureAction = SessionActions.requestResetPasswordFailure(error);

      yield put(failureAction);
    }
  };
}

export const requestPassword = createRequestPassword();

export function* getRequestPassword() {
  yield takeLatest(SESSION_TYPES.REQUEST_PASSWORD_BEGIN, requestPassword);
}
