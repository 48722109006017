const CDN = 'https://s3.amazonaws.com/assets.wealthpoint.app';
const REGISTRATION = '/register';
const BASIC_INFO = '/basic-info';
const SIGN_IN = '/sign-in';
const LOGOUT = '/logout';
const RESET_PASSWORD = '/reset-password';
const MARKETPLACE = '/marketplace';
const SUB_MARKETPLACE = (slug) => `/marketplace/${ slug }`;
const INVESTMENT = (id) => `/deal/${ id }`;
const WALLET = '/wallet';
const WALLET_DETAIL = (currency) => `/wallet/${ currency }`;
const PROFILE = '/profile';
const PROFILE_DETAIL = (id, step, autostep=true) => `/profile/${ id }/${ step }/${autostep}`;
const COMPANY_PROFILE = '/company-profile';
const PORTFOLIO = '/portfolio';
const PORTFOLIO_DETAIL = (type) => `/portfolio/${ type }`;
const VERIFY_EMAIL = '/verify';
const EXPIRED_TOKEN = '/expired';
const NEW_PASSWORD = '/new-password';
const UNSUPPORTED_COUNTRY = '/unsupported-country';

const WEALTH_UNIVERSITY = 'https://wealthuniversity.org/';
const WEALTHMIGRATE = 'https://www.wealthmigrate.com/';
const TERMS_CONDITIONS = 'https://wealthmigrate.com/legal/terms-and-conditions';
const LEGACY_PLATFORM = 'https://orbvest.com/signin';
const RESOURCES = 'https://wealthpoint.app/resources/';
const HELP_ARTICLES = (contentType) => {
  switch(contentType?.toLowerCase()){
    case 'company': 
        return 'https://wealthpoint.app/resources/investor/account/how-to-complete-your-profile-and-get-kyb-verified-as-a-corporate-structure-entity/'
    case 'trust': 
        return 'https://wealthpoint.app/resources/investor/account/how-to-complete-your-profile-and-get-kyb-verified-as-a-trust/'
    default: 
        return 'https://wealthpoint.app/resources/investor/account/how-to-complete-your-profile-and-get-kyc-verified-as-an-individual/' 
  }
}

/**
 * Application routing paths
 */
const Paths = {
  CDN,
  COMPANY_PROFILE,
  REGISTRATION,
  UNSUPPORTED_COUNTRY,
  BASIC_INFO,
  SIGN_IN,
  INVESTMENT,
  PORTFOLIO,
  PORTFOLIO_DETAIL,
  LOGOUT,
  RESET_PASSWORD,
  NEW_PASSWORD,
  WALLET,
  WALLET_DETAIL,
  PROFILE,
  VERIFY_EMAIL,
  EXPIRED_TOKEN,
  WEALTH_UNIVERSITY,
  WEALTHMIGRATE,
  TERMS_CONDITIONS,
  LEGACY_PLATFORM,
  MARKETPLACE,
  SUB_MARKETPLACE,
  RESOURCES,
  PROFILE_DETAIL,
  HELP_ARTICLES
};

export default Paths;
