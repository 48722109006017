import { Map } from 'immutable';

import { SESSION_TYPES } from '../types/session.types';

/**
 * Redux Reducer for session
 */

const initialState = {
  isLoggedIn: false,
  loading: false,
  refreshing: false,
  email: undefined,
  userEntities: [],
  accountVerified: true,
  legacy: false,
  demandPartner: undefined,
  channelPartner: undefined,
  subsystem: undefined,
};

const actionsMap = {
  [SESSION_TYPES.SIGNIN_BEGIN]: (state) =>
    ({
      ...state,
      loading: true,
      error: undefined,
    }),
  [SESSION_TYPES.SIGNIN_FAILURE]: (state, action) =>
    ({
      ...state,
      loading: false,
      error: action.payload.error,
    }),
  [SESSION_TYPES.SIGNIN_SUCCESS]: (state, action) =>
    ({
      ...state,
      isLoggedIn: true,
      loading: false,
      email: action.payload.email,
      userEntities: action.payload.userEntities,
      accountVerified: true,
      legacy: action.payload.legacy || false,
      demandPartner: action.payload.demandPartner,
      channelPartner: action.payload.channelPartner,
      subsystem: action.payload.subsystem,
      error: undefined,
    }),
  [SESSION_TYPES.SIGNUP_BEGIN]: (state) =>
    ({
      ...state,
      loading: true,
      error: undefined,
    }),
  [SESSION_TYPES.SIGNUP_FAILURE]: (state, action) =>
    ({
      ...state,
      loading: false,
      error: action.payload.error,
    }),
  [SESSION_TYPES.SIGNUP_SUCCESS]: (state, action) =>
    ({
      ...state,
      isLoggedIn: true,
      loading: false,
      error: undefined,
      userEntities: action.payload.userEntities,
      accountVerified: action.payload.accountVerified,
      email: action.payload.username,
      subsystem: action.payload.subsystem
    }),
  [SESSION_TYPES.SIGNOUT]: (state) =>
    ({
      ...state,
      isLoggedIn: false,
      loading: false,
    }),
  [SESSION_TYPES.REFRESH_TOKEN_BEGIN]: (state) =>
    ({
      ...state,
      refreshing: true,
      error: undefined,
    }),
  [SESSION_TYPES.REFRESH_TOKEN_SUCCESS]: (state, action) =>
    ({
      ...state,
      refreshing: false,
      error: undefined,
      userEntities: action.payload.userEntities,
      accountVerified: action.payload.accountVerified,
      legacy: action.payload.legacy || false,
      demandPartner: action.payload.demandPartner,
      channelPartner: action.payload.channelPartner,
    }),
  [SESSION_TYPES.REFRESH_TOKEN_FAILURE]: (state, action) =>
    ({
      ...state,
      refreshing: true,
      error: action.payload.error,
    }),
  [SESSION_TYPES.VERIFY_EMAIL_BEGIN]: (state) =>
    ({
      ...state,
      loading: true,
      error: undefined,
    }),
  [SESSION_TYPES.VERIFY_EMAIL_FAILURE]: (state, action) =>
    ({
      ...state,
      loading: false,
      error: action.payload.error,
    }),
  [SESSION_TYPES.VERIFY_EMAIL_SUCCESS]: (state) =>
    ({
      ...state,
      loading: false,
      error: undefined,
    }),
};

export default function reducer(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
