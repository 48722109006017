export default class NumberExtensions {
  /**
   * Creates a fixed string from a double value
   * @example
   * const double = toFixed(21323423423, 2);
   * @returns {String} Returns a string with the number in fixed point notation
   */
  static toFixedString(number, fractionDigits = 2) {
    if (!number) {
      return "0.00"
    }
    return parseFloat(Math.round(number * 100) / 100).toFixed(fractionDigits);
  }

  /**
   * Calculate available wallet balance catering for posible negative reserved values
   * @param {*} balance 
   * @param {*} reserved 
   */
  static availWalletBalance = (balance = 0, reserved = 0) => {
    return reserved < 0 ? balance : balance - Math.abs(reserved)
  }
}
