/**
 * Stores and retrieves a user session.
 */
class SessionStore {
  /**
   * Retrieves a users session from the store
   * @example
   * const session = SessionStore.getSession();
   * @returns {string} Returns a string containing the user session token.
   */
  static getSession() {
    return sessionStorage.getItem('a');
  }

  /**
   * Retrieves a users refresh from the store
   * @example
   * const session = SessionStore.getRefresh();
   * @returns {string} Returns a string containing the user session token.
   */
  static getRefresh() {
    return sessionStorage.getItem('r');
  }

  static getSubsystem() {
    return localStorage.getItem('s') || process.env.REACT_APP_API_SUBSYSTEM;
  }

  static getLocation() {
    const l = JSON.parse(sessionStorage.getItem('l'));
    return (l && l.p) || '/';
  }

  static getActiveEntityId() {
    const l = JSON.parse(sessionStorage.getItem('l'));
    return (l && l.e) || '';
  }

  static setLocation(location) {
    const l = JSON.parse(sessionStorage.getItem('l'));
    sessionStorage.setItem('l', JSON.stringify({ ...l, p: location }));
  }

  static setActiveEntity(entity_id) {
    const l = JSON.parse(sessionStorage.getItem('l'));
    sessionStorage.setItem('l', JSON.stringify({ ...l, e: entity_id }));
  }

  static setListerPreferences(lister = undefined) {
    const l = JSON.parse(sessionStorage.getItem('l'));
    sessionStorage.setItem('l', JSON.stringify({ ...l, c: { subsystem: lister?.subsytem || 'global', currencies: lister?.preferred_currency_iso || []} }));
  }

  static getListerPreferences() {
    const l = JSON.parse(sessionStorage.getItem('l'));
    return l?.c
  }

  /**
   * Stores a user session.
   * @example
   * SessionStore.setSession('some token')
   */
  static setSession(token, refresh, subsystem) {
    sessionStorage.setItem('a', token);
    sessionStorage.setItem('r', refresh);
    localStorage.setItem('s', subsystem);
  }

  /**
   * Removes a user session.
   * @example
   * SessionStore.clearSession()
   */
  static clearSession() {
    sessionStorage.removeItem('a');
    sessionStorage.removeItem('r');
    sessionStorage.removeItem('persist:root');
    sessionStorage.removeItem('l');
    localStorage.removeItem('s');
  }

  /**
   * Tests a users session from the store
   * @example
   * const session = SessionStore.getSession();
   * @returns {boolean} Returns a value indicate if a session is currently stored.
   */
  static hasSession() {
    const token = SessionStore.getSession(); // this can refer to statics in ES6
    return !!token;
  }
}

export default SessionStore;
