import React from 'react';
import propTypes from 'prop-types';
import Notification from '@traaidmark/konstruct-part-notification';

import iconSrc from '../../images/icons/notification-orange.svg';

const Warning = ({ children, className = '' }) => {
  return (
    <Notification className={ `p-notification--warning ${ className }`} icon={ iconSrc }>
      { children }
    </Notification>
  )
};

Warning.propTypes = {
  children: propTypes.node,
  className: propTypes.string
}

export default Warning;
