import 'isomorphic-fetch';
import { fetchResource } from './fetch-resource';

import { API } from '../constants';

class PartnerMappingsService {
  /**
   * Gets Partner Mappings by their legacy.
   * @example
   * @returns {Promise} Returns a promise containing the result in json format.
   */

  static getByLegacyCode = (legacyCode) => {
    const requestConfig = {
      cache: 'no-cache',
      mode: 'cors',
      headers: {
        'content-type': 'application/json',
      },
      method: 'GET',

    };

    return fetchResource(`${ API.PARTNER_MAPPINGS }?legacy_code=${ legacyCode }`, requestConfig);
  }

  /**
   * Gets all partner mappings.
   * @example
   * @returns {Promise} Returns a promise containing the result in json format.
   */

  static getAll = () => {
    const requestConfig = {
      cache: 'no-cache',
      mode: 'cors',
      headers: {
        'content-type': 'application/json',
      },
      method: 'GET',
    };

    return fetchResource(`${ API.PARTNER_MAPPINGS }`, requestConfig);
  }
}

export default PartnerMappingsService;
