import { fetchResource } from './fetch-resource';

import { API } from '../constants';

/**
 * User API service
 */
class UserService {
  /**
   * Logs a user into the platform.
   * @example
   * emailLogin('some email', 'some password').then(res => { return res.data }).then(...)
   * @returns {Promise} Returns a promise containing the result of the HTTP operation.
   */
  static emailLogin = (email, password, system, subsystem) => {
    const payload = JSON.stringify({
      email, password, system, subsystem,
    });

    const requestConfig = {
      credentials: 'include',
      cache: 'no-cache',
      mode: 'cors',
      body: payload,
      headers: {
        'content-type': 'application/json',
      },
      method: 'POST',
    };

    return fetchResource(`${ API.USERS }/login`, requestConfig);
  }

  /**
   * Registers a user into the platform.
   * @example
   * emailRegister('some email', 'some password').then(res => { return res.data }).then(...)
   * @returns {Promise} Returns a promise containing the result in json format
   */
  static emailRegister = (email, password, system, subsystem, demandPartner, channelPartner, campaignCode, registrationUri) => {
    const payload = JSON.stringify({
      email,
      password,
      system,
      subsystem,
      demand_partner: demandPartner,
      channel_partner: channelPartner,
      campaign: campaignCode,
      register_uri: registrationUri,
    });

    const requestConfig = {
      credentials: 'include',
      cache: 'no-cache',
      mode: 'cors',
      body: payload,
      headers: {
        'content-type': 'application/json',
      },
      method: 'POST',
    };

    return fetchResource(`${ API.USERS }/register`, requestConfig);
  }
  /**
   * Requests to verify a users email
   * @example
   * emailVerify('some userId').then(res => { return res.data }).then(...)
   * @returns {Promise} Returns a promise containing the result in json format
   */
  static emailVerifyRequest(jwt, subject, email, system = 'platform', subsystem = 'global', domainName = 'platform.wealthmigrate.com') {
    const payload = JSON.stringify({ user_id: subject, system, subsystem, email, domain_name: domainName });

    const requestConfig = {
      cache: 'no-cache',
      mode: 'cors',
      body: payload,
      headers: {
        'content-type': 'application/json',
        'authorization': `bearer ${ jwt }`,
      },
      method: 'POST',
    };

    return fetchResource(`${ API.USERS }/request_verify_entity_email`, requestConfig);
  }

  /**
   * Verifies an email address with a verification token
   * @example
   * emailVerify('some token').then(res => { return res.data }).then(...)
   * @returns {Promise} Returns a promise containing the result in json format
   */
  static emailVerifyComplete(token, system, subsystem) {
    const payload = JSON.stringify({ token, system, subsystem });

    const requestConfig = {
      cache: 'no-cache',
      mode: 'cors',
      body: payload,
      headers: {
        'content-type': 'application/json',
      },
      method: 'POST',
    };

    return fetchResource(`${ API.USERS }/verify_email`, requestConfig);
  }

  /**
   * Requests a password reset
   * @example
   * passwordResetRequest('someaccount@domain.com').then(res => { return res.data }).then(...)
   * @returns {Promise} Returns a promise containing the result in json format
   */
  static passwordResetRequest(email, system = 'platform', subsystem = 'global', domainName = 'platform.wealthmigrate.com') {
    const payload = JSON.stringify({ email, system, subsystem, domain_name: domainName });

    const requestConfig = {
      cache: 'no-cache',
      mode: 'cors',
      body: payload,
      headers: {
        'content-type': 'application/json',
      },
      method: 'POST',
    };

    return fetchResource(`${ API.USERS }/request_password_reset`, requestConfig);
  }

  /**
   * Completes an existing password reset
   * @example
   * passwordResetComplete('changeToken', 'newPassword', 'global', 'platform').then(res => { return res.data }).then(...)
   * @returns {Promise} Returns a promise containing the result in json format
   */
  static passwordResetComplete(token, newPassword, system, subsystem) {
    const payload = JSON.stringify({
      token, password: newPassword, system, subsystem,
    });

    const requestConfig = {
      cache: 'no-cache',
      mode: 'cors',
      body: payload,
      headers: {
        'content-type': 'application/json',
      },
      method: 'POST',
    };

    return fetchResource(`${ API.USERS }/reset_password`, requestConfig);
  }

  /**
   * Refreshes a session in the platform.
   * @example
   * refreshSession('refresh-token').then(res => { return res.data }).then(...)
   * @returns {Promise} Returns a promise containing the result of the HTTP operation.
   */
  static refreshSession = (token) => {
    const requestConfig = {
      credentials: 'include',
      cache: 'no-cache',
      mode: 'cors',
      headers: {
        'content-type': 'application/json',
      },
      method: 'GET',
    };

    return fetchResource(`${ API.USERS }/token`, requestConfig);
  }

  /**
   * Get agreements for logged in user.
   * @returns {Promise} Returns a promise containing the result of the HTTP operation.
   */
  static getUserAgreements = (jwt) => {
    const requestConfig = {
      credentials: 'include',
      cache: 'no-cache',
      mode: 'cors',
      headers: {
        'content-type': 'application/json',
        'authorization': `bearer ${ jwt }`,
      },
      method: 'GET',
    };
    return fetchResource(`${ API.USERS }/agreements`, requestConfig);
  }

  /**
   * Adds user entity to the platform (experimental - will be removed in a future version).
   * @example
   * refreshSession('refresh-token').then(res => { return res.data }).then(...)
   * @returns {Promise} Returns a promise containing the result of the HTTP operation.
   */
  static addUserEntity = (jwt, userId, entityId) => {
    if (!entityId || !userId || !entityId) {
      throw Error('Invalid arguments specified.');
    }

    const payload = JSON.stringify([
      {
        op: 'add',
        path: '/entities',
        value: entityId,
      },
    ]);

    const requestConfig = {
      cache: 'no-cache',
      mode: 'cors',
      body: payload,
      headers: {
        'content-type': 'application/json',
        'authorization': `bearer ${ jwt }`,
      },
      method: 'PATCH',
    };

    return fetchResource(`${ API.USERS }/${ userId }`, requestConfig);
  }


  /**
   * Adds user agreements to the user's model
   * @example
   * addUserAgreements('refresh-token').then(res => { return res.data }).then(...)
   * @returns {Promise} Returns a promise containing the result of the HTTP operation.
   */
  static addUserAgreements = (jwt, userId, agreements) => {
    if (!userId || !agreements || agreements.length === 0 ) {
      throw Error('Invalid arguments specified.');
    }

    const payload = JSON.stringify(agreements.map(agreement => ({ op: "add", path: "/agreements", value: agreement })));

    const requestConfig = {
      cache: 'no-cache',
      mode: 'cors',
      body: payload,
      headers: {
        'content-type': 'application/json',
        'authorization': `bearer ${ jwt }`,
      },
      method: 'PATCH',
    };

    return fetchResource(`${ API.USERS }/${ userId }`, requestConfig);
  }
}

export default UserService;
