import 'isomorphic-fetch';
import { fetchResource } from './fetch-resource';

import { API } from '../constants';

/**
 * Bookbuild API service
 */
class BookbuildService {
  /**
   * Retrieves the indications for an entity
   * @example
   * getEntityInvestments('someJwt', 'some-entity-id').then(res => { return res.data }).then(...)
   * @returns {Promise} Returns a promise containing the result in json format.
   */
  static getEntityIndications = (jwt, entityId) => {
    const requestConfig = {
      cache: 'no-cache',
      mode: 'cors',
      headers: {
        'content-type': 'application/json',
        'authorization': `bearer ${ jwt }`,
      },
      method: 'GET',
    };

    return fetchResource(`${ API.INDICATIONS }?entity_id=${ entityId }&limit=5000`, requestConfig) ;
  }

  /**
   * Creates an indication for an entity
   * @example
   * createEntityIndication('someJwt', 'some-entity-id', 'some-user-Id', 1000, 'USD', 'some-product-id)
   * .then(res => { return res.data })
   * .then(...)
   * @returns {Promise} Returns a promise containing the result in json format.
   */
  static createEntityIndication = (jwt, entityId, userId, amount, currencyIso, productId, fees, agreements, sourcesOfWealth, integrationIdentifier, feesRevision, feesTimestamp, subsystem = 'global') => {
    const body = {
      user_id: userId,
      entity_id: entityId,
      amount,
      currency_iso: currencyIso,
      product_id: productId,
      fees,
      product_agreements: agreements,
      sources_of_wealth: sourcesOfWealth,
      subsystem,
      integration_identifier: integrationIdentifier,
      fees_revision: feesRevision,
      fees_timestamp: feesTimestamp
    };

    const requestConfig = {
      mode: 'cors',
      headers: {
        'content-type': 'application/json',
        'authorization': `bearer ${ jwt }`,
      },
      method: 'POST',
      body: JSON.stringify(body),
    };

    return fetchResource(`${ API.INDICATIONS }`, requestConfig);
  }

  /**
   * Retrieves the indications for an entity
   * @example
   * getEntityOrders('someJwt', 'some-entity-id').then(res => { return res.data }).then(...)
   * @returns {Promise} Returns a promise containing the result in json format.
   */
  static getEntityOrders = (jwt, entityId) => {
    const requestConfig = {
      cache: 'no-cache',
      mode: 'cors',
      headers: {
        'content-type': 'application/json',
        'authorization': `bearer ${ jwt }`,
      },
      method: 'GET',
    };

    return fetchResource(`${ API.ORDERS }?entity_id=${ entityId }&limit=5000`, requestConfig);
  }

  /**
   * Retrieves the orders for an entity for a product
   * @example
   * getEntityProductOrders('someJwt', 'some-entity-id', 'some-product-id').then(res => { return res.data }).then(...)
   * @returns {Promise} Returns a promise containing the result in json format.
   */
  static getEntityProductOrders = (jwt, productId) => {
    const requestConfig = {
      cache: 'no-cache',
      mode: 'cors',
      headers: {
        'content-type': 'application/json',
        'authorization': `bearer ${ jwt }`,
      },
      method: 'GET',
    };

    return fetchResource(`${ API.ORDERS }?product_id=${ productId }&fields=_id,user_id,entity_id,amount,amount_paid,currency_iso,product_id,timestamp&sort=timestamp&limit=5000`, requestConfig);
  }

  static cancelIndication = (jwt, indicationId) => {
    const requestConfig = {
      cache: 'no-cache',
      mode: 'cors',
      headers: {
        'content-type': 'application/json',
        'authorization': `bearer ${ jwt }`,
      },
      method: 'POST',
    };

    return fetchResource(`${ API.INDICATIONS }/${indicationId}/cancel`, requestConfig);
  }
}

export default BookbuildService;
