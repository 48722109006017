import { takeLatest, put, call } from 'redux-saga/effects';

import { DOCUMENT_TYPES } from '../../types';
import DocumentActions from '../../actions/document.actions';
import documentService from '../../../api/document.service';
import SessionStore from '../../../utils/session-store';

/**
 * Redux Saga generator function for getting transaction statement
 */
function getTransactionDocuments() {
  return function* (options) {
    try {
      const token = SessionStore.getSession();
      const {entityId, currency, startDate, endDate, timezoneOffset} = options.payload;

      const statement = yield call(() => documentService.getTransactionDocumentById(token, entityId, currency, startDate, endDate, timezoneOffset));
      const downloadLink = document.createElement('a');
      downloadLink.href = URL.createObjectURL(statement);
      downloadLink.setAttribute(
          'download', 'transaction_statement.pdf'
      );
      document.body.appendChild(downloadLink)
      downloadLink.click();
      document.body.removeChild(downloadLink)
      const action = DocumentActions.getTransactionDocumentsByIdSuccess(statement);
      yield put(action);
    } catch (error) {
      const failureAction = DocumentActions.getTransactionDocumentsByIdFailure(error);
      yield put(failureAction);
    }
  };
}

export const getTransactionDocument = getTransactionDocuments();

export function* getTransactionDocumentsByIdWatcher() {
  yield takeLatest(DOCUMENT_TYPES.GET_TRANSACTION_DOCUMENTS_BY_ID_BEGIN, getTransactionDocument);
}
