import React, {  useMemo } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import qs from 'query-string';

import * as Routes from './routes';
import AuthenticatedRoute from '../authenticated-route';
import UnauthenticatedRoute from '../anonymous-route';
//import NotFound from '../../views/404';
import Paths from '../../constants/paths';

import { uiThunk } from '../../redux/thunks';

import Painter from '../../assets/containers/Painter';
import { email } from '../../constants/validation-expressions';

/**

 * Outlet for all routes in the application.

 */

const RouterOutlet = ({
  location,
  isLoggedIn,
  activeEntity,
  investments,
  createUi,
  ui,
  subsystem,
  wallets,
}) => {
  useMemo(() => {

    const storedKey = localStorage.getItem('s');
    const queryKey = qs.parse(location.search).subsystem;
    const domainName = window.location.hostname;

    return createUi({ domainName, storedKey, queryKey });

  }, [location, localStorage, window]);

  const loggedIn = isLoggedIn || false;
  
  const defaultRoute = (props) => (
    <Redirect
      to={ { pathname: Paths.SIGN_IN, search: props.location.search } }
      { ...props } 
    />
    );
  useMemo(() => {

    if(activeEntity && activeEntity.email && activeEntity.email_confirmation_date === 0) {
      return (
        window.pendo.initialize({
          visitor: {
            id: activeEntity._id,
            email: activeEntity.email,
            subsystem: subsystem,
            isVerified: false,
            isInvestor: false,
            kyc_status: activeEntity.kyc_status,
            hasAvailableBalance: false,
          },
        })
      );
    };
    if (activeEntity && subsystem && activeEntity.email && wallets?.length > 0) {
      const content = activeEntity.content
      const filteredWallets = wallets.filter((wallet) => wallet.balance > 0);
      return (
        window.pendo.initialize({
          visitor: {
            id: activeEntity._id,
            full_name: content && content?.first_name && content?.last_name ? `${ content?.first_name } ${ content?.last_name }` : undefined, 
            email: activeEntity.email,
            country: activeEntity?.content?.country,
            subsystem: subsystem,
            isVerified: true,
            isInvestor: investments && investments.length > 0,
            kyc_status: activeEntity.kyc_status,
            hasAvailableBalance: filteredWallets.length > 0 ? true : false,
          },
        })
      );
    } 
    return null;
  }, [activeEntity, investments, subsystem, wallets]);

  useMemo(() => {
    if (ui?.images?.favicon) {
      const favicon = document.getElementById("favicon");
      return favicon.setAttribute('href', ui.images.favicon);
    }
    return null;
  }, [ui]);

  return (
    <>
      <Painter />
      <Switch>

        <Route
          path='/'
          exact
          render={ (props) => defaultRoute(props) }
          onError
        />

        <UnauthenticatedRoute
          path={ Paths.SIGN_IN }
          RenderComponent={ (props) => <Routes.AsyncSignInPage { ...props } /> }
          FallbackComponent={ (props) =>
            <Redirect to={ { pathname: Paths.BASIC_INFO, state: { from: props.location } } } /> }
          ignoreLoggedIn={ true }
          isLoggedIn={ loggedIn }
          name="signIn"
        />

        <UnauthenticatedRoute
          path={ Paths.REGISTRATION }
          RenderComponent={ (props) => <Routes.AsyncRegisterPage { ...props } /> }
          FallbackComponent={ (props) =>
            <Redirect to={ { pathname: Paths.BASIC_INFO, state: { from: props.location } } } /> }
          isLoggedIn={ loggedIn }
          name="signUp"
        />

        <UnauthenticatedRoute
          path={ Paths.LOGOUT }
          RenderComponent={ (props) => <Routes.AsyncLogoutPage { ...props } /> }
          ignoreLoggedIn={ true }
          name="signOut"
        />

        <UnauthenticatedRoute
          path={ Paths.VERIFY_EMAIL }
          RenderComponent={ (props) => <Routes.AsyncVerifyEmailPage { ...props } /> }
          ignoreLoggedIn={ true }
          name="emailVerify"
        />

        <UnauthenticatedRoute
          path={ Paths.EXPIRED_TOKEN }
          RenderComponent={ (props) => <Routes.AsyncInvalidTokenPage { ...props } /> }
          ignoreLoggedIn={ true }
          name="tokenExpired"
        />

        <UnauthenticatedRoute
          path={ Paths.NEW_PASSWORD }
          RenderComponent={ (props) => <Routes.AsyncNewPasswordPage { ...props } /> }
          ignoreLoggedIn={ true }
          name="passwordReset"
        />

        <UnauthenticatedRoute
          path={ Paths.UNSUPPORTED_COUNTRY }
          RenderComponent={ (props) => <Routes.AsyncUnsupportedCountry { ...props } /> }
          FallbackComponent={ (props) =>
            <Redirect to={ { pathname: Paths.BASIC_INFO, state: { from: props.location } } } /> }
          ignoreLoggedIn={ true }
          name="unsupportedCountry"
        />

        <UnauthenticatedRoute
          path={ Paths.RESET_PASSWORD }
          RenderComponent={ (props) => <Routes.AsyncResetPasswordPage { ...props } /> }
          FallbackComponent={ (props) =>
            <Redirect to={ { pathname: Paths.BASIC_INFO, state: { from: props.location } } } /> }
          isLoggedIn={ loggedIn }
          name="passwordReset"
        />

        <UnauthenticatedRoute
          path={ Paths.BASIC_INFO }
          RenderComponent={ (props) => <Routes.AsyncBasicInfoPage { ...props } /> }
          FallbackComponent={ (props) => defaultRoute(props) }
          ignoreLoggedIn={ true }
        />

        <AuthenticatedRoute
          path={ Paths.SUB_MARKETPLACE(':slug') }
          RenderComponent={ (props) => <Routes.AsyncInvestmentsPage { ...props } /> }
          FallbackComponent={ (props) => defaultRoute(props) }
          isLoggedIn={ loggedIn }
        />

        <AuthenticatedRoute
          path={ Paths.MARKETPLACE }
          RenderComponent={ (props) => <Routes.AsyncInvestmentsPage { ...props } /> }
          FallbackComponent={ (props) => defaultRoute(props) }
          isLoggedIn={ loggedIn }
        />

        

        <AuthenticatedRoute
          path={ Paths.INVESTMENT(':id') }
          RenderComponent={ (props) => <Routes.AsyncInvestmentPage { ...props } /> }
          FallbackComponent={ (props) => defaultRoute(props) }
          isLoggedIn={ loggedIn }
        />

        <AuthenticatedRoute
          path={ Paths.WALLET_DETAIL(':currency') }
          RenderComponent={ (props) => <Routes.AsyncWalletDetailPage { ...props } /> }
          FallbackComponent={ (props) => defaultRoute(props) }
          isLoggedIn={ loggedIn }
        />

        <AuthenticatedRoute
          path={ Paths.WALLET }
          RenderComponent={ (props) => <Routes.AsyncWalletPage { ...props } /> }
          FallbackComponent={ (props) => defaultRoute(props) }
          isLoggedIn={ loggedIn }
        />

        <AuthenticatedRoute
          path={ Paths.PROFILE_DETAIL(':id', ':step', ':autostep') }
          RenderComponent={ (props) => <Routes.AsyncProfilePage { ...props } /> }
          FallbackComponent={ (props) => defaultRoute(props) }
          isLoggedIn={ loggedIn }
        />

        <AuthenticatedRoute
          path={ Paths.COMPANY_PROFILE }
          RenderComponent={ (props) => <Routes.AsyncCompanyProfilePage { ...props } /> }
          FallbackComponent={ (props) => defaultRoute(props) }
          isLoggedIn={ loggedIn }
        />

        <AuthenticatedRoute
          path={ Paths.PORTFOLIO_DETAIL(':type') }
          RenderComponent={ (props) => <Routes.AsyncPortfolioPage { ...props } /> }
          FallbackComponent={ (props) => defaultRoute(props) }
          isLoggedIn={ loggedIn }
        />

      </Switch>
    </>
  );
};

RouterOutlet.propTypes = {
  isLoggedIn: PropTypes.bool,
  isVerified: PropTypes.bool,
  subsystem: PropTypes.bool,
  activeEntity: PropTypes.object,
  investments: PropTypes.array,
  createUi: PropTypes.func,
  wallets: PropTypes.array,
};

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state?.session?.isLoggedIn,
    activeEntity: state?.user?.active_entity,
    subsystem: state?.ui?.ui?.key,
    investments: state?.investments?.items,
    ui: state?.ui?.ui,
    wallets: state.wallets?.wallet?.wallets,
  }
};
const mapDispatchToProps = (dispatch) => {
  return {
    createUi: name => dispatch(uiThunk.createUi(name)),
  }
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RouterOutlet));