import { takeLatest, put, call } from 'redux-saga/effects';

import { INVESTMENTS_TYPES } from '../../types';
import InvestmentsActions from '../../actions/investments.actions';
import rulesService from '../../../api/rules.service';
import SessionStore from '../../../utils/session-store';
import { SessionContext } from '../../../utils';

export function* getFees(action) {
  try {
    const token = SessionStore.getSession();
    const subsystem = SessionStore.getSubsystem();
    const userId = SessionContext.get(token)?.sub;
    const {
      productIds,
    } = action.payload;

    const fees = yield call(() => rulesService.getFeeData(token, userId, subsystem, productIds));

    yield put(InvestmentsActions.getFeesSuccess(fees));

  } catch (error) {
    yield put(InvestmentsActions.getFeesError(error));
  }
}

export function* getFeesWatcher() {
  yield takeLatest(INVESTMENTS_TYPES.GET_PRODUCT_FEES_BEGIN, getFees);
}
