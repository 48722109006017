import { takeLatest, put, call } from 'redux-saga/effects';

import UserService from '../../../api/user.service';
import { USER_TYPES } from '../../types';
import UserActions from '../../actions/user.actions';
import SessionStore from '../../../utils/session-store';
import SessionContext from '../../../utils/session-context';

function createUpdateUserAgreements(){
  return function* (options) {
    try {
      const token = SessionStore.getSession();
      const userId = SessionContext.get(token).sub;
      const body = yield call(() => UserService.addUserAgreements(token, userId, options.payload.agreements));
      const successAction = UserActions.updateUserAgreementsSuccess(body.data[0].agreements);
      yield put(successAction);
    } catch (e) {
      const failureAction = UserActions.updateUserAgreementsFailure({error: e});
      yield put(failureAction);
    }
  }
}

export const updateUserAgreements = createUpdateUserAgreements();

export function* updateUserAgreementsWatcher() {
  yield takeLatest(USER_TYPES.UPDATE_USER_AGREEMENTS_BEGIN, updateUserAgreements);
}