import PATH from '../paths';

const name = 'Crypto Based Innovation';
const key = 'cbi';
const imgUrl = `${ PATH.CDN }/whitelabels/${ key }`;
const images = {
  favicon: `${ imgUrl }/favicon.png`,
  logo: `${ imgUrl }/cbi-logo.png`,
  logoSidebar: `${ imgUrl }/logo.png`,
  logoPDF: `${ imgUrl }/logo-pdf.png`,
  opengraph: false,
  backgrounds: [
    `${ imgUrl }/background.jpg`
  ],
};

export default {
  name,
  key,
  images,
  stylesheet: `
  
    /* ANONYMOUS LAYOUT */

    --anonymous-logo-bg:              #cb953d;
    --anonymous-bg:                   url(${ images.backgrounds[0] });
    --anonymous-bg-size:              cover;
    --anonymous-logo-height:          70px;


    /* END */
  
  `
  
}; 