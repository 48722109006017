/**
 * A collection of well known tooltip messages in the application.
 */
const INFO_MESSAGES = {
  company_profile: 'To meet our regulatory requirements, we are required to verify the identity of our members. For further information please read through our <a href="https://wealthpoint.app/resources/investor/account/how-to-complete-your-profile-and-get-kyb-verified-as-a-corporate-structure-entity/" target="_blank" rel="noreferrer noopener">help article</a> or contact us directly at <a href="mailto:support@wealthpoint.app">support@wealthpoint.app</a>',
  company_profile_country: 'To meet our regulatory requirements, we are required to verify the Country of Incorporation and the Investor Category of our members\' entities. Entities incorporated in the United States of America and a few other countries are prevented from setting up and investing in opportunities on our platform. Entities from other countries have unrestricted access to our platform. For more information, please contact us at support@wealthpoint.app',
  company_profile_bank: 'To meet our regulatory requirements, we are required to verify our member\'s bank details to process any money-out events. For further information please read through our <a href=" https://wealthpoint.app/resources/investor/account/how-to-complete-your-profile-and-get-kyb-verified-as-a-corporate-structure-entity/ " target="_blank" rel="noreferrer noopener">help article</a> or contact us directly at <a href="mailto:support@wealthpoint.app">support@wealthpoint.app</a>',
  trust_profile: 'To meet our regulatory requirements, we are required to verify the identity of our members. For further information please read through our <a href="https://wealthpoint.app/resources/investor/account/how-to-complete-your-profile-and-get-kyb-verified-as-a-trust/" target="_blank" rel="noreferrer noopener">help article</a> or contact us directly at <a href="mailto:support@wealthpoint.app">support@wealthpoint.app</a>',
  trust_profile_bank: 'To meet our regulatory requirements, we are required to verify our member\'s bank details to process any money-out events. For further information please read through our <a href="https://wealthpoint.app/resources/investor/account/how-to-complete-your-profile-and-get-kyb-verified-as-a-trust/" target="_blank" rel="noreferrer noopener">help article</a> or contact us directly at <a href="mailto:support@wealthpoint.app">support@wealthpoint.app</a>',
  profile_country: 'To meet our regulatory requirements, we are required to verify the country of residence of our members.',
  profile_personal: 'To meet our regulatory requirements, we are required to verify the identity of our members. For further information please read through our <a href="https://wealthpoint.app/resources/investor/account/how-to-complete-your-profile-and-get-kyc-verified-as-an-individual/" target="_blank" rel="noreferrer noopener">help article</a> or contact us directly at <a href="mailto:support@wealthpoint.app">support@wealthpoint.app</a>',
  profile_bank: 'To meet our regulatory requirements, we are required to verify our member\'s bank details to process any money-out events. For further information please read through our <a href="https://wealthpoint.app/resources/investor/account/how-to-complete-your-profile-and-get-kyc-verified-as-an-individual/" target="_blank" rel="noreferrer noopener">help article</a> or contact us directly at <a href="mailto:support@wealthpoint.app">support@wealthpoint.app</a>',
  profile_kyc: 'We are required to verify the identity of our members through the Know Your Customer (KYC) process to protect our investors against the risks of fraud and money laundering. Our KYC Support Team will do a preliminary review of your profile before submitting it for verification.',
  zero_rated_legacy: 'As a Legacy Client you do not pay any fees on the Wealth Migrate Platform.'
};

export default INFO_MESSAGES;
