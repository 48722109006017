import { takeEvery, put, call } from "redux-saga/effects";
import { createPatch } from "rfc6902";
import {
  streamlinePatches,
  flattenDeep,
  greenlightPatches,
} from "../../../utils";

import { USER_TYPES } from "../../types";
import SessionStore from "../../../utils/session-store";
import EntityService from "../../../api/entity.service";
import UserActions from "../../actions/user.actions";
/**
 * Redux Saga generator function for creating bank accounts
 */
function* updateBankAccount(bankAcc){
  const token = SessionStore.getSession();

  const baBody = yield call(() =>
        EntityService.getBankAccountsforEntity(token, bankAcc?.owner)
      );
      const incBank = baBody.data.find((b) => b?._id === bankAcc?._id);

      const remoteBank = {
        ...incBank,
        wallet_links: incBank.wallet_links ? [...incBank.wallet_links] : [],
      };

      const localBank = {
        ...bankAcc,
        wallet_links: bankAcc.wallet_links
          ? [...bankAcc.wallet_links]
          : [],
      };

      const bankDiff = createPatch(remoteBank, localBank)
        .map((diffItem) => streamlinePatches(diffItem))
        ?.filter((x) => x !== undefined);

      const flattenedBankDiff = bankDiff
        .map((item) => {
          return Array.isArray(item);
        })
        .includes(true)
        ? flattenDeep(bankDiff)
        : bankDiff;

      const greenlitPatches = greenlightPatches(flattenedBankDiff, [
        "/source_of_funds",
        "/owner",
        "/status",
      ]);
      if (greenlitPatches.length > 0) {
        const body = yield call(() =>
          EntityService.updateBankAccount(
            token,
            bankAcc?._id,
            greenlitPatches
          )
        );
        yield put(UserActions.updatebankAccountSuccess(body.data?.[0]));
      } else {
        yield put(UserActions.updatebankAccountSuccess(remoteBank));
      }
}


function createUpdateBankAccounts() {
  return function* (options) {
    try {
      const { bankAccounts } = options.payload;
      for(let ba of bankAccounts){
        yield updateBankAccount(ba);
      }
    } catch (error) {
      const failureAction = UserActions.updatebankAccountFailure(error);
      yield put(failureAction);
    }
  };
}

export const updateBankAccounts = createUpdateBankAccounts();

export function* updateBankAccountsWatcher() {
  yield takeEvery(USER_TYPES.UPDATE_BANK_ACCOUNT_BEGIN, updateBankAccounts);
}
