import { Map } from 'immutable';

import { DEMAND_PARTNERS_TYPES } from '../types/demand-partners.types';

/**
 * Redux Reducer for demand Partners
 */

const initialState = {
  items: [],
  loading: false,
  defaultSettings: []
};

const actionsMap = {
  [DEMAND_PARTNERS_TYPES.GET_ALL_DEMAND_PARTNERS]: (state) =>
    ({
      ...state,
      loading: true,
      error: undefined,
    }),
  [DEMAND_PARTNERS_TYPES.GET_ALL_DEMAND_PARTNERS_FAILURE]: (state, action) =>
    ({
      ...state,
      loading: false,
      error: action.payload.error,
    }),
  [DEMAND_PARTNERS_TYPES.GET_ALL_DEMAND_PARTNERS_SUCCESS]: (state, action) =>
    ({
      ...state,
      items: action.payload.demandPartners,
      loading: false,
    }),
  [DEMAND_PARTNERS_TYPES.GET_DEFAULT_SETTINGS]: (state) =>
    ({
      ...state,
      loading: true,
      error: undefined,
    }),
  [DEMAND_PARTNERS_TYPES.GET_DEFAULT_SETTINGS_FAILURE]: (state, action) =>
    ({
      ...state,
      loading: false,
      error: action.payload.error,
    }),
  [DEMAND_PARTNERS_TYPES.GET_DEFAULT_SETTINGS_SUCCESS]: (state, action) =>
    ({
      ...state,
      defaultSettings: action.payload.defaultSettings,
      loading: false,
    }),
};

export default function reducer(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
