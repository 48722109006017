import { takeEvery, take, race, put, select } from 'redux-saga/effects';
import SessionActions from '../../actions/session.actions';
import ERROR_MESSAGES from '../../../constants/error-message';
import { SESSION_TYPES } from '../../types';

import AlertActions from '../../actions/alert.actions';
import Effect from '../../actions/helpers/effect';
import SessionStore from '../../../utils/session-store';

const ignoreActionTypes = [
  'REFRESH_TOKEN',
];

const identifyAction = (action) => {
  return action.type.split('_').slice(0, -1).join('_');
};

const getSuccessType = (action) => {
  return `${ identifyAction(action) }_SUCCESS`;
};

const getFailType = (action) => {
  return `${ identifyAction(action) }_FAILURE`;
};

const monitorableAction = (action) => {
  return action.type && action.type
    .includes('BEGIN') &&
    ignoreActionTypes.every(fragment => !action.type.includes(fragment));
};

function* refreshToken() {
  const session = yield select(state => state.session);
  const isRefreshing = session.refreshing;

  // we need to check state here, if we're already refreshing don't do it again.
  if (!isRefreshing) {
    yield put(SessionActions.refreshToken());
  }
}

function* handleMetaEffects(monitoredAction, successAction, failAction) {
  if (successAction) {
    yield Effect.getOrDefault(monitoredAction.meta.effects.onSuccess)(successAction.payload);
  }

  if (failAction) {
    yield Effect.getOrDefault(monitoredAction.meta.effects.onError)(failAction.payload);
  }
}

function* handleErrorAlert(failAction) {
  const errorMessage =
    (failAction.meta && failAction.meta.displayMessage) || ERROR_MESSAGES.DEFAULT;

  yield put(AlertActions.error(errorMessage));
}

function* handleSuccessAlert(successAction) {
  const message =
    (successAction.meta && successAction.meta.displayMessage);

  if (message) {
    yield put(AlertActions.success(message));
  }
}

/**
 * Redux Saga generator function for monitoring all events
 */
function createMonitor() {
  return function* (monitoredAction) {
    // evaluate the result of the call on the action.
    const { success, fail } = yield race({
      success: take(getSuccessType(monitoredAction)),
      fail: take(getFailType(monitoredAction)),
    });

    // if our token expired try and refresh and run again or logout.
    const unAuthorized = fail?.payload?.error?.code === 401;

    const payloadStatus = fail?.payload?.error;
    const hasTokenExpired = unAuthorized && (
      payloadStatus?.status === 'token expired.' || //scala service token expired
      payloadStatus?.code === 401 //rust service token expired
      );

    if (hasTokenExpired) {
      yield refreshToken();

      const { succeed } = yield race({
        succeed: take(SESSION_TYPES.REFRESH_TOKEN_SUCCESS),
        fail: take(SESSION_TYPES.REFRESH_TOKEN_FAILURE),
      });

      if (succeed) {
        // if we succeeded in refreshing our token retry the initial action.
        yield put(monitoredAction);
      } else {
        // else logout the user.
        yield put(SessionActions.signout());
      }
    }

    if (!hasTokenExpired && fail?.error) {
      if (fail?.payload?.error?.status === 'JWT ID rejected.' && !SessionStore.hasSession()) {
        yield put(AlertActions.info('Your current session expired. Sign in to refresh your session'));
        yield put(SessionActions.signout(false));
      } else {
        yield handleErrorAlert(fail);
      }
    }

    if (!hasTokenExpired) {
      yield handleMetaEffects(monitoredAction, success, fail);
    }

    if (!hasTokenExpired && success) {
      yield handleSuccessAlert(success);
    }
  };
}

export const monitor = createMonitor();

export function* getMonitorWatcher() {
  yield takeEvery(monitorableAction, monitor);
}
