import React from 'react'; // eslint-disable-line
import Loadable from 'react-loadable';

import Loader from '@traaidmark/konstruct-part-loader';

/**
 * Async wrappers to support chunked pages in routes
 */

export const AsyncRegisterPage = Loadable({
  loader: () => import(/* webpackChunkName: "register.chunk" */'../../views/anonymous/sign-up'),
  loading: Loader,
});

export const AsyncSignInPage = Loadable({
  loader: () => import(/* webpackChunkName: "signin.chunk" */'../../views/anonymous/SignIn'),
  loading: Loader,
});

export const AsyncBasicInfoPage = Loadable({
  loader: () => import(/* webpackChunkName: "basicinfo.chunk" */'../../views/authenticated/basic-information'),
  loading: Loader,
});

export const AsyncVerifyEmailPage = Loadable({
  loader: () => import(/* webpackChunkName: "verifyemail.chunk" */'../../views/anonymous/verify-email'),
  loading: Loader,
});

export const AsyncInvalidTokenPage = Loadable({
  loader: () => import(/* webpackChunkName: "invalidtoken.chunk" */'../../views/anonymous/invalid-token'),
  loading: Loader,
});

export const AsyncResetPasswordPage = Loadable({
  loader: () => import(/* webpackChunkName: "single-investment.chunk" */'../../views/anonymous/reset-password'),
  loading: Loader,
});

export const AsyncNewPasswordPage = Loadable({
  loader: () => import(/* webpackChunkName: "newpassword.chunk" */'../../views/anonymous/new-password'),
  loading: Loader,
});

export const AsyncWaitingListPage = Loadable({
  loader: () => import(/* webpackChunkName: "waitinglist.chunk" */'../../views/anonymous/waiting-list'),
  loading: Loader,
});

export const AsyncUnsupportedCountry = Loadable({
  loader: () => import(/* webpackChunkName: "unsupported-country.chunk" */'../../views/anonymous/unsupported-country'),
  loading: Loader,
});

export const AsyncLogoutPage = Loadable({
  loader: () => import(/* webpackChunkName: "logout.chunk" */'../../views/anonymous/logout'),
  loading: Loader,
});

export const AsyncInvestmentsPage = Loadable({
  loader: () => import(/* webpackChunkName: "marketplace.chunk" */'../../views/authenticated/marketplace'),
  loading: Loader,
});

export const AsyncInvestmentPage = Loadable({
  loader: () => import(/* webpackChunkName: "deal.chunk" */'../../views/authenticated/deal'),
  loading: Loader,
});

export const AsyncWalletPage = Loadable({
  loader: () => import(/* webpackChunkName: "wallet.chunk" */'../../views/authenticated/wallets'),
  loading: Loader,
});

export const AsyncWalletDetailPage = Loadable({
  loader: () => import(/* webpackChunkName: "walletDetail.chunk" */'../../views/authenticated/wallet'),
  loading: Loader,
});

export const AsyncProfilePage = Loadable({
  loader: () => import(/* webpackChunkName: "profile.chunk" */'../../views/authenticated/profile'),
  loading: Loader,
});

export const AsyncCompanyProfilePage = Loadable({
  loader: () => import(/* webpackChunkName: "profile.chunk" */'../../views/authenticated/company-profile'),
  loading: Loader,
});

export const AsyncPortfolioPage = Loadable({
  loader: () => import(/* webpackChunkName: "portfolio.chunk" */'../../views/authenticated/portfolio'),
  loading: Loader,
});

