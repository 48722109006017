export default ({
  id,
  email,
  full_name,
  gender,
  country,
  kycStatus,
  isEmailVerified,
  isLegacyUser,
  pendingInvestments,
  fundedInvestments,
  pledges,
  canInvest
}) => {

  return (
    window.pendo.initialize({
      visitor: {
        id,
        email,
        full_name,
        gender,
        country,
        kycStatus,
        isEmailVerified,
        isLegacyUser,
        pendingInvestments,
        fundedInvestments,
        pledges,
        canInvest
      }
    })
  );
};