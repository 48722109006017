import { takeEvery, put, call } from 'redux-saga/effects';

import { USER_TYPES } from '../../types';
import SessionStore from '../../../utils/session-store';
import EntityService from '../../../api/entity.service';
import UserActions from '../../actions/user.actions';

/**
 * Redux Saga generator function for withdrawing funds
 */
function* createBankAccount(ba){
    const token = SessionStore.getSession();
    const body =  yield call(() => EntityService.createBankAccountsforEntity(token, ba));
    yield put(UserActions.createBankAccountSuccess(body.data?.[0]));
}

function createPostBankAccount() {
  return function* (options) {
    try {
      const { bankAccounts } = options.payload
      for(let b of bankAccounts){
        yield createBankAccount(b);
      }
    } catch (error) {
      const failureAction = UserActions.createBankAccountFailure(error);
      yield put(failureAction);
    }
  };
}

export const postBankAccount = createPostBankAccount();

export function* postBankAccountsWatcher() {
  yield takeEvery(USER_TYPES.CREATE_BANK_ACCOUNT_BEGIN, postBankAccount);
}
