import 'isomorphic-fetch';
import { fetchResource } from './fetch-resource';

import { API } from '../constants';
import COMMUNICATION_PREF_TYPES from '../constants/communicate-preference-types';

/**
 * Communication Preferences API service
 */
class CommunicationPreferenceService {
  /**
   * Sets communication preferences for a specific user on signup
   * @example
   * createCommunicationPreferencesOnSignup('someJwt', 'some user Id', true, false, ['sms','inapp']).then(res => { return res.data }).then(...)
   * @returns {Promise} Returns a promise containing the result.
   */
  static createCommunicationPreferencesOnSignup = (
    jwt,
    userId,
    optInMarketing,
    optInOpportunities,
    defaultChannels = [COMMUNICATION_PREF_TYPES.Email]) => {
    // opt in user to default channels.
    const options = {
      user_id: userId,
    };

    options.updates = optInMarketing ? defaultChannels : [];
    options.opportunities = optInOpportunities ? defaultChannels : [];

    const requestConfig = {
      cache: 'no-cache',
      mode: 'cors',
      headers: {
        'content-type': 'application/json',
        'authorization': `bearer ${ jwt }`,
      },
      body: JSON.stringify(options),
      method: 'POST',
    };

    return fetchResource(`${ API.COMMUNICATION_PREFERENCE }`, requestConfig);
  }
}

export default CommunicationPreferenceService;
