import { takeLatest, put, call } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import qs from 'query-string';

import userService from '../../../api/user.service';
import { SESSION_TYPES } from '../../types';
import SessionActions from '../../actions/session.actions';
import UserActions from '../../actions/user.actions';
import SessionStore from '../../../utils/session-store';
import Action from '../../actions/helpers/action';
import SessionContext from '../../../utils/session-context';
import Paths from '../../../constants/paths';

/**
 * Redux Saga generator function for sign in
 */
function createSignIn() {
  return function* (options) {
    try {
      const username = options.payload.email;
      const password = options.payload.password;

      const system = process.env.REACT_APP_API_SYSTEM;
      const subsystem = options.payload.subsystem || process.env.REACT_APP_API_SUBSYSTEM;

      const body = yield call(() => userService.emailLogin(username, password, system, subsystem));
      const result = body.data[0];

      SessionStore.setSession(result.access_token, result.refresh_token, subsystem);

      const context = SessionContext.get(result.access_token);
      const entities = context.entities || [];
      const legacy = context.legacy;
      const userId = context.sub;
      const demandPartner = context.demandPartner;
      const channelPartner = context.channelPartner;

      //firing off the fetch process of userLevels
      yield put(UserActions.getUserLevels(userId));

      //Getting user agreements
      yield put(UserActions.getUserAgreements());


      const successAction = SessionActions.signinSuccess(entities, username, true, legacy, demandPartner, channelPartner, subsystem); //TODO: remove account verified dependencies - temp always returning account verified as true

      yield put(successAction);
    } catch (error) {
      const INVALID_CREDENTIALS_CODE = 100;
      const DEFAULT_PASSWORD_CODE = 101;
      const failureAction = SessionActions.signinFailure(error);

      if (error.apiErrors && error.apiErrors.some((ele) => ele.code === INVALID_CREDENTIALS_CODE)) {
        const alertMessage = 'Invalid username or password.';
        yield put(Action.withDisplayMessage(failureAction, alertMessage));
      } else if (error.apiErrors && error.apiErrors.some((ele) => ele.code === DEFAULT_PASSWORD_CODE)) {
        const queryString = qs.parse(window.location.search, { ignoreQueryPrefix: true });
        const updateQueryString = { ...queryString, reason: 'system' };
        const querySearch = qs.stringify(updateQueryString);
        yield put(push({
          pathname: Paths.RESET_PASSWORD,
          search: querySearch,
        }));
      } else {
        yield put(failureAction);
      }
    }
  };
}

export const signIn = createSignIn();

export function* getSignInWatcher() {
  yield takeLatest(SESSION_TYPES.SIGNIN_BEGIN, signIn);
}
