import 'isomorphic-fetch';
import { fetchResource } from './fetch-resource';

import { API } from '../constants';

class DemandPartnerService {
  /**
   * Gets Demand Partners by their code.
   * @example
   * @returns {Promise} Returns a promise containing the result in json format.
   */

  static getByCode = (jwt, code) => {
    const requestConfig = {
      cache: 'no-cache',
      mode: 'cors',
      headers: {
        'content-type': 'application/json',
        'authorization': `bearer ${ jwt }`,
      },
      method: 'GET',

    };

    return fetchResource(`${ API.DEMAND_PARTNERS }?code=${ code }`, requestConfig);
  }

  /**
   * Gets all demand partners.
   * @example
   * @returns {Promise} Returns a promise containing the result in json format.
   */

  static getAll = (jwt) => {
    const requestConfig = {
      cache: 'no-cache',
      mode: 'cors',
      headers: {
        'content-type': 'application/json',
        'authorization': `bearer ${ jwt }`,
      },
      method: 'GET',
    };

    return fetchResource(`${ API.DEMAND_PARTNERS }?limit=1000`, requestConfig);
  }

  /**
   * Get default dp and cp for specified subsystem
   * @example
   * @returns {Promise} Returns a promise containing the result in json format.
   */

  static getdefaultsBySubsystem = (subsystem = 'global') => {
    const requestConfig = {
      cache: 'no-cache',
      mode: 'cors',
      headers: {
        'content-type': 'application/json',
        'authorization': 'bearer',
      },
      method: 'GET',
    };

    return fetchResource(`${ API.PARTNER_DEFAULTS }?subsystem=${subsystem}`, requestConfig);
  }
}

export default DemandPartnerService;
