import React from 'react';
import propTypes from 'prop-types';

import wpLogoSrc from '../../images/logo-wealthpoint.svg';
import LemonWayLogo from '../../images/logos/lemon-way-simple.png';

import './stylesheet.css';

const Footer = ({ className = '' }) => {

  return (
    <footer className={ `c-footer ${ className }`}>
      <aside className="c-footer__provider">
        <a href="http://www.lemonway.eu/" title="Lemon Way - Payment Institution for the new economy" target="_blank" rel="noopener noreferer"><img src={ LemonWayLogo } /></a>
        <small>Partner of Lemon Way, a European payment institution accredited in France by the ACPR (CIB 16568). - <a href="https://www.lemonway.com/en/terms-and-conditions/" target="_blank" rel="noreferer nofollow">Lemon Way Terms &amp; Conditions</a></small>
      </aside>
      <figure className="c-footer__logo">
        <small>powered by</small>
        <img src={ wpLogoSrc } alt="Powered by Wealthpoint" />
      </figure>
      
      
    </footer>
    
  );

};

Footer.propTypes = {
  className: propTypes.string,
};

export default Footer;
