import 'isomorphic-fetch';
import { fetchResource } from './fetch-resource';

import { API } from '../constants';

/**
 * Product API service
 */
class WalletsService {
  /**
   * Retrieves the wallet for a specified entity
   * @example
   * getProductsForListing('someJwt').then(res => { return res.data }).then(...)
   * @returns {Promise} Returns a promise containing the result in json format.
   */
  static getWallets = (jwt) => {
    const requestConfig = {
      cache: 'no-cache',
      mode: 'cors',
      headers: {
        'content-type': 'application/json',
        'authorization': `bearer ${ jwt }`,
      },
      method: 'GET',
    };

    return fetchResource(`${ API.WALLETS }`, requestConfig);
  }
  static getWalletsByEntityID = (jwt, entity_id) => {
    const requestConfig = {
      cache: 'no-cache',
      mode: 'cors',
      headers: {
        'content-type': 'application/json',
        'authorization': `bearer ${ jwt }`,
      },
      method: 'GET',
    };

    return fetchResource(`${ API.WALLETS }/${ entity_id }`, requestConfig);
  }

  static initWalletForEntityID = (jwt, entity_id, integrationIdentifier = '', currencyISO = '') => { 
    const body = {
      integration_identifier: integrationIdentifier,
      currency_iso: currencyISO
    }
    const requestConfig = {
      cache: 'no-cache',
      mode: 'cors',
      headers: {
        'content-type': 'application/json',
        'authorization': `bearer ${ jwt }`,
      },
      method: 'POST',
      body: JSON.stringify(body)
    };

    return fetchResource(`${ API.WALLETS }/${ entity_id }/init`, requestConfig);
  }

  static convertCurrencies = (jwt, converstions  = []) => {
    const body = converstions

    const requestConfig = {
      cache: 'no-cache',
      mode: 'cors',
      headers: {
        'content-type': 'application/json',
        'authorization': `bearer ${ jwt }`,
      },
      method: 'POST',
      body: JSON.stringify(body),
    };

    return fetchResource(`${ API.CURRENCY }/convert`, requestConfig);
  }

  /**
   * A function to withdraw funds to a client's bank account.
   * @ignore - Still needs to be tested.
   */
  static withdrawFunds = (jwt, entityId, currency_iso, amount, integrationIdentifier) => {
    const body = {
      currency_iso: currency_iso?.toUpperCase(),
      amount: `${amount}`,
      integration_identifier: integrationIdentifier
    }

    const requestConfig = {
      cache: 'no-cache',
      mode: 'cors',
      headers: {
        'content-type': 'application/json',
        'authorization': `bearer ${ jwt }`,
      },
      method: 'POST',
      body: JSON.stringify(body),
    };

  return fetchResource(`${ API.WALLETS }/${ entityId }/withdraw`, requestConfig);}
}

export default WalletsService;
