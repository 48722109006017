import { takeLatest, put, call } from 'redux-saga/effects';
import { createPatch } from 'rfc6902';
import { streamlinePatches, flattenDeep, greenlightPatches } from '../../../utils'

import EntityService from '../../../api/entity.service';
import { USER_TYPES } from '../../types';
import UserActions from '../../actions/user.actions';
import SessionStore from '../../../utils/session-store';
import SessionContext from '../../../utils/session-context';

function* notifyEmailChange(entityDiff) {
  const emailPatch = entityDiff.find(ed => ed.path === '/email');
  emailPatch && emailPatch.value ?
  yield put(UserActions.sendVerificationEmail(emailPatch.value, window?.location?.hostname))
  : yield;
}

/**
 * Redux Saga generator function for getting user entities
 */
function updatePersonalEntity() {
  return function* (options) {
    try {
      const token = SessionStore.getSession();
      const userId = SessionContext.get(token).sub;

      const originalEntities = yield call(() => EntityService.getEntitiesForUser(token, userId));

      const prepareLocalEntity = (entity) => {
        const newEntity = entity;

        //Single address for personal - list of address for companies and trusts
        if (newEntity?.content_type !== 'personal' && newEntity?.content?.address) {
          newEntity.content.addresses = [ newEntity.content.address ];
          delete newEntity.content.address;
        }

        return {
          ...newEntity,
          content: {
            ...newEntity?.content
          }
        }
      };

      const prepareIncEntity = (entity) => {
        //ensure optional bank accounts field is initialized
        return { 
          ...entity,
          content: {
            ...entity?.content
          }
        };
      }

      const localEntity = prepareLocalEntity(options.payload.entity);
      const remoteEntity = prepareIncEntity(originalEntities.data.find(entity => entity._id === localEntity._id)); //eslint-disable-line

      const entityDiff = createPatch(remoteEntity, localEntity).map(diffItem => streamlinePatches(diffItem))
        ?.filter(x => x !== undefined);

      const flattenedEntityDiff = entityDiff.map(item => { return Array.isArray(item); }).includes(true) ? flattenDeep(entityDiff) : entityDiff;

      const restrictedPaths = ['/ext_id', '/lemonway_id', '/lw_kyc_status', '/content/bank_account/source_of_funds'];

      const greenlitPatches = greenlightPatches(flattenedEntityDiff, restrictedPaths);

      if (greenlitPatches.length > 0) {
        const response = yield call(() => EntityService.updateEntity(token, remoteEntity._id, greenlitPatches)); // eslint-disable-line
        const effect = response.data[0];
        const updateAction = UserActions.updatePersonalEntitySuccess(effect);
        yield* notifyEmailChange(entityDiff);
        yield put(UserActions.getCanInvest(effect._id)); //eslint-disable-line
        yield put(updateAction);
      } else {
        const nonUpdateAction = UserActions.updatePersonalEntitySuccess(remoteEntity);

        yield put(nonUpdateAction);
      }
    } catch (error) {
      const failureAction = UserActions.updatePersonalEntityFailure(error);
      yield put(failureAction);
    }
  };
}

export const updatedEntity = updatePersonalEntity();

export function* updatePersonalEntityWatcher() {
  yield takeLatest(USER_TYPES.UPDATE_PERSONAL_ENTITY_BEGIN, updatedEntity);
}
