import { KYC_TYPES } from '../types/kyc.types';

/**
 * Redux Reducer for kycInfo
 */

const initialState = {
  loading: false,
  countryFields: [],
};

const actionsMap = {
  [KYC_TYPES.GETBANKINGFIELDS_BY_ISO2_BEGIN]: (state) =>
    ({
      ...state,
      loading: true,
      error: undefined,
    }),
  [KYC_TYPES.GETBANKINGFIELDS_BY_ISO2_FAILURE]: (state, action) =>
    ({
      ...state,
      loading: false,
      error: action.payload.error,
    }),
  [KYC_TYPES.GETBANKINGFIELDS_BY_ISO2_SUCCESS]: (state, action) =>
    ({
      ...state,
      loading: false,
      countryFields: action.payload.countryFields,
      error: undefined
    }),
};

export default function reducer(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
