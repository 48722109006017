import PATH from '../paths';

const name = 'Women and Wealth';
const key = 'women';
const imgUrl = `${ PATH.CDN }/whitelabels/${ key }`;
const images = {
  favicon: `${ PATH.CDN }/whitelabels/global/favicon.png`,
  logo: `${ imgUrl }/logo-white.png`, 
  logoSidebar: `${ PATH.CDN }/whitelabels/global/logo.png`,
  logoPDF: `${ imgUrl }/logo-pdf.png`,
  opengraph: false,
  backgrounds: [
    `${ imgUrl }/background.jpg`
  ],
};

export default {

  name,
  key,
  images,
  stylesheet: `

    /* COLOR VARIABLES +++++++++++++++++++++++++++++++++++++++++++++++++++++  */

    --color-women-pink:               #e37574;
    --color-women-pink-light:         #f6cecc;
    --color-women-pink-dark:          #834640; 

    --color-primary:                  var(--color-women-pink);
    --color-secondary:                var(--color-women-pink-light);
    --color-accent:                   var(--color-women-pink);

    --color-link:                     var(--color-women-pink-dark);
    --color-link-hover:               var(--color-secondary);

    /* END +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++  */

    /* TYPOGRAPHY ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++  */

    --color-link:                     var(--color-accent-green);
    --color-link-hover:               var(--color-secondary);

    /* END +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++  */
    
    /* LAYOUTS +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++  */

    /* ANONYMOUS LAYOUT */

    --anonymous-logo-bg:              var(--color-primary);
    --anonymous-copy-bg:              var(--color-secondary);
    --anonymous-heading-color:        var(--color-accent-green);
    --anonymous-subheading-color:     var(--color);
    --anonymous-text-color            var(--color-white);
    --anonymous-logo-height:          50px;
    --anonymous-bg:                   url(${ images.backgrounds[0] });
    --anonymous-bg-size:              cover;

    /* END */

    /* SIDEBAR */

    --sidebar-bg-color: var(--color-secondary);
    --sidebar-logo-bg-color: var(--color-primary);
    --sidebar-logo-height: 45px;
    --sidebar-label-color: var(--color-primary);
    --sidebar-border-color: var(--color-primary);

    --sidebar-button-color: var(--color-darker);
    --sidebar-button-color-active: var(--color-darker);
    --sidebar-button-color-hover: var(--color-darker);
    --sidebar-button-bg-color: transparent;
    --sidebar-button-bg-color-active: var(--color-accent);
    --sidebar-button-bg-color-hover: var(--color-accent);
    --sidebar-button-border-color: var(--color-accent);
    --sidebar-button-border-color-active: var(--color-accent);
    --sidebar-button-border-color-hover: var(--color-accent);

    /* END */

    /* END +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++  */

    /* BUTTONS +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++  */

    /* PRIMARY BUTTON */

    --btn-primary--bg:                var(--color-secondary);
    --btn-primary--border:            var(--color-secondary);
    --btn-primary--color:             var(--color-darker);
    --btn-primary--colorH:            var(--color-darker);
    --btn-primary--bgH:               var(--color-accent);
    --btn-primary--borderH:           var(--color-accent);

    /* END */

    /* SECONDARY BUTTON */

    --btn-secondary--bg:              transparent;
    --btn-secondary--border:          var(--color-accent);
    --btn-secondary--color:           var(--color-darker);
    --btn-secondary--bgH:             transparent;
    --btn-secondary--borderH:         var(--color-accent);
    --btn-secondary--colorH:          var(--color-accent);

    /* END */

    /* END +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++  */
`,

};
