import { createStore, applyMiddleware, compose } from 'redux';
import { createBrowserHistory } from 'history';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import thunk from 'redux-thunk';
import logger from '../../dev/logger';

import rootSaga from '../sagas';
import rootReducer from '../reducers';

import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage/session';

/**
 * Redux Store configuration
 */

const IS_PRODUCTION = process.env.NODE_ENV === 'production';

const initialState = {};

// Creating store
export default () => {
  let store = null;
  let middleware = null;

  const history = createBrowserHistory();
  const sagaMiddleware = createSagaMiddleware();
  const routingMiddleware = routerMiddleware(history);// for dispatching history actions

  if (IS_PRODUCTION) {
    middleware = applyMiddleware(routingMiddleware, sagaMiddleware, thunk);
  } else {
    // In development mode beside sagaMiddleware
    // logger and DevTools are added
    middleware = applyMiddleware(routingMiddleware, sagaMiddleware, logger, thunk);

    // Enable DevTools if browser extension is installed
    if (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION__) { // eslint-disable-line
      const composeEnhancers = (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
            window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true, traceLimit: 25 })) || compose;
      middleware = composeEnhancers(applyMiddleware(routingMiddleware, sagaMiddleware, logger, thunk))
    }
  }

  // Create store

  const persistConfig = {
    key: 'root',
    storage: storage,
    blacklist: ['user', 'session', 'documents', 'portfolio', 'wallets', 'pledges', 'investments', 'ui']
  };

  const pReducer = persistReducer(persistConfig, rootReducer);
  // with initial state if it exists
  store = createStore(
    connectRouter(history)(pReducer),
    initialState,
    middleware
  );

  // Run root saga
  sagaMiddleware.run(rootSaga);

  // Enable Webpack hot module replacement for reducers
  if (module.hot) {
    module.hot.accept('../reducers', () => {
      const nextRootReducer = require('../reducers/index').default; // eslint-disable-line global-require
      store.replaceReducer(nextRootReducer);
    });
  }

  const persistor = persistStore(store);

  return {
    store,
    history,
    persistor,
  };
};
