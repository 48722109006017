import React from 'react';
import propTypes from 'prop-types';
import Notification from '@traaidmark/konstruct-part-notification';

import iconSrc from '../../images/icons/notification-red.svg';

const Error = ({ children, className = '' }) => {
  return (
    <Notification className={ `p-notification--important ${ className }`} icon={ iconSrc }>
      { children }
    </Notification>
  )
};

Error.propTypes = {
  children: propTypes.node,
  className: propTypes.string
}

export default Error;
