export const PLEDGE_TYPES = {
  GENERATE_DEPOSIT_REFERENCE_BEGIN: 'GENERATE_DEPOSIT_REFERENCE_BEGIN',
  GENERATE_DEPOSIT_REFERENCE_SUCCESS: 'GENERATE_DEPOSIT_REFERENCE_SUCCESS',
  GENERATE_DEPOSIT_REFERENCE_FAILURE: 'GENERATE_DEPOSIT_REFERENCE_FAILURE',

  UPDATE_DEPOSIT_REFERENCE_BEGIN: 'UPDATE_DEPOSIT_REFERENCE_BEGIN',
  UPDATE_DEPOSIT_REFERENCE_SUCCESS: 'UPDATE_DEPOSIT_REFERENCE_SUCCESS',
  UPDATE_DEPOSIT_REFERENCE_FAILURE: 'UPDATE_DEPOSIT_REFERENCE_FAILURE',

  DELETE_DEPOSIT_REFERENCE_BEGIN: 'DELETE_DEPOSIT_REFERENCE_BEGIN',
  DELETE_DEPOSIT_REFERENCE_SUCCESS: 'DELETE_DEPOSIT_REFERENCE_SUCCESS',
  DELETE_DEPOSIT_REFERENCE_FAILURE: 'DELETE_DEPOSIT_REFERENCE_FAILURE',

  GET_DEPOSIT_REFERENCES_BEGIN: 'GET_DEPOSIT_REFERENCES_BEGIN',
  GET_DEPOSIT_REFERENCES_SUCCESS: 'GET_DEPOSIT_REFERENCES_SUCCESS',
  GET_DEPOSIT_REFERENCES_FAILURE: 'GET_DEPOSIT_REFERENCES_FAILURE',

  GET_FILTERED_DEPOSIT_REFERENCES_BEGIN: 'GET_FILTERED_DEPOSIT_REFERENCES_BEGIN',
  GET_FILTERED_DEPOSIT_REFERENCES_SUCCESS: 'GET_FILTERED_DEPOSIT_REFERENCES_SUCCESS',
  GET_FILTERED_DEPOSIT_REFERENCES_FAILURE: 'GET_FILTERED_DEPOSIT_REFERENCES_FAILURE',
};
