import { takeLatest, put, call } from 'redux-saga/effects';
import { COUNTRIES_TYPES } from '../../types';
import countryService from '../../../api/country.service';
import CountriesActions from '../../actions/countries.actions';
import SessionStore from '../../../utils/session-store';

/**
 * Redux Saga generator function for getting countries
 */
function createGetCountries() {
  return function* (options) { // eslint-disable-line
    try {
      const token = SessionStore.getSession();

      const countries = yield call(() => countryService.getCountries(token));
      const action = CountriesActions.success(countries.data);

      yield put(action);
    } catch (error) {
      const failureAction = CountriesActions.error(error);

      yield put(failureAction);
    }
  };
}

export const getCountries = createGetCountries();

export function* getCountriesWatcher() {
  yield takeLatest(COUNTRIES_TYPES.GET_ALL_COUNTRIES, getCountries);
}

export default [
  getCountriesWatcher(),
];
