module.exports = {
  pointer: 'Your KYC status will be reset if you change your details.',
  
  locked: {
    heading: 'Form is locked',
    text: 'Changing your details is disabled while we are busy verifying your information.'
  },
  switchable: {
    heading: 'Changing your details will reset your KYC status',
    text: 'If you change your details now your KYC status will be reset, and your profile will have to be reviewed again before you can invest.'
  }
}