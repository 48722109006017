import { Map } from 'immutable';
import { UI_TYPES } from '../types';

/**
 * Redux Reducer for alerts
 */

const initialState = {
  task: false,
  taskName: '',
  taskStep: 0,
  dashbar: undefined,
  ui: {},
  filters: { 
    status: undefined,
    structure: undefined,
    marketplace: 'All Asset Classes',
    structures: [],
    statuses: [],
    currencies: 'All Currencies'
  },
};

const actionsMap = {
  [UI_TYPES.SET_DASHBAR]: (state, action) =>
    ({
      ...state,
      dashbar: action.payload.data,
    }),
  [UI_TYPES.TOGGLE_DRAWER]: (state, action) =>
    ({
      ...state,
      drawer: action.payload.data,
    }),
  [UI_TYPES.TOGGLE_TASK]: (state, { payload }) =>
    ({
      ...state,
      task: payload.task,
      drawer: payload.drawer,
      taskName: payload.taskName,
      taskStep: payload.taskStep,
      taskData: payload.taskData,
    }),
  [UI_TYPES.SET_UI]: (state, { payload }) =>
    ({
      ...state,
      ui: payload,
    }),
  [UI_TYPES.SET_FILTERS]: (state, { payload }) =>
    {
      return {
      ...state,
      filters: {
        status: 'status' in payload ? payload?.status : state?.filters?.status,
        structure: 'structure' in payload ? payload?.structure : state?.filters?.structure,
        marketplace: 'marketplace' in payload ? payload?.marketplace : state?.filters?.marketplace,
        statuses: 'statuses' in payload ? payload?.statuses : state?.filters?.statuses,
        structures: 'structures' in payload ? payload?.structures : state?.filters?.structures,
        currencies: 'currencies' in payload ? payload?.currencies : state?.filters?.currencies,
      },
    }
  },
};

export default function reducer(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
