import 'isomorphic-fetch';
import { fetchResource } from './fetch-resource';

import { API } from '../constants';

/**
 * Lister API service
 */
class ListerService {
  /**
   * Retrieves a single lister by name
   * @example
   * getListerById('someJwt').then(res => { return res.data }).then(...)
   * @returns {Promise} Returns a promise containing the result in json format.
   */
  static getListerByName = (jwt, name) => {
    const requestConfig = {
      cache: 'no-cache',
      mode: 'cors',
      headers: {
        'content-type': 'application/json',
        'authorization': `bearer ${ jwt }`,
      },
      method: 'GET',
    };

    return fetchResource(`${ API.LISTER }?name=${ name }`, requestConfig);
  }

  /**
   * Returns a filtered list of listers by subsystem specified
   * @example
   * getListerBySubsystem('someJwt', 'orbvest').then(res => { return res.data }).then(...)
   * @returns {Promise} Returns a promise containing the result in json format.
   */

  static getListerBySubsystem = (jwt, subsystem) => {
    const requestConfig = {
      cache: 'no-cache',
      mode: 'cors',
      headers: {
        'content-type': 'application/json',
        'authorization': `bearer ${ jwt }`,
      },
      method: 'GET',
    };

    return fetchResource(`${ API.LISTER }?subsystem=${ subsystem }`, requestConfig);
  }
}

export default ListerService;
