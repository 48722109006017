import React from 'react';
import propTypes from 'prop-types';

import './stylesheet.css';
import { useCallback } from 'react';

const PopupNav = ({ 
  isHidden,
  items = [],
  orientation = 'left',
}) => {

  if(isHidden) return null;

  const orientationClass = () => {
    if (orientation === 'right') {
      return 'c-popup-nav--align-right u-arrow--top-right';
    }
    return 'c-popup-nav--align-left u-arrow--top-left';
  }

  return (
    <nav class={ `c-popup-nav u-arrow ${ orientationClass() }` }>
      <ul className="u-list-vertical">
        {
          items.length > 0 && items.map((i,k) => (
            <li key={ `popupnav-${ items.length }-${ k }` }>
              { i }
            </li>
          ))
        }
      </ul>
    </nav>
  );
};


PopupNav.propTypes = {
  items: propTypes.array,
  isHidden: propTypes.bool,
  orientation: propTypes.oneOf(['left', 'right']),
};

export default PopupNav;
