import { takeLatest, put, call, all } from 'redux-saga/effects';

import { INVESTMENT_TYPES } from '../../types';
import InvestmentActions from '../../actions/investment.actions';
import bookbuildService from '../../../api/bookbuild.service';
import altinvestmentService from '../../../api/alt-investment.service';
import SessionStore from '../../../utils/session-store';
import RulesService from '../../../api/rules.service';
import SessionContext from '../../../utils/session-context';
import { applyCoC, applyIrr, getCalcData } from '../../../utils/ProductReturns';

export function* getOrdersByProductId(action) {
  try {
    const token = SessionStore.getSession();
    const subsystem = SessionStore.getSubsystem();
    const userId = SessionContext.get(token)?.sub;
    const {
      productId,
    } = action.payload;
    const orders = yield call(() => bookbuildService.getEntityProductOrders(token, productId))
    yield put(InvestmentActions.getOrdersByProductIdSuccess(orders));
  } catch (error) {
    yield put(InvestmentActions.getOrdersByProductIdError(error));
  }
}

export function* getOrdersByProductIdWatcher() {
  yield takeLatest(INVESTMENT_TYPES.GETORDERSBYPRODUCTID_BEGIN, getOrdersByProductId);
}
