import { DOCUMENT_TYPES } from '../types';
import Action from './helpers/action';

export default class DocumentAction {
  /**
   * Retrieve all documents associated with a product or entity
   * @example
   * getAllAssociated()
   */
  static getAllAssociated = (associatedId) => {
    return Action.create(DOCUMENT_TYPES.GETDOCUMENTS_BEGIN, { associatedId });
  }

  /**
   * Retrieve all documents succeeded
   * @example
   * success([{payload}])
   */
  static success = (documents) => {
    return Action.create(DOCUMENT_TYPES.GETDOCUMENTS_SUCCESS, { documents });
  }

  /**
   * Retrieve all documents failed.
   * @example
   * error(errorObj)
   */
  static error = (errorObj) => {
    return Action.createError(DOCUMENT_TYPES.GETDOCUMENTS_FAILURE, { error: errorObj });
  }

  /**
   * Retrieve all documents associated with a product or entity
   * @example
   * getAllAssociated()
   */
   static getAllDocuments = (associatedId, ids) => {
    return Action.create(DOCUMENT_TYPES.GETALLDOCUMENTS_BEGIN, { associatedId, ids });
  }

  /**
   * Retrieve all documents succeeded
   * @example
   * success([{payload}])
   */
  static getAllSuccess = (documents) => {
    return Action.create(DOCUMENT_TYPES.GETALLDOCUMENTS_SUCCESS, { documents });
  }

  /**
   * Retrieve all documents failed.
   * @example
   * error(errorObj)
   */
  static getAllError = (errorObj) => {
    return Action.createError(DOCUMENT_TYPES.GETALLDOCUMENTS_FAILURE, { error: errorObj });
  }

  /**
   * Retrieve only the documents who have the ids specified.
   * @example
   * getAllAssociated()
   */
  static getDocumentsById = (ids) => {
    return Action.create(DOCUMENT_TYPES.GETDOCUMENTS_BY_ID_BEGIN, { ids });
  }

  /**
   * getDocumentsById succeeded
   * @example
   * success([{payload}])
   */
  static getDocumentsByIdSuccess = (documents) => {
    return Action.create(DOCUMENT_TYPES.GETDOCUMENTS_BY_ID_SUCCESS, { documents });
  }

  /**
   * getDocumentsById failed.
   * @example
   * error(errorObj)
   */
  static getDocumentsByIdFailure = (errorObj) => {
    return Action.create(DOCUMENT_TYPES.GETDOCUMENTS_BY_ID_FAILURE, { error: errorObj });
  }

  static getTransactionDocumentsById = (entityId, currency, startDate, endDate, timezoneOffset) => {
    return Action.create(DOCUMENT_TYPES.GET_TRANSACTION_DOCUMENTS_BY_ID_BEGIN, { entityId, currency, startDate, endDate, timezoneOffset });
  }

  static getTransactionDocumentsByIdSuccess = () => {
    return Action.create(DOCUMENT_TYPES.GET_TRANSACTION_DOCUMENTS_BY_ID_SUCCESS);
  }

  static getTransactionDocumentsByIdFailure = (errorObj) => {
    return Action.create(DOCUMENT_TYPES.GET_TRANSACTION_DOCUMENTS_BY_ID_FAILURE, { error: errorObj });
  }

  static getProductInfoDocument = (productId, subsystem, subsystemHost, minimum_investment, initiation_fee_rate, subsystemLogo, irr, coc, cap_return_rate,cap_return_succ_fee_rate, success_fee_rate) => {
    return Action.create(DOCUMENT_TYPES.GET_PRODUCT_INFO_DOCUMENT_BEGIN, { productId, subsystem, subsystemHost, minimum_investment, initiation_fee_rate, subsystemLogo, irr, coc, cap_return_rate,cap_return_succ_fee_rate, success_fee_rate });
  }

  static getProductInfoDocumentSuccess = () => {
    return Action.create(DOCUMENT_TYPES.GET_PRODUCT_INFO_DOCUMENT_SUCCESS);
  }

  static getProductInfoDocumentFailure = (errorObj) => {
    return Action.create(DOCUMENT_TYPES.GET_PRODUCT_INFO_DOCUMENT_FAILURE, { error: errorObj });
  }

  /**
   * upload a document.
   * @example
   * getAllAssociated()
   */
  static uploadDocument = (document, associatedId, renderInline, tags, country) => {
    return Action.create(DOCUMENT_TYPES.UPLOAD_DOCUMENT_BEGIN, { document, associatedId, renderInline, tags, country });
  }

  /**
   * uploadDocumentSuccess succeeded
   * @example
   * success([{payload}])
   */
  static uploadDocumentSuccess = (document) => {
    return Action.create(DOCUMENT_TYPES.UPLOAD_DOCUMENT_SUCCESS, { document });
  }

  /**
   * getDocumentsById failed.
   * @example
   * error(errorObj)
   */
  static uploadDocumentFailure = (errorObj) => {
    return Action.create(DOCUMENT_TYPES.UPLOAD_DOCUMENT_FAILURE, { error: errorObj });
  }

   // delete Deposit Document
   static deleteDocument = (documentId) => {
    return Action.create(DOCUMENT_TYPES.DELETE_DOCUMENT_BEGIN, { documentId });
  }

  static deleteDocumentSuccess = (documentId) => { // Return the deleted documentId so we can remove it from redux in the reducer
    return Action.create(DOCUMENT_TYPES.DELETE_DOCUMENT_SUCCESS, { documentId });
  }

  static deleteDocumentFailure = (errorObj) => {
    return Action.create(DOCUMENT_TYPES.DELETE_DOCUMENT_FAILURE, { error: errorObj });
  }
}
