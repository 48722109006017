import { DEMAND_PARTNERS_TYPES } from '../types';
import Action from './helpers/action';

export default class DemandPartnerActions {
  static getAll = () => {
    return Action.create(DEMAND_PARTNERS_TYPES.GET_ALL_DEMAND_PARTNERS);
  }

  static success = (demandPartners) => {
    return Action.create(DEMAND_PARTNERS_TYPES.GET_ALL_DEMAND_PARTNERS_SUCCESS, { demandPartners });
  }

  static error = (errorObj) => {
    return Action.createError(DEMAND_PARTNERS_TYPES.GET_ALL_DEMAND_PARTNERS_FAILURE, { error: errorObj });
  }

  static getDefaults = (subsystem) => {
    return Action.createError(DEMAND_PARTNERS_TYPES.GET_DEFAULT_SETTINGS, { subsystem });
  }

  static getDefaultsError = (errorObj) => {
    return Action.createError(DEMAND_PARTNERS_TYPES.GET_DEFAULT_SETTINGS_FAILURE, { error: errorObj });
  }

  static getDefaultsSuccess = (defaultSettings) => {
    return Action.createError(DEMAND_PARTNERS_TYPES.GET_DEFAULT_SETTINGS_SUCCESS, { defaultSettings });
  }

}

