import PATH from '../paths';

const name = 'MyWealth';
const key = 'mywealth';
const imgUrl = `${ PATH.CDN }/whitelabels/${ key }`;
const images = {
  favicon: `${ imgUrl }/favicon.png`,
  logo: `${ imgUrl }/mywealth-logo.png`,
  logoSidebar: `${ imgUrl }/logo.png`,
  opengraph: false,
  backgrounds: [
    `${ imgUrl }/background.jpg`
  ],
};

export default {
  name,
  key,
  images,
  stylesheet: `
  
    /* ANONYMOUS LAYOUT */

    --anonymous-bg:                   url(${ images.backgrounds[0] });
    --anonymous-bg-size:              cover;
    --anonymous-logo-bg:              #f7f7f7;
    --anonymous-logo-height:          70px;

    /* END */


    /* SIDEBAR */

    --sidebar-logo-bg-color:  #f7f7f7;

    /* END */
  
  `
  
}; 