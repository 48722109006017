import { takeLatest, put, call } from 'redux-saga/effects';

import DepositReferenceService from '../../../api/deposit-reference.service';
import ProductService from '../../../api/product.service';
import { PLEDGE_TYPES } from '../../types/pledge.types';
import PledgeActions from '../../actions/pledge.actions';

import SessionStore from '../../../utils/session-store';
import SessionContext from '../../../utils/session-context';

function getDepositReferences() {
  return function* () {
    try {
      const token = SessionStore.getSession();
      const userId = SessionContext.get(token).sub;
      const depositReferences = yield call(() => DepositReferenceService.getDepositReferenceByQuery(token, [`user_id=${userId}`]));
      const depositReferencesResult = depositReferences.data;
      const products = yield call(() => ProductService.getProductsByIdCollection(token, depositReferencesResult.map(dr => dr.product_id)));
      const productsResult = products.data;
      const createProductAssociation = (object, productsCollection) => {
        return {
          ...object,
          // eslint-disable-next-line no-underscore-dangle
          associatedProduct: productsCollection.find(product => product._id === object.product_id),
        };
      };
      const productAssocDepositRef = depositReferencesResult.map(d => createProductAssociation(d, productsResult));
      yield put(PledgeActions.getDepositReferencesSuccess(productAssocDepositRef));
    } catch (error) {
      yield put(PledgeActions.getDepositReferencesFailure(error));
    }
  };
}

const newDepositReferences = getDepositReferences();

export function* getDepositReferencesWatcher() {
  yield takeLatest(PLEDGE_TYPES.GET_DEPOSIT_REFERENCES_BEGIN, newDepositReferences);
}
