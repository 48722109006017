import { Finance } from 'financejs';

const getIRR = (cash_flow_data, fees) => {
  let finance = new Finance();
  const initiationFeeRate = fees?.base + fees?.structure + fees?.cap
  const st = cash_flow_data?.subTotal.length > 0 ? cash_flow_data?.subTotal : [parseFloat(cash_flow_data?.amount)]
  const irr_number = parseFloat(cash_flow_data?.amount) > 0 && st.length === cash_flow_data?.investment_term ?
    finance.IRR(-1 * (parseFloat(cash_flow_data?.amount) + (parseFloat(cash_flow_data?.amount) * (initiationFeeRate / 100)) + 0.0001), ...st)
    : 0;
  return irr_number
}

const getCashOnCash = (cash_flow_data, fees) => {
  const initiationFeeRate = fees?.base + fees?.structure + fees?.cap;
  const sum = (x, y) => x + y;
  const coc = parseFloat(cash_flow_data?.amount) > 0 ? ((cash_flow_data?.interest.reduce(sum, 0) - cash_flow_data?.successFee.reduce(sum, 0)) /
    (parseFloat(cash_flow_data?.amount) + (true ? parseFloat(cash_flow_data?.amount) * (initiationFeeRate / 100) : 0)) * 100) /
      cash_flow_data?.cash_flows?.length : 0;
  return Math.round(coc * 100) / 100
}

export const getCalcData = (p_id, fees) => {
  const { data } = fees;
  const productData = data.find(d => d?.product_id === p_id)
  const feeData = productData?.fees;
  const cashFlowData = productData?.cash_flow_data;
  return {
    feeData,
    cashFlowData
  }
}

export const applyIrr = (p, cashFlowData, feeData) => {
  return {...p, irr: p?.cash_flows?.length > 0 ? getIRR(cashFlowData, feeData) : p?.irr }
}

export const applyCoC = (p, cashFlowData, feeData) => ({...p, targeted_return: p?.cash_flows?.length > 0 ? getCashOnCash(cashFlowData, feeData) : p?.targeted_return})
