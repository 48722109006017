import React from 'react';
import propTypes from 'prop-types';
import { connect } from 'react-redux';
import { createGlobalStyle } from 'styled-components';

import { WHITELABEL } from '../../../constants';

const paintTheme = (name) => {
  if(name !== 'global' && WHITELABEL[name]) {
    return WHITELABEL[name].stylesheet;
  };
  return null;
};

const Painter = ({ ui }) => {

  const Theme = createGlobalStyle`
    :root {
      ${ paintTheme(ui.key) }
    }
  `;

  return <Theme />;
  
};

Painter.propTypes = {
  ui: propTypes.object,
};

const mapStateToProps = (state) => ({
  ui: state.ui.ui,
});

export default connect(mapStateToProps, null)(Painter);
