import { takeLatest, put, call } from 'redux-saga/effects';

import userLevelsService from '../../../api/user-levels.service';
import { USER_TYPES } from '../../types';
import UserActions from '../../actions/user.actions';

import SessionStore from '../../../utils/session-store';
import SessionContext from '../../../utils/session-context';

/**
 * Redux Saga generator function for getting user entities
 */
function createGetUserLevels() {
  return function* (options) {
    try {
      const token = SessionStore.getSession();
      const userId = SessionContext.get(token).sub;

      const body = yield call(() => userLevelsService.getUserLevels(token, userId));
      const userLevels = body.data[0];
      const action = UserActions.getUserLevelsSuccess(userLevels);

      yield put(action);
    } catch (error) {
      const failureAction = UserActions.getUserLevelsError(error);
      yield put(failureAction);
    }
  };
}

export const getUserLevels = createGetUserLevels();

export function* getUserLevelsWatcher() {
  yield takeLatest(USER_TYPES.GET_USER_LEVELS_BEGIN, getUserLevels);
}
