import { takeLatest, put, call } from 'redux-saga/effects';
import { DEMAND_PARTNERS_TYPES } from '../../types';
import DemandPartnersActions from '../../actions/demand-partners.actions';
import SessionStore from '../../../utils/session-store';
import demandPartnerService from '../../../api/demand-partner.service';

/**
 * Redux Saga generator function for getting demandPartners
 */
function createGetDeafultSettings() {
  return function* (options) { // eslint-disable-line
    try {
      const subsystem = options.payload.subsystem
      const defaultSettings = yield call(() => demandPartnerService.getdefaultsBySubsystem(subsystem));
      const action = DemandPartnersActions.getDefaultsSuccess(defaultSettings.data);

      yield put(action);
    } catch (error) {
      const failureAction = DemandPartnersActions.error(error);

      yield put(failureAction);
    }
  };
}

export const getDefaultSettings = createGetDeafultSettings();

export function* getDefaultSettingsWatcher() {
  yield takeLatest(DEMAND_PARTNERS_TYPES.GET_DEFAULT_SETTINGS, getDefaultSettings);
}

export default [
  getDefaultSettingsWatcher(),
];
