import { USER_TYPES } from "../types";
import Action from "./helpers/action";

export default class UserActions {
  static getEntities = () => {
    return Action.create(USER_TYPES.GET_ENTITIES_BEGIN);
  };

  static getEntitiesSuccess = (entities) => {
    return Action.create(USER_TYPES.GET_ENTITIES_SUCCESS, { entities });
  };

  static getEntitiesError = (errorObj) => {
    return Action.createError(USER_TYPES.GET_ENTITIES_FAILURE, {
      error: errorObj,
    });
  };

  static createPersonalEntity = (
    countryIsoCode,
    investorType,
    investorTypeCertified,
    investorRiskAcceptedDate,
    email,
    domainName,
    subsystem
  ) => {
    return Action.create(USER_TYPES.CREATE_PERSONAL_ENTITY_BEGIN, {
      countryIsoCode,
      investorType,
      investorTypeCertified,
      investorRiskAcceptedDate,
      email,
      domainName,
      subsystem,
    });
  };

  static createPersonalEntityFailure = (errorObj) => {
    return Action.createError(USER_TYPES.CREATE_PERSONAL_ENTITY_FAILURE, {
      error: errorObj,
    });
  };

  static createPersonalEntitySuccess = (entity) => {
    return Action.create(USER_TYPES.CREATE_PERSONAL_ENTITY_SUCCESS, { entity });
  };

  static createCompanyEntity = (
    countryIsoCode,
    investorType,
    investorTypeCertified,
    investorRiskAcceptedDate,
    entityType,
    entityName,
    registrationNumber
  ) => {
    return Action.create(USER_TYPES.CREATE_COMPANY_ENTITY_BEGIN, {
      countryIsoCode,
      investorType,
      investorTypeCertified,
      investorRiskAcceptedDate,
      entityType,
      entityName,
      registrationNumber,
    });
  };

  static createCompanyEntityFailure = (errorObj) => {
    return Action.createError(USER_TYPES.CREATE_COMPANY_ENTITY_FAILURE, {
      error: errorObj,
    });
  };

  static createCompanyEntitySuccess = (entity) => {
    return Action.create(USER_TYPES.CREATE_COMPANY_ENTITY_SUCCESS, { entity });
  };


  static updatePersonalEntity = (entity) => {
    return Action.create(USER_TYPES.UPDATE_PERSONAL_ENTITY_BEGIN, { entity });
  };


  static updatePersonalEntityFailure = (errorObj) => {
    return Action.createError(USER_TYPES.UPDATE_PERSONAL_ENTITY_FAILURE, {
      error: errorObj,
    });
  };


  static updatePersonalEntitySuccess = (entity) => {
    return Action.create(USER_TYPES.UPDATE_PERSONAL_ENTITY_SUCCESS, { entity });
  };

  static sendVerificationEmail = (email, domainName) => {
    return Action.create(USER_TYPES.VERIFICATION_EMAIL_SEND_BEGIN, {
      email,
      domainName,
    });
  };

  static sendVerificationEmailFailure = (errorObj) => {
    return Action.createError(USER_TYPES.VERIFICATION_EMAIL_SEND_FAILURE, {
      error: errorObj,
    });
  };

  static sendVerificationEmailSuccess = () => {
    return Action.create(USER_TYPES.VERIFICATION_EMAIL_SEND_SUCCESS, {
    });
  };

  static createBankAccount = (bankAccounts) => {
    return Action.create(USER_TYPES.CREATE_BANK_ACCOUNT_BEGIN, { bankAccounts });
  };

  static createBankAccountFailure = (errorObj) => {
    return Action.createError(USER_TYPES.CREATE_BANK_ACCOUNT_FAILURE, {
      error: errorObj,
    });
  };

  static createBankAccountSuccess = (bankAccount) => {
    return Action.create(USER_TYPES.CREATE_BANK_ACCOUNT_SUCCESS, {
      bankAccount,
    });
  };

  static getBankAccounts = (entityId) => {
    return Action.create(USER_TYPES.GET_BANK_ACCOUNT_BEGIN, { entityId });
  };

  static getBankAccountsFailure = (errorObj) => {
    return Action.createError(USER_TYPES.GET_BANK_ACCOUNT_FAILURE, {
      error: errorObj,
    });
  };

  static getBankAccountsSuccess = (bankAccounts) => {
    return Action.create(USER_TYPES.GET_BANK_ACCOUNT_SUCCESS, { bankAccounts });
  };

  static updateBankAccounts = (bankAccounts) => {
    return Action.create(USER_TYPES.UPDATE_BANK_ACCOUNT_BEGIN, { bankAccounts });
  };

  static updatebankAccountFailure = (errorObj) => {
    return Action.createError(USER_TYPES.UPDATE_BANK_ACCOUNT_FAILURE, {
      error: errorObj,
    });
  };

  static updatebankAccountSuccess = (bankAccount) => {
    return Action.create(USER_TYPES.UPDATE_BANK_ACCOUNT_SUCCESS, {
      bankAccount,
    });
  };

  /**
   * Used for when user tries to upload bank statement when bank account creation is still in progress.
   * ie. when no bank_account._id is present to pass as the associated id for the document uploaded
   */
  static associateBankStatement = (bankAccount, statement) => {
    return Action.create(USER_TYPES.ASSOCIATE_BANK_STATEMENT_BEGIN, { bankAccount, statement });
  };

  static associateBankStatementFailure = (errorObj) => {
    return Action.createError(USER_TYPES.ASSOCIATE_BANK_STATEMENT_FAILURE, {
      error: errorObj,
    });
  };

  static associateBankStatementSuccess = () => {
    return Action.create(USER_TYPES.ASSOCIATE_BANK_STATEMENT_SUCCESS, {
    });
  };

  static getCanInvest = (entityId) => {
    return Action.create(USER_TYPES.GET_CAN_INVEST_BEGIN, { entityId });
  };

  static getCanInvestError = (errorObj) => {
    return Action.createError(USER_TYPES.GET_CAN_INVEST_ERROR, {
      error: errorObj,
    });
  };

  static getCanInvestSuccess = (canInvest) => {
    return Action.create(USER_TYPES.GET_CAN_INVEST_SUCCESS, { canInvest });
  };

  static getUserLevels = () => {
    return Action.create(USER_TYPES.GET_USER_LEVELS_BEGIN, {});
  };

  static getUserLevelsError = (errorObj) => {
    return Action.createError(USER_TYPES.GET_USER_LEVELS_FAILURE, {
      error: errorObj,
    });
  };

  static getUserLevelsSuccess = (userLevels) => {
    return Action.create(USER_TYPES.GET_USER_LEVELS_SUCCESS, { userLevels });
  };

  static selectActiveEntity = (entity) => {
    return Action.create(USER_TYPES.SELECT_ACTIVE_ENTITY, { entity });
  };

  static createNewNonPersonalEntityBegin = () => {
    return Action.create(USER_TYPES.CREATE_NON_PERSONAL_ENTITY_BEGIN, {});
  };

  static createNewNonPersonalEntitySuccess = () => {
    return Action.create(USER_TYPES.CREATE_NON_PERSONAL_ENTITY_SUCCESS, {});
  };

  static displayDefaultStepBegin = () => {
    return Action.create(USER_TYPES.DISPLAY_DEFAULT_STEP_BEGIN, {});
  };

  static displayDefaultStepSuccess = () => {
    return Action.create(USER_TYPES.DISPLAY_DEFAULT_STEP_SUCCESS, {});
  };

  static updateUserAgreements = (agreements) => {
    return Action.create(USER_TYPES.UPDATE_USER_AGREEMENTS_BEGIN, {
      agreements,
    });
  };
  static updateUserAgreementsSuccess = (agreements) => {
    return Action.create(USER_TYPES.UPDATE_USER_AGREEMENTS_SUCCESS, {
      agreements,
    });
  };
  static updateUserAgreementsFailure = (error) => {
    return Action.create(USER_TYPES.UPDATE_USER_AGREEMENTS_FAILURE, { error });
  };
  static getUserAgreements = () => {
    return Action.create(USER_TYPES.GET_USER_AGREEMENTS_BEGIN, {});
  };
  static getUserAgreementsSuccess = (agreements) => {
    return Action.create(USER_TYPES.GET_USER_AGREEMENTS_SUCCESS, {
      agreements,
    });
  };
  static getUserAgreementsFailure = (error) => {
    return Action.create(USER_TYPES.GET_USER_AGREEMENTS_FAILURE, { error });
  };
}
