export const INVESTMENT_TYPES = {
  GETINVESTMENTBYID_BEGIN: 'GETINVESTMENTBYID_BEGIN',
  GETINVESTMENTBYID_SUCCESS: 'GETINVESTMENTBYID_SUCCESS',
  GETINVESTMENTBYID_FAILURE: 'GETINVESTMENTBYID_FAILURE',

  GET_FEES_BEGIN: 'GET_FEES_BEGIN',
  GET_FEES_FAILURE: 'GET_FEES_FAILURE',
  GET_FEES_SUCCESS: 'GET_FEES_SUCCESS',

  GETORDERSBYPRODUCTID_BEGIN: 'GETORDERSBYPRODUCTID_BEGIN',
  GETORDERSBYPRODUCTID_SUCCESS: 'GETORDERSBYPRODUCTID_SUCCESS',
  GETORDERSBYPRODUCTID_FAILURE: 'GETORDERSBYPRODUCTID_FAILURE',
};
