export default {
  USERS: `${ process.env.REACT_APP_API }/v1/users`,
  USER_LEVELS: `${ process.env.REACT_APP_API }/v1/userlevels`,
  PRODUCTS: `${ process.env.REACT_APP_API }/v1/products`,
  ALTERNATIVE_INVESTMENTS: `${ process.env.REACT_APP_API }/v1/alternative_investments`,
  INDICATIONS: `${ process.env.REACT_APP_API }/v2/indications`,
  ORDERS: `${ process.env.REACT_APP_API }/v2/orders`,
  CURRENCY: `${ process.env.REACT_APP_API }/v2/currency`,
  COMMUNICATION_PREFERENCE: `${ process.env.REACT_APP_API }/v1/communication_preferences`,
  COUNTRIES: `${ process.env.REACT_APP_API }/v1/countries`,
  ENTITIES: `${ process.env.REACT_APP_API }/v1/entities`,
  WALLETS: `${ process.env.REACT_APP_API }/v2/wallets`,
  KYC: `${process.env.REACT_APP_API}/v1/kycservice`,
  WALLETSTRANSACTIONS: `${ process.env.REACT_APP_API }/v2/wallettransactions`,
  DOCUMENTS: `${ process.env.REACT_APP_API }/v1/documents`,
  RENDER_DOCUMENTS: `${ process.env.REACT_APP_API }/v1/render_document`,
  LISTER: `${ process.env.REACT_APP_API }/v1/listers`,
  LEADS: `${ process.env.REACT_APP_API }/v1/marketing_information`,
  DEPOSIT_REFERENCE: `${ process.env.REACT_APP_API }/v1/deposit_reference`,
  DEMAND_PARTNERS: `${ process.env.REACT_APP_API }/v1/demandpartners`,
  PARTNER_DEFAULTS: `${ process.env.REACT_APP_API }/v1/partnerdefaults`,
  CAMPAIGNS: `${ process.env.REACT_APP_API }/v1/campaigns`,
  PARTNER_MAPPINGS: `${ process.env.REACT_APP_API }/v1/partner_mappings`,
  EXTERNAL_TRANSACTIONS: `${ process.env.REACT_APP_API }/v1/external_transactions`,
  RULES: `${ process.env.REACT_APP_API }/v1/rules`,
  EXITED_INVESTMENTS: `${ process.env.REACT_APP_API }/v2/exited`,
};
