export default [
  {
    domain: 'institution.wealthpoint.app',
    key: 'institutional',
  },
  {
    domain: 'platform.privatewealthglobal.com',
    key: 'privatewealth',
  },
  {
    domain: 'invest.worldwidecapitalpartners.com',
    key: 'worldwidecapitalpartners',
  },
  {
    domain: 'invest.rewardprop.com',
    key: 'rewardproperties',
  },
  {
    domain: 'global.sapropertynetwork.com',
    key: 'sapin',
  },
  {
    domain: 'rei.wealthpoint.app',
    key: 'rei',
  },
  {
    domain: 'stockcap.wealthpoint.app',
    key: 'stockcapital',
  },
  {
    domain: 'invest.yachtieswealth.com',
    key: 'yachties',
  },
  {
    domain: 'network.neumainvestments.com',
    key: 'neuma',
  },
  {
    domain: 'invest.wealthpoint.app',
    key: 'invest',
  },
  {
    domain: 'legacybuildersglobal.wealthpoint.app',
    key: 'legacybuildersglobal',
  },
  {
    domain: 'invest.igrowcapital.co.za',
    key: 'igrowcapital',
  },
  {
    domain: 'wealthindia.wealthpoint.app',
    key: 'wealthindia',
  },
  {
    domain: 'apm.wealthpoint.app',
    key: 'apm',
  },
  {
    domain: 'shariahwealth.wealthpoint.app',
    key: 'shariahwealth',
  },
  {
    domain: 'bt.wealthpoint.app',
    key: 'bt',
  },
  {
    domain: 'invictusamg.wealthpoint.app',
    key: 'invictusamg',
  },
  {
    domain: 'associatedbroker.wealthpoint.app',
    key: 'associatedbroker',
  },
  {
    domain: 'russellwallett.wealthpoint.app',
    key: 'russellwallett',
  },
  {
    domain: 'investlife.wealthpoint.app',
    key: 'investlife',
  },
  {
    domain: 'equitymultiple.wealthpoint.app',
    key: 'equitymultiple',
  },
  {
    domain: 'platform.womenandwealthinvest.com',
    key: 'women',
  },
  {
    domain: 'platform.millennialsandwealth.com',
    key: 'millennials',
  },
  {
    domain: 'platform.farmersandwealth.com',
    key: 'farmers',
  },
  {
    domain: 'platform.businessownerswealth.com',
    key: 'businessowners',
  },
];