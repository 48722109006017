import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
  Information,
  Error,
  Warning,
  Success,
} from '../../components/Notifications';

import './stylesheet.css';


import AlertActions from '../../../redux/actions/alert.actions';

/**
 * A redux connect wrapped alert component.
 */
class ConnectedAlert extends Component {
  componentDidMount() {
    const { clearAlert } = this.props;

    // listen for path changes so that we can clear our alerts
    this.historyUnlisten = this.props.history.listen(() => {
      clearAlert();
    });
  }

  componentDidUpdate(prevProps) {
    // clear alert after a certain time.
    if (!!this.props.message && this.props.message !== prevProps.message) {
      this.createTimer();
    }
  }

  componentWillUnmount() {
    // the object returned from history.listen will clear the listener.
    this.historyUnlisten();
  }

  /**
   * Creates a timer for clearing alerts after a specified interval.
   */
  createTimer() {
    const { clearAlert } = this.props;

    if (this.timeout) {
      // clear previous timeout
      clearTimeout(this.timeout);
    }
    this.timeout = setTimeout(() => clearAlert(), 5000);
  }

  render() {
    const { message, alertCategory } = this.props;
    return message && (
      <div className="c-systemAlerts" data-validationgroup='global-alerts'>
        {
          alertCategory === 'error' && (
            <Error className="p-notification--shadowed">{ message }</Error>
          )
        }
        {
          alertCategory === 'info' && (
            <Information className="p-notification--shadowed">{ message }</Information>
          )
        }
        {
          alertCategory === 'success' && (
            <Success className="p-notification--shadowed">{ message }</Success>
          )
        }
        {
          alertCategory === 'warning' && (
            <Warning className="p-notification--shadowed">{ message }</Warning>
          )
        }
      </div>
    );
  }
}

ConnectedAlert.propTypes = {
  clearAlert: PropTypes.func,
  message: PropTypes.string,
  history: PropTypes.object, // There must be a redux way to access this but I would need to investigate that.
  alertCategory: PropTypes.oneOf(['success', 'error']),
};

const mapDispatchToProps = (dispatch) => {
  return ({
    clearAlert: () => { dispatch(AlertActions.clear()); },
  });
};

const mapStateToProps = (state) => ({
  message: state.alert.message,
  alertCategory: state.alert.category,
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ConnectedAlert));
