import { ALERT_TYPES } from '../types';
import Action from './helpers/action';

export default class AlertActions {
  /**
   * Handles positive action alert messages
   * @example
   * success('Well done.')
   */
  static success = (message) => {
    return Action.create(ALERT_TYPES.SUCCESS, { message });
  }

  /**
   * Handles info alert messages
   * @example
   * info('Session expired')
   */
  static info = (message) => {
    return Action.create(ALERT_TYPES.INFO, { message });
  }

  /**
   * Handles negatives action alert messages
   * @example
   * success('You burnt down the farm. Not cool.')
   */
  static error = (message) => {
    return Action.create(ALERT_TYPES.ERROR, { message });
  }

  /**
   * Clears alerts
   * @example
   * clear()
   */
  static clear = () => {
    return Action.createError(ALERT_TYPES.CLEAR);
  }
}

