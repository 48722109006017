import { INVESTMENTS_TYPES } from '../types/investments.types';
import _ from 'lodash';

/**
 * Redux Reducer for investments
 */

const initialState = {
  loading: false,
  items: [],
  minimums: [],
  fees: [],
};

const actionsMap = {
  [INVESTMENTS_TYPES.GET_INVESTMENTS_BEGIN]: (state) =>
    ({
      ...state,
      loading: true,
      error: undefined,
    }),
  [INVESTMENTS_TYPES.GET_INVESTMENTS_FAILURE]: (state, action) =>
    ({
      ...state,
      loading: false,
      error: action.payload.error,
    }),
  [INVESTMENTS_TYPES.GET_INVESTMENTS_SUCCESS]: (state, action) =>
    ({
      ...state,
      items: action.payload.investments,
      loading: false,
      error: undefined,
    }),
  [INVESTMENTS_TYPES.GET_PRODUCT_MINIMUMS_BEGIN]: (state) =>
    ({
      ...state,
      loading: true,
      error: undefined,
    }),
  [INVESTMENTS_TYPES.GET_PRODUCT_MINIMUMS_FAILURE]: (state, action) =>
    ({
      ...state,
      minimums: [],
      loading: false,
      error: action?.payload?.error,
    }),
  [INVESTMENTS_TYPES.GET_PRODUCT_MINIMUMS_SUCCESS]: (state, action) =>
  {
      const newProds =  state?.minimums?.data?.products ? _.uniq([...action?.payload?.minimums?.data?.products, ...state?.minimums?.data?.products]) : action?.payload?.minimums?.data?.products

      return {
      ...state,
      minimums: {...action?.payload?.minimums, data: {...action?.payload?.minimums?.data, products: newProds}},
      loading: false,
      error: undefined,
    }},
  [INVESTMENTS_TYPES.GET_PRODUCT_FEES_BEGIN]: (state) =>
    ({
      ...state,
      loading: true,
      error: undefined,
    }),
  [INVESTMENTS_TYPES.GET_PRODUCT_FEES_FAILURE]: (state, action) =>
    ({
      ...state,
      fees: [],
      loading: false,
      error: action?.payload?.error,
    }),
  [INVESTMENTS_TYPES.GET_PRODUCT_FEES_SUCCESS]: (state, action) =>
    ({
      ...state,
      fees: action?.payload?.fees,
      loading: false,
      error: undefined,
    }),
};

export default function reducer(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
