import React from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';

import AnonymousLayout from '../../assets/layouts/anonymous';

/**
 * Wrapper around react router routes which require unauthenticated users.
 * @example
 * <UnauthenticatedRoute
 *  path={ '/some-path' }
 *  RenderComponent={ (props) => <AnyComponent { ...props } /> }
 *  FallbackComponent={ (props) => <SomeOtherComponentIfAuthenticated /> }
 *  isLoggedIn={ loggedInState }
 */
const UnauthenticatedRoute = ({
  RenderComponent,
  FallbackComponent,
  isLoggedIn,
  ignoreLoggedIn,
  name = undefined,
  ...rest
}) => {

  const renderComps = (props) => {
    if (ignoreLoggedIn || isLoggedIn === false) {
      return RenderComponent(props)
    }
    return FallbackComponent(props)
  };

  return (
    <AnonymousLayout
    name={ name }
    >
      <Route
        { ...rest }
        render={ (props) => renderComps(props) }
      />
    </AnonymousLayout>
  );
};

UnauthenticatedRoute.propTypes = {
  RenderComponent: PropTypes.func,
  FallbackComponent: PropTypes.func,
  isLoggedIn: PropTypes.bool,
  ignoreLoggedIn: PropTypes.bool,
  name: PropTypes.string,
};

export default UnauthenticatedRoute;
