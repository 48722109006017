import { takeLatest, put, call, all } from 'redux-saga/effects';

import { INVESTMENTS_TYPES } from '../../types';
import InvestmentsActions from '../../actions/investments.actions';
import productService from '../../../api/product.service';
import altInvestmentService from '../../../api/alt-investment.service'
import demandPartnerService from '../../../api/demand-partner.service';
import listerService from '../../../api/lister.service';
import SessionStore from '../../../utils/session-store';
import RulesService from '../../../api/rules.service';
import { SessionContext } from '../../../utils';
import { applyCoC, applyIrr, getCalcData } from '../../../utils/ProductReturns';

const orderProducts = (products) => products.sort((a, b) => b.listing_date - a.listing_date)?.filter(p =>
  p?.status?.toLowerCase() === 'open' && p.featured === true).concat(products?.filter(p =>
  p?.status?.toLowerCase() === 'open' && p.featured !== true).concat(products?.filter(p => //filtering !== true because it can possibly be undefined
  p?.status?.toLowerCase() === 'under review')).concat(products?.filter(p =>
  p?.status?.toLowerCase() !== 'open' && p?.status?.toLowerCase() !== 'under review')));

  function* getAllProducts(token, entityId, userId) {
  const subsystem = SessionStore.getSubsystem();
  const body = yield call(() => productService.getRealEstateProducts(token));
  const realEstate = body.data.map(re => ({...re, content_type: 'realestate'})); //adding realestate as content type

  const altbody = yield call(() => altInvestmentService.gePublishedtAltInvestments(token));
  const alternative = altbody.data.map(re => ({...re, content_type: 'alternativeinvestments'})); //adding alternative as content type

  const nonListerExclusive = orderProducts(realEstate.concat(alternative)?.filter(i => !i.lister_exclusive));

  const { minimums, fees } =  yield(all({
    minimums: call(() => RulesService.getMinimumInvestmentData(token, entityId, subsystem, nonListerExclusive.map(p => p._id))),
    fees: call(() => RulesService.getFeeData(token, userId, subsystem, nonListerExclusive.map(p => p._id)))
  }))
  yield put(InvestmentsActions.getFeesSuccess(fees));
  yield put(InvestmentsActions.getMinimumsSuccess(minimums));
  yield put(InvestmentsActions.success(orderProducts(nonListerExclusive.map(p => {
    const {
      feeData,
      cashFlowData
    } = getCalcData(p?._id, fees)
   return applyCoC(applyIrr(p, cashFlowData, feeData), cashFlowData, feeData)
  }
))));
}

function* getProductsForSubsystem(token, subsystem, entityId, userId) {
  const lister = yield call(() => listerService.getListerBySubsystem(token, subsystem));
  const listerId = lister.data[0] ? lister.data[0]._id : undefined; //eslint-disable-line

  const body = yield call(() => productService.getRealEstateProducts(token));
  const realEstate = body.data.map(re => ({...re, content_type: 'realestate'})); //adding realestate as content type

  const altbody = yield call(() => altInvestmentService.gePublishedtAltInvestments(token));
  const alternative = altbody.data.map(re => ({...re, content_type: 'alternativeinvestments'})); //adding alternative as content type

  const allProducts = realEstate.concat(alternative);
  const nonExclusiveProducts = allProducts?.filter(p => !p.lister_exclusive); // filtering out non exclusive deals
  if (listerId) {
    const dpBody = yield call(() => demandPartnerService.getAll(token));
    const demandPartners = dpBody.data;
    
    const listerDp = demandPartners?.filter(d => d.channel_partners && !!d.channel_partners.find(c => c.lister_id && c.lister_id === listerId));

    const listerCp = listerDp.length > 0 ? listerDp[0].channel_partners && listerDp[0].channel_partners.find(c =>
      c.lister_id === listerId) : undefined;

    const listerProductFilter = (listerCp && listerCp.product_filters.find(f => f.subsystem.toLowerCase() === subsystem.toLowerCase()).products?.length > 0) ? nonExclusiveProducts : []
    const ListerExclusiveProducts = listerProductFilter.concat(allProducts?.filter(p => // adding exclusive deals specific to this lister
      p.lister_exclusive && p.lister_id === listerId))

    if (listerCp && listerCp.product_filters.length > 0 && listerCp.product_filters.find(f => f.subsystem.toLowerCase() === subsystem.toLowerCase()) &&
        listerCp.product_filters.find(f => f.subsystem.toLowerCase() === subsystem.toLowerCase()).products.length > 0) { // only filtering if product filters are defined

      const filteredLEProducts = ListerExclusiveProducts?.filter(p => listerCp.product_filters.find(f => // filtering out all products not listed
        f.subsystem.toLowerCase() === subsystem.toLowerCase())?.products?.includes(p._id)); // eslint-disable-line

      const { minimums, fees } =  yield(all({
        minimums: call(() => RulesService.getMinimumInvestmentData(token, entityId, subsystem, filteredLEProducts.map(p => p._id))),
        fees: call(() => RulesService.getFeeData(token, userId, subsystem, filteredLEProducts.map(p => p._id)))
      }));
      yield put(InvestmentsActions.getFeesSuccess(fees));
      yield put(InvestmentsActions.getMinimumsSuccess(minimums));
      yield put(InvestmentsActions.success(orderProducts(filteredLEProducts.map(p => {
        const {
          feeData,
          cashFlowData
        } = getCalcData(p?._id, fees)
       return applyCoC(applyIrr(p, cashFlowData, feeData), cashFlowData, feeData)
      }
    ))));
    } else {
      const { minimums, fees } =  yield(all({
        minimums: call(() => RulesService.getMinimumInvestmentData(token, entityId, subsystem, ListerExclusiveProducts.map(p => p._id))),
        fees: call(() => RulesService.getFeeData(token, userId, subsystem, ListerExclusiveProducts.map(p => p._id)))
      }))
      yield put(InvestmentsActions.getFeesSuccess(fees));
      yield put(InvestmentsActions.getMinimumsSuccess(minimums));
      yield put(InvestmentsActions.success(orderProducts(ListerExclusiveProducts.map(p => {
        const {
          feeData,
          cashFlowData
        } = getCalcData(p?._id, fees)
        return applyCoC(applyIrr(p, cashFlowData, feeData), cashFlowData, feeData)
      }
    ))));
    }
  } else {
    const unfilteredAction = InvestmentsActions.success(orderProducts(nonExclusiveProducts));
    yield put(unfilteredAction);
  }
}


/**
 * Redux Saga generator function for getting products
 */
function createGetProducts() {
  return function* (options) {
    try {
      const entityId = options?.payload?.entityId;
      const token = SessionStore.getSession();
      const userId = SessionContext.get(token)?.sub;

      const subsystem = SessionStore.getSubsystem();

      // if the subsystem is the default subsystem (global) get all products
      // otherwise filter the products by the subsystem. Which equals the lister id.
      if (subsystem === process.env.REACT_APP_API_SUBSYSTEM) {
        yield getAllProducts(token, entityId, userId);
      } else {
        yield getProductsForSubsystem(token, subsystem, entityId, userId);
      }
    } catch (error) {
      const failureAction = InvestmentsActions.error(error);

      yield put(failureAction);
    }
  };
}

export const getProducts = createGetProducts();

export function* getProductsWatcher() {
  yield takeLatest(INVESTMENTS_TYPES.GET_INVESTMENTS_BEGIN, getProducts);
}
