import { takeLatest, put, call } from 'redux-saga/effects';

import { KYC_TYPES } from '../../types';
import KYCActions from '../../actions/kyc.actions';
import KYCService from '../../../api/kyc.service';
import SessionStore from '../../../utils/session-store';

/**
 * Redux Saga generator function for getting banking fields
 */
function createGetBankingFields() {
  return function* (options) {
    try {
      const token = SessionStore.getSession();
      const iso_2_code = options?.payload?.iso_2_code;

      const result = yield call(() => KYCService.getCountryBankingFields(token, iso_2_code));

      const action = KYCActions.success(result?.data);

      yield put(action);
    } catch (error) {
      const failureAction = KYCActions.error(error);
      yield put(failureAction);
    }
  };
}

export const getBankingFields = createGetBankingFields();

export function* getBankFieldsWatcher() {
  yield takeLatest(KYC_TYPES.GETBANKINGFIELDS_BY_ISO2_BEGIN, getBankingFields);
}
