import { newDepositReferenceWatcher } from './generate-deposit-reference.saga';
import { getDepositReferencesWatcher } from './get-deposit-references.saga';
import { updateDepositReferenceWatcher } from './update-deposit-reference.saga';
import { deleteDepositReferencesWatcher } from './delete-deposit-reference.saga';

export default [
  newDepositReferenceWatcher(),
  getDepositReferencesWatcher(),
  updateDepositReferenceWatcher(),
  deleteDepositReferencesWatcher(),
];
