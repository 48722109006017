import React from 'react';
import ReactDOM from 'react-dom';
import 'isomorphic-fetch';
import { ConnectedRouter } from 'connected-react-router';
import { AppContainer } from 'react-hot-loader';
import { Provider } from 'react-redux';
import qs from 'query-string';

import '@traaidmark/konstruct-cement';
import '@traaidmark/konstruct-cement/lib/utility-arrows.css';
import '@traaidmark/konstruct-cement/lib/utility-list.css';
import '@traaidmark/konstruct-cement/lib/utility-gutters.css';
import '@traaidmark/konstruct-part-flag/lib/stylesheet.css';
import '@traaidmark/konstruct-part-button/lib/stylesheet.css';
import '@traaidmark/konstruct-part-icon-button/lib/stylesheet.css';
import '@traaidmark/konstruct-part-table/lib/stylesheet.css';


import './assets/css/variables.css';
import './assets/css/fonts.css';
import './assets/css/main.css';
import './assets/css/stylesheet.css';
import './assets/css/features.css';
import './assets/css/utilities.css';

import * as serviceWorker from './serviceWorker';
import configureStore from './redux/store/config';
import Logger from './logrocket/logger';
import SessionStore from './utils/session-store';
import SessionActions from './redux/actions/session.actions';
import { PersistGate } from 'redux-persist/integration/react'



//import RouterOutlet from './router/outlet';
import Router from './router/outlet';

Logger.initialize();

const storeConfiguration = configureStore();
const store = storeConfiguration.store;
const history = storeConfiguration.history;
const persistor = storeConfiguration.persistor;

const hydrateState = () => {
  // when hydrating and we have a session, log the user in.
  if (SessionStore.hasSession()) {
    const urlSubsystem = qs.parse(window.location.search, { ignoreQueryPrefix: true }).subsystem || undefined;
    if ((urlSubsystem && urlSubsystem === SessionStore.getSubsystem()) || !urlSubsystem) {
      store.dispatch(SessionActions.hydrateState());
    } else {
      store.dispatch(SessionActions.signout(false));
    }
  } else {
    persistor.purge();
  }
};

hydrateState();

const app = (
  <AppContainer>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ConnectedRouter history={history}>
          <Router />
        </ConnectedRouter>
      </PersistGate>
    </Provider>
  </AppContainer>
);

ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
if (process.env.NODE_ENV !== 'development') {
  serviceWorker.unregister();
}

export default persistor;
