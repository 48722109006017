import { Map } from 'immutable';
import { PLEDGE_TYPES } from '../types';

const initialState = {
  loading: false,
  deposit_references: [],
};

const actionsMap = {
  [PLEDGE_TYPES.GET_DEPOSIT_REFERENCES_BEGIN]: (state) =>
    ({
      ...state,
      loading: true,
      errors: undefined,
    }),
  [PLEDGE_TYPES.GET_DEPOSIT_REFERENCES_SUCCESS]: (state, action) =>
    ({
      ...state,
      loading: false,
      deposit_references: action.payload.depositReferences,
      errors: undefined,
    }),
  [PLEDGE_TYPES.GET_DEPOSIT_REFERENCES_FAILURE]: (state, action) =>
    ({
      ...state,
      loading: false,
      errors: action.payload.error,
    }),
  // Generate Deposit References
  [PLEDGE_TYPES.GENERATE_DEPOSIT_REFERENCE_BEGIN]: (state) =>
    ({
      ...state,
      loading: true,
      errors: undefined,
    }),
  [PLEDGE_TYPES.GENERATE_DEPOSIT_REFERENCE_SUCCESS]: (state, action) => {
    const deposit_references = state.deposit_references; // eslint-disable-line
    const new_deposit_references = deposit_references && deposit_references.length > 0 ? // eslint-disable-line
      [...deposit_references, action.payload.depositReference] : [action.payload.depositReference]; // eslint-disable-line

    return {
      ...state,
      loading: false,
      deposit_references: new_deposit_references,
      errors: undefined,
    };
  },
  [PLEDGE_TYPES.GENERATE_DEPOSIT_REFERENCE_FAILURE]: (state, action) =>
    ({
      ...state,
      loading: false,
      errors: action.payload.error,
    }),
  // delete reducers
  [PLEDGE_TYPES.DELETE_DEPOSIT_REFERENCE_BEGIN]: (state) =>
    ({
      ...state,
      loading: true,
      errors: undefined,
    }),
  [PLEDGE_TYPES.DELETE_DEPOSIT_REFERENCE_FAILURE]: (state, action) =>
    ({
      ...state,
      loading: false,
      errors: action.payload.error,
    }),
};

export default function reducer(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
