import React from 'react';
import propTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

const Link = ({
  children,
  to = '#',
  className = '',
  ...rest
}) => {

  if (to.includes('https') || to.includes('http')) {
    return (
      <a
        href={ to }
        target="_blank"
        rel="noopener noreferer"
        className={ className }
        { ...rest }
      >
        { children }
      </a>
    );
  };

  return (
    <NavLink
      to={ to }
      className={ className }
      { ...rest }
    >
      { children }
    </NavLink>
  );
};

Link.propTypes = {
  children: propTypes.node,
  to: propTypes.string,
  className: propTypes.string,
};

export default Link;
