import PATH from '../paths';

const name = 'Universal Financial Corporation';
const key = 'ufc';
const imgUrl = `${ PATH.CDN }/whitelabels/${ key }`;
const images = {
  favicon: `${ imgUrl }/favicon.png`,
  logo: `${ imgUrl }/ufc-logo.png`,
  logoSidebar: `${ imgUrl }/logo.png`,
  logoPDF: `${ imgUrl }/logo-pdf.png`,
  opengraph: false,
  backgrounds: [
    `${ imgUrl }/background.jpg`
  ],
};

export default {
  name,
  key,
  images,
  stylesheet: `
  
    /* ANONYMOUS LAYOUT */

    --anonymous-bg:                   url(${ images.backgrounds[0] });
    --anonymous-bg-size:              cover;
    --anonymous-logo-bg:              var(--color-white);
    --anonymous-logo-height:          70px;

    /* END */
    

    /* SIDEBAR */

    --sidebar-logo-bg-color: var(--color-white);

    /* END */
  
  `
  
}; 