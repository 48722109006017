import { combineReducers } from 'redux';
import alert from './alert.reducer';
import countries from './countries.reducer';
import session from './session.reducer';
import investment from './investment.reducer';
import investments from './investments.reducer';
import wallets from './wallets.reducer';
import user from './user.reducer';
import portfolio from './portfolio.reducer';
import documents from './document.reducer';
import ui from './ui.reducer';
import pledges from './pledge.reducer';
import demand_partners from './demand-partners.reducer'; //eslint-disable-line
import currencies from './currency.reducer'; //eslint-disable-line
import lister from './lister.reducer'; //eslint-disable-line
import kyc from './kyc.reducer'

export default combineReducers({
  alert,
  countries,
  demand_partners,
  session,
  kyc,
  investment,
  investments,
  pledges,
  wallets,
  user,
  ui,
  portfolio,
  documents,
  currencies,
  lister,
});
