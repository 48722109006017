
import { getEntityPortfolioWatcher } from './get-entity-portfolio.saga';
import { getCreateIOIWatcher } from './create-ioi.saga';
import { getCancelIOIWatcher } from './cancel-ioi.saga';

export default [
  getEntityPortfolioWatcher(),
  getCreateIOIWatcher(),
  getCancelIOIWatcher(),
];
