import { takeLatest, put, call, all } from 'redux-saga/effects';

import { INVESTMENT_TYPES } from '../../types';
import InvestmentActions from '../../actions/investment.actions';
import productService from '../../../api/product.service';
import altinvestmentService from '../../../api/alt-investment.service';
import SessionStore from '../../../utils/session-store';
import RulesService from '../../../api/rules.service';
import SessionContext from '../../../utils/session-context';
import { applyCoC, applyIrr, getCalcData } from '../../../utils/ProductReturns';

export function* getProductById(action) {
  try {
    const token = SessionStore.getSession();
    const subsystem = SessionStore.getSubsystem();
    const userId = SessionContext.get(token)?.sub;
    const {product, alt_investment, fees} = yield all({
      product: call(() => productService.getProductById(token, action.payload.id)),
      alt_investment: call(() => altinvestmentService.getAltInvestmentById(token, action.payload.id)),
      fees: call(() => RulesService.getFeeData(token, userId, subsystem, [action.payload.id])),
    })
    const inv = product.data?.[0] || alt_investment?.data?.[0]
    if(inv) {
      yield put(InvestmentActions.getByIdSuccess([inv].map(i => {
        const {
          feeData,
          cashFlowData
        } = getCalcData(i?._id, fees)
        return applyCoC(applyIrr(i, cashFlowData, feeData), cashFlowData, feeData)
      })?.[0]));
      yield put(InvestmentActions.getFeesSuccess(fees));
    } else {
      yield put(InvestmentActions.getByIdError({'msg': 'no data'}));
    }
  } catch (error) {
    yield put(InvestmentActions.getByIdError(error));
  }
}

export function* getProductByIdWatcher() {
  yield takeLatest(INVESTMENT_TYPES.GETINVESTMENTBYID_BEGIN, getProductById);
}
