import { UI_TYPES } from '../types';
import Action from './helpers/action';

export default class UIActions {
  /**
   * Toggles the display of the dashbar and dashboard
   */
  static setDashbar = (data) => {
    return Action.create(UI_TYPES.SET_DASHBAR, { data });
  }
  /**
   * Toggles the display of the drawer
   */
  static toggleDrawer = (data) => {
    return Action.create(UI_TYPES.TOGGLE_DRAWER, { data });
  }
  /**
   * Toggles the display of the task
   */
  static toggleTask = (data) => {
    return Action.create(UI_TYPES.TOGGLE_TASK, data);
  }
  /**
   * Sets the active theme
   */
  static setUi = (data) => {
    return Action.create(UI_TYPES.SET_UI, data);
  }
  /**
   * An action for setting Marketplace filters
   */
  static setFilters = (data) => {
    return Action.create(UI_TYPES.SET_FILTERS, data);
  }
}
