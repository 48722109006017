import { takeLatest } from 'redux-saga/effects';


import { SESSION_TYPES } from '../../types';
import SessionStore from '../../../utils/session-store';
import SessionContext from '../../../utils/session-context';

/**
 * Redux Saga generator function for sign in
 */
function createMonitorAuthSuccess() {
  return function* () {
    try {
      const token = SessionStore.getSession();
      const userId = SessionContext.get(token).sub;

    } catch (error) {
      // Do nothing. continue.
    }
  };
}

export const authSuccess = createMonitorAuthSuccess();

export function* getSignInCompleteWatcher() {
  yield takeLatest(SESSION_TYPES.SIGNIN_SUCCESS, authSuccess);
}

export function* getSignUpCompleteWatcher() {
  yield takeLatest(SESSION_TYPES.SIGNUP_SUCCESS, authSuccess);
}
