import { LISTER_TYPES } from '../types';

const initialState = {
  status: 'idle',
  data: undefined,
  errors: undefined
};

const actionsMap = {
  [LISTER_TYPES.FETCH_LISTER_BEGIN]: (state) =>
    ({
      ...state,
      status: 'fetching',
    }),
  [LISTER_TYPES.FETCH_LISTER_SUCCESS]: (state, action) =>
    ({
      ...state,
      status: 'idle',
      data: action.payload.payload,
      errors: undefined,
    }),
  [LISTER_TYPES.FETCH_LISTER_ERROR]: (state, action) =>
    ({
      ...state,
      status: 'idle',
      data: undefined,
      errors: action.payload,
    }),
};

export default function reducer(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
