export default class Returns {
  /**
   * Calculates Annual returns based on distributions received. If less than 1 year elapsed after order creation - distributions will be annualized
   * @example
   * const annualReturns = calcAnnualReturns({}, []);
   * @param {Object} order
   * @param {Array} distributions
   * @returns {Number} Returns total returns over a period of a year or an annualized amount.
   */
  static calcAnnualReturns(order, distributions) {
    const filterByTimeRange = (transaction, start, end) => {
      return (transaction.timestamp && transaction.timestamp >= start && transaction.timestamp <= end) || false;
    };
    const yearAgo = (time) => time - (3600000 * 24 * 365.25);
    const latestDistribution = (distributions.length > 0 && distributions.sort((a, b) => b.timestamp - a.timestamp)[0]) || undefined;
    const recentDividends = distributions.length > 0 && distributions?.filter(d => filterByTimeRange(d, yearAgo(latestDistribution.timestamp),
      latestDistribution && latestDistribution.timestamp));
    const totalAnnualDistributions = distributions.length > 0 && recentDividends.length > 0 ? recentDividends.map(d =>
      d.destination.amount).reduce((a, b) => a + b) : 0;
    return Math.round(totalAnnualDistributions * 100) / 100;
  }
}
