import 'isomorphic-fetch';
import { fetchResource } from './fetch-resource';

import { API } from '../constants';

/**
 * Rules API service
 */
class RulesService {
  /**
   * Retrieves minimum investment amount for product ids supplied
   * @example
   * @returns {Promise} Returns a promise containing the result in json format.
   */
  static getMinimumInvestmentData = (jwt, entityId = '', subsystem = '', productIds = []) => {
    const body = {
      entity_id: entityId,
      subsystem,
      product_ids: productIds,
    }
    const requestConfig = {
      credentials: 'include',
      cache: 'no-cache',
      mode: 'cors',
      headers: {
        'content-type': 'application/json',
        'authorization': `bearer ${ jwt }`,
      },
      method: 'POST',
      body: JSON.stringify(body)
    };

    return fetchResource(`${ API.RULES }/minimum`, requestConfig);
  }

  static getCanInvestData = (jwt, entityId = '') => {
    const body = {
      entity_id: entityId,
    }
    const requestConfig = {
      credentials: 'include',
      cache: 'no-cache',
      mode: 'cors',
      headers: {
        'content-type': 'application/json',
        'authorization': `bearer ${ jwt }`,
      },
      method: 'POST',
      body: JSON.stringify(body)
    };

    return fetchResource(`${ API.RULES }/caninvest`, requestConfig);
  }

  static getFeeData = (jwt, user_id = '', subsystem = '', product_ids = []) => {
    const body = {
      user_id,
      product_ids,
      subsystem,
    }
    const requestConfig = {
      credentials: 'include',
      cache: 'no-cache',
      mode: 'cors',
      headers: {
        'content-type': 'application/json',
        'authorization': `bearer ${ jwt }`,
      },
      method: 'POST',
      body: JSON.stringify(body)
    };

    return fetchResource(`${ API.RULES }/fees`, requestConfig);
  }
}

export default RulesService;
