import UiActions from '../actions/ui.actions';

import {
  WHITELABEL,
  WHITELABEL_DOMAIN,
  COPY
} from '../../constants';

export default {

  createUi: ({ domainName, storedKey, queryKey }) => async (dispatch) => {

    const generateKey = (d, s, q) => d || q || s || 'global'

    const findDomain = WHITELABEL_DOMAIN.filter(w => w.domain === domainName);
    const domainKey = findDomain.length === 1 ? findDomain[0].key : undefined;

    const payload = generateKey(domainKey, storedKey, queryKey);

    if (WHITELABEL[payload]) {

      return dispatch(UiActions.setUi({
        copy: COPY,
        quote: WHITELABEL[payload].quote,
        key: WHITELABEL[payload].key,
        name:  WHITELABEL[payload].name,
        images: WHITELABEL[payload].images,
        domainKey,
        domainName
      }));

    };

    return dispatch(UiActions.setUi({
      copy: COPY,
      key: 'global',
      domainName
    }));
    
  },

};
