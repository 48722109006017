import { takeLatest, put, call, select, all } from 'redux-saga/effects';

import { WALLETS_TYPES } from '../../types';
import WalletActions from '../../actions/wallets.actions';
import walletService from '../../../api/wallets.service';
import SessionStore from '../../../utils/session-store';
import ListerService from '../../../api/lister.service';

const defaultWallets = [
  {
    currency_iso: 'USD',
    balance: 0,
    available: 0,
  },
];

function* initWalletsForCurrencies(entityId = '', currencies = []){
  try {
    const token = SessionStore.getSession();
      if(currencies.length > 0){
        for(let curr of currencies ){
          yield call(() => walletService.initWalletForEntityID(token, entityId, curr?.toLowerCase() === 'usd' ? 'lemonway' : '', curr))
        }
      }
    } catch (error) {
      const failureAction = WalletActions.error(error);
      yield put(failureAction);
  }

}

function* getAndSaveListerPrefs(subsystem){
  try {
    const token = SessionStore.getSession();
    const body = yield call(() => ListerService.getListerBySubsystem(token, subsystem))
    SessionStore.setListerPreferences(body?.data?.[0])
  } catch(error){
    const failureAction = WalletActions.error(error);
    yield put(failureAction);
  }
}

function* checkAllowedWallets(){
  const listerPrefs = SessionStore.getListerPreferences();
  const subsystem = SessionStore.getSubsystem();

  if(!listerPrefs || listerPrefs?.subsystem?.toLowerCase() !== subsystem.toLowerCase()){
    yield getAndSaveListerPrefs(subsystem)
  }
}

/**
 * Redux Saga generator function for getting wallets
 */
function createGetWallets() {
  return function* (options) {
    try {
      const token = SessionStore.getSession();
      const entityId = options.payload.entity_id;

      yield checkAllowedWallets();

      let body = yield call(() => walletService.getWalletsByEntityID(token, entityId));
      let wallets = body.data.length > 0 ? body.data[0] : { total_holdings: 0, wallets: [] };

      const currencies = wallets?.wallets.map(w => w?.currency_iso)
      const listerPrefs = SessionStore.getListerPreferences();

      //initialize allowed wallets not yet inittialized
      if(!listerPrefs?.currencies?.every(c => currencies?.includes(c))){
        const curs = listerPrefs?.currencies.filter(c => !currencies.includes(c));
        yield initWalletsForCurrencies(entityId, curs)
      }

      //Getting fresh wallets
      body = yield call(() => walletService.getWalletsByEntityID(token, entityId)); //TODO: optimize
      wallets = body.data.length > 0 ? body.data[0] : { total_holdings: 0, wallets: [] };


      let mergedWallets = [...defaultWallets];

      mergedWallets = [...wallets.wallets, ...defaultWallets];
      mergedWallets = [...(new Set(mergedWallets))]; //TODO: revise and see if this is still required

      mergedWallets = mergedWallets?.filter((item, index, self) => {
        return index === self.findIndex(t => (
          t.currency_iso === item.currency_iso
        ));
      });


      wallets.wallets = mergedWallets;

      yield put(WalletActions.getAllTransactions(entityId))
      yield put(WalletActions.success(wallets));
    } catch (error) {
      const NOT_FOUND_CODE = 404;

      if (error.code && error.code === NOT_FOUND_CODE) {
        const entityId = options.payload.entity_id

        //Checking and only initing wallets thats allowed
        yield checkAllowedWallets();
        const listerPrefs = SessionStore.getListerPreferences();
        yield initWalletsForCurrencies(entityId, listerPrefs?.currencies.length > 0 ? listerPrefs?.currencies : ['USD'])

        yield put(WalletActions.getAll(entityId));
      } else {
        const failureAction = WalletActions.error(error);
        yield put(failureAction);
      }
    }
  };
}

export const getWallets = createGetWallets();

export function* getWalletsWatcher() {
  yield takeLatest(WALLETS_TYPES.GETWALLETS_BEGIN, getWallets);
}
