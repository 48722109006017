import { takeLatest, put, call } from 'redux-saga/effects';
import { createPatch } from 'rfc6902';

import DepositReferenceService from '../../../api/deposit-reference.service';
import { PLEDGE_TYPES } from '../../types/pledge.types';
import PledgeActions from '../../actions/pledge.actions';

import SessionStore from '../../../utils/session-store';

function updateDepositReference() {
  return function* (options) {
    try {
      const token = SessionStore.getSession();
      const {
        referenceId,
        amount,
        baseFee,
        structureFee,
        capFee,
        blocktradeFee,
      } = options.payload;

      const depositReference = yield call(() => DepositReferenceService.getDepositReferenceById(token, referenceId));
      const originalDepRef = depositReference.data[0];

      const patches = createPatch({
        amount: 0,
        base_fee: 0,
        structure_fee: 0,
        marketing_fee: 0, 
        blocktrade_fee: 0,
      },
      {
        amount,
        base_fee: baseFee || 0,
        structure_fee: structureFee || 0,
        marketing_fee: capFee || 0,
        blocktrade_fee: blocktradeFee || 0,
      });

      if (patches.length > 0) {
        const body = yield call(() => DepositReferenceService.updateDepositReference(token, referenceId, patches));

        const result = body.data[0];

        const successAction = PledgeActions.updateDepositReferenceSuccess(result);

        yield put(successAction);
      } else {
        const nonUpdateAction = PledgeActions.updateDepositReferenceSuccess(originalDepRef);

        yield put(nonUpdateAction);
      }
    } catch (error) {
      const failureAction = PledgeActions.updateDepositReferenceFailure(error);
      yield put(failureAction);
    }
  };
}

const updatedDepositReference = updateDepositReference();

export function* updateDepositReferenceWatcher() {
  yield takeLatest(PLEDGE_TYPES.UPDATE_DEPOSIT_REFERENCE_BEGIN, updatedDepositReference);
}
