import { getSignInWatcher } from './sign-in.saga';
import { getSignUpWatcher } from './sign-up.saga';
import { getLogoutWatcher } from './logout.saga';
import { getSignInCompleteWatcher, getSignUpCompleteWatcher } from './auth-monitor.saga';
import { getRefreshTokenWatcher } from './refresh-token.saga';
import { getRequestPassword } from './request-password.saga';
import { getVerifyEmailWatcher } from './verify-email.saga';
import { getResetPasswordWatcher } from './reset-password.saga';

export default [
  getRefreshTokenWatcher(),
  getSignInWatcher(),
  getSignUpWatcher(),
  getSignInCompleteWatcher(),
  getSignUpCompleteWatcher(),
  getLogoutWatcher(),
  getRequestPassword(),
  getVerifyEmailWatcher(),
  getResetPasswordWatcher(),
];
