import { COUNTRIES_TYPES } from '../types';
import Action from './helpers/action';

export default class CountryActions {
  static getAll = () => {
    return Action.create(COUNTRIES_TYPES.GET_ALL_COUNTRIES);
  }

  static success = (countries) => {
    return Action.create(COUNTRIES_TYPES.GET_ALL_COUNTRIES_SUCCESS, { countries });
  }

  static error = (errorObj) => {
    return Action.createError(COUNTRIES_TYPES.GET_ALL_COUNTRIES_FAILURE, { error: errorObj });
  }
}

