import { Map } from 'immutable';
import { ALERT_TYPES } from '../types/alert.types';

/**
 * Redux Reducer for alerts
 */

const initialState = {
  message: '',
  category: '',
};

const actionsMap = {
  [ALERT_TYPES.SUCCESS]: (state, action) =>
    ({
      ...state,
      message: action.payload.message,
      category: 'success',
    }),
  [ALERT_TYPES.INFO]: (state, action) =>
    ({
      ...state,
      message: action.payload.message,
      category: 'info',
    }),
  [ALERT_TYPES.ERROR]: (state, action) =>
    ({
      ...state,
      message: action.payload.message,
      category: 'error',
    }),
  [ALERT_TYPES.CLEAR]: (state) =>
    ({
      ...state,
      message: '',
      category: '',
    }),
};

export default function reducer(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
