import { takeLatest, put, call } from 'redux-saga/effects';

import entityService from '../../../api/entity.service';
import walletService from '../../../api/wallets.service';
import bookbuildService from '../../../api/bookbuild.service';
import { USER_TYPES } from '../../types';
import UserActions from '../../actions/user.actions';

import SessionStore from '../../../utils/session-store';
import RulesService from '../../../api/rules.service';

/**
 * Redux Saga generator function for getting user entities
 */
function createGetCanInvest() {
  return function* (options) {
    try {
      const token = SessionStore.getSession();
      const {
        entityId,
      } = options.payload

      const body = yield call(() => RulesService.getCanInvestData(token, entityId));
      const action = UserActions.getCanInvestSuccess(body);

      yield put(action);
    } catch (error) {
      const failureAction = UserActions.getCanInvestError(error);
      yield put(failureAction);
    }
  };
}

export const getCanInvest = createGetCanInvest();

export function* getCanInvestWatcher() {
  yield takeLatest(USER_TYPES.GET_CAN_INVEST_BEGIN, getCanInvest);
}
