import { WALLETS_TYPES } from '../types';
import Action from './helpers/action';

export default class WalletActions {
  /**
   * Retrieve all wallets
   * @example
   * getAll()
   */
  static getAll = (entity_id) => {
    return Action.create(WALLETS_TYPES.GETWALLETS_BEGIN, { entity_id });
  }

  /**
   * Retrieve all wallets succeeded
   * @example
   * success([{payload}])
   */
  static success = (wallets) => {
    return Action.create(WALLETS_TYPES.GETWALLETS_SUCCESS, { wallets });
  }

  /**
   * Retrieve all wallets failed.
   * @example
   * error(errorObj)
   */
  static error = (errorObj) => {
    return Action.createError(WALLETS_TYPES.GETWALLETS_FAILURE, { error: errorObj });
  }
  /**
   * Retrieve all wallet transactions
   * @example
   * getAll()
   */
  static getAllTransactions = (entity_id, currency, investments) => {
    return Action.create(WALLETS_TYPES.GETWALLETTRANSACTIONS_BEGIN, { entity_id, currency, investments });
  }

  /**
   * Retrieve all transactions succeeded
   * @example
   * success([{payload}])
   */
  static successTransactions = (transactions) => {
    return Action.create(WALLETS_TYPES.GETWALLETTRANSACTIONS_SUCCESS, { transactions });
  }

  /**
   * Retrieve all transactions failed.
   * @example
   * error(errorObj)
   */
  static errorTransactions = (errorObj) => {
    return Action.createError(WALLETS_TYPES.GETWALLETTRANSACTIONS_FAILURE, { error: errorObj });
  }
  /**
   * Wallet withdrawal transactions
   * @example
   * getAll()
   */
  static walletWithdraw = (entity_id, amount, currencyIso, integrationId ) => {
    return Action.create(WALLETS_TYPES.WALLETWITHDRAW_BEGIN, { entity_id, amount, currencyIso, integrationId });
  }

  /**
   * Wallet withdrawal succeeded
   * @example
   * success([{payload}])
   */
  static successWalletWithdraw = () => {
    return Action.create(WALLETS_TYPES.WALLETWITHDRAW_SUCCESS);
  }

  /**
   * Wallet withdrawal failed.
   * @example
   * error(errorObj)
   */
  static errorWalletWithdraw = (errorObj) => {
    return Action.createError(WALLETS_TYPES.WALLETWITHDRAW_FAILURE, { error: errorObj });
  }
}
