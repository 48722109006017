import { takeLatest, put, call } from 'redux-saga/effects';

import SessionStore from '../../../utils/session-store';
import DocumentService from '../../../api/document.service';
import DocumentAction from '../../actions/document.actions';
import { DOCUMENT_TYPES } from '../../types/index';

function deleteDocument() {
  return function* (options) {
    try {
      const token = SessionStore.getSession();
      const documentId = options.payload.documentId;

      yield call(() => DocumentService.deleteDocument(token, documentId));

      yield put(DocumentAction.deleteDocumentSuccess(documentId));
    } catch (error) {
      yield put(DocumentAction.deleteDocumentFailure(error));
    }
  };
}

const newDeleteDocument = deleteDocument();

export function* deleteDocumentWatcher() {
  yield takeLatest(DOCUMENT_TYPES.DELETE_DOCUMENT_BEGIN, newDeleteDocument);
}
