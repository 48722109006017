import { takeLatest, put } from 'redux-saga/effects';
import { SESSION_TYPES } from '../../types';
import AlertActions from '../../actions/alert.actions';
import Persistor from '../../../index'
import SessionStore from '../../../utils/session-store';

/**
 * Redux Saga generator function for logout
 */
function createLogOut() {
  return function* (options) {
    try {
      if (SessionStore.hasSession()) {
        const subsystem = SessionStore.getSubsystem();
        SessionStore.clearSession();
        const retain = options.payload.retainSubsystem;
        if (retain) { // if we would like to retain the subsystem
          // set subsystem when logging out.
          window.history.pushState('', '', `/sign-in${ subsystem ? `?subsystem=${ subsystem }` : '' }`);
        }
        // Purge redux store persistor
        Persistor.purge()
        window.location.reload(); //easy way to reset redux state to default
      }
    } catch (error) {
      const action = AlertActions.error(error);

      yield put(action);
    }
  };
}

export const logOut = createLogOut();

export function* getLogoutWatcher() {
  yield takeLatest(SESSION_TYPES.SIGNOUT, logOut);
}
