import JwtDecode from 'jwt-decode';

/**
 * Session context to unwrap user sessions.
 */
export default class SessionContext {
  /**
   * Unwraps a user session.
   * @example
   * const context = some token; context.sub;
   * @returns {object} Returns an object containing the unwrapped user session.
   */
  static get(sessionToken) {
    if (!sessionToken) {
      throw Error('Invalid session supplied to context');
    }

    const token = JwtDecode(sessionToken);

    return {
      sub: token.sub,
      entities: token.entities || [],
      email: token.email,
      accountVerified: !!token.email,
      legacy: token.legacy,
      demandPartner: token.partners ? token.partners.demand : undefined,
      channelPartner: token.partners ? token.partners.channel : undefined,
    };
  }
}
