import { takeLatest, put, call, race } from 'redux-saga/effects';

import { SESSION_TYPES } from '../../types';
import userService from '../../../api/user.service';
import SessionActions from '../../actions/session.actions';
import SessionStore from '../../../utils/session-store';
import SessionContext from '../../../utils/session-context';

/**
 * Redux Saga generator function for refreshing token
 */
function createRefreshToken() {
  return function* (options) {
    try {
      // add in success flow for refreshing token.
      if (SessionStore.hasSession() === false) {
        throw Error('Cannot refresh token when no valid session.');
      }

      const refresh = SessionStore.getRefresh();
      const body = yield call(() => userService.refreshSession(refresh));
      const result = body.data[0];
      
      const subsystem = SessionStore.getSubsystem();
      SessionStore.setSession(result.access_token, refresh, subsystem);
      
      const context = SessionContext.get(result.access_token);
      const entities = context.entities;
      const accountVerified = context.accountVerified;
      const legacy = context.legacy;
      const demandPartner = context.demandPartner;
      const channelPartner = context.channelPartner;

      const successAction = SessionActions.refreshTokenSuccess(entities, accountVerified, legacy, demandPartner, channelPartner);

      yield put(successAction);
    } catch (error) {
      const failureAction = SessionActions.refreshTokenFailure(error);

      yield put(failureAction);
    }
  };
}

export const refreshToken = createRefreshToken();

export function* getRefreshTokenWatcher() {
  yield takeLatest(SESSION_TYPES.REFRESH_TOKEN_BEGIN, refreshToken);
}
