import PATH from '../paths';

const name = 'Yachties Wealth';
const key = 'yachties';
const imgUrl = `${ PATH.CDN }/whitelabels/${ key }`;
const images = {
  favicon: `${ imgUrl }/favicon.png`,
  logo: `${ imgUrl }/logos.png`,
  logoPDF: `${ imgUrl }/logo-pdf.png`,
  logoSidebar: `${ imgUrl }/logo-sidebar.png`,
  backgrounds: [
    `${ imgUrl }/background.jpg`
  ],
};

export default {
  name,
  key,
  images,
  stylesheet: `
  
    /* ANONYMOUS LAYOUT */

    --anonymous-bg:                   url(${ images.backgrounds[0] });
     --anonymous-logo-bg:             transparent;
    --anonymous-logo-height:          70px;
  `
};
