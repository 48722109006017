import 'isomorphic-fetch';
import { fetchResource } from './fetch-resource';

import { API } from '../constants';

/**
 * Product API service
 */
class ExitedInvestmentsService {
  /**
   * Retrieves wallet transactions for a specific entity by specified currency
   * @example
   * getExitedInvestmentsByEntityID('someJwt', entityID).then(res => { return res.data }).then(...)
   * @returns {Promise} Returns a promise containing the result in json format.
   */

  static getExitedInvestmentsByEntityID = (jwt, entity_id) => {
    const requestConfig = {
      cache: 'no-cache',
      mode: 'cors',
      headers: {
        'content-type': 'application/json',
        'authorization': `bearer ${ jwt }`,
      },
      method: 'GET',
    };

    return fetchResource(`${ API.EXITED_INVESTMENTS }?entity_id=${ entity_id }&limit=1000&sort=dsc`, requestConfig); //temp increasing limit until we've implemented pagination on the FE
  }

}

export default ExitedInvestmentsService;
