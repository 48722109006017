import 'isomorphic-fetch';
import { fetchResource } from './fetch-resource';

import { API } from '../constants';

class DepositReferenceService {
  /**
   * Creates a deposit reference.
   * @example
   * @returns {Promise} Returns a promise containing the result in json format.
   */

  static generateDepositReference = (jwt, userId, productId, amount, entityId, currency, baseFee, structureFee, marketingFee, blocktradeFee, agreements, sourcesOfWealth, feesRevision, feesTimestamp, subsystem = 'global') => {
    const body = {
      user_id: userId,
      entity_id: entityId,
      product_id: productId,
      amount,
      currency_iso: currency,
      base_fee: baseFee,
      structure_fee: structureFee,
      marketing_fee: marketingFee,
      blocktrade_fee: blocktradeFee,
      product_agreements: agreements,
      sources_of_wealth: sourcesOfWealth,
      fees_revision: feesRevision,
      fees_timestamp: feesTimestamp,
      subsystem
    };

    const requestConfig = {
      cache: 'no-cache',
      mode: 'cors',
      headers: {
        'content-type': 'application/json',
        'authorization': `bearer ${ jwt }`,
      },
      method: 'POST',
      body: JSON.stringify(body),
    };

    return fetchResource(`${ API.DEPOSIT_REFERENCE }`, requestConfig);
  }

  /**
   * Updates an existing deposit reference.
   * @example
   * @returns {Promise} Returns a promise containing the result in json format.
   */

  static updateDepositReference = (jwt, referenceId, patches) => {
    const body = patches;

    const requestConfig = {
      cache: 'no-cache',
      mode: 'cors',
      headers: {
        'content-type': 'application/json',
        'authorization': `bearer ${ jwt }`,
      },
      method: 'PATCH',
      body: JSON.stringify(body),
    };

    return fetchResource(`${ API.DEPOSIT_REFERENCE }/${ referenceId }`, requestConfig);
  }

  /**
   * Retrieves a specific reference made by id.
   * @example
   * getDepositReferenceById('someJwt', 'e228b575-f3c4-435d-8944-7069d9e04154').then(res => { return res.data }).then(...)
   * @returns {Promise} Returns a promise containing the result in json format.
   */
  static getDepositReferenceById = (jwt, referenceId) => {
    const requestConfig = {
      cache: 'no-cache',
      mode: 'cors',
      headers: {
        'content-type': 'application/json',
        'authorization': `bearer ${ jwt }`,
      },
      method: 'GET',
    };

    return fetchResource(`${ API.DEPOSIT_REFERENCE }/${ referenceId }`, requestConfig);
  }

  static getDepositReferenceByQuery = (jwt, queries = []) => {
    const requestConfig = {
      cache: 'no-cache',
      mode: 'cors',
      headers: {
        'content-type': 'application/json',
        'authorization': `bearer ${ jwt }`,
      },
      method: 'GET',
    };

    return fetchResource(`${ API.DEPOSIT_REFERENCE }${queries?.length > 0 ? `?${queries.join('&')}` : ''}`, requestConfig);
  }

  /**
   * Retrieves a reference made by a user for a specific product.
   * @example
   * @returns {Promise} Returns a promise containing the result in json format.
   */
  static getFilteredDepositReference = (jwt, userId, productId) => {
    const requestConfig = {
      cache: 'no-cache',
      mode: 'cors',
      headers: {
        'content-type': 'application/json',
        'authorization': `bearer ${ jwt }`,
      },
      method: 'GET',
    };

    return fetchResource(`${ API.DEPOSIT_REFERENCE }?user_id=${ userId }&product_id=${ productId }`, requestConfig);
  }

  /**
   * deletes a deposit reference.
   * @example
   * @returns {Promise} Returns a promise containing the result in json format.
   */
  static deleteDepositReference = (jwt, referenceId) => {
    const requestConfig = {
      cache: 'no-cache',
      mode: 'cors',
      headers: {
        'content-type': 'application/json',
        'authorization': `bearer ${ jwt }`,
      },
      method: 'DELETE',
    };

    return fetchResource(`${ API.DEPOSIT_REFERENCE }/${ referenceId }`, requestConfig);
  }
}

export default DepositReferenceService;
