import { takeLatest, put, call } from 'redux-saga/effects';

import { DOCUMENT_TYPES } from '../../types';
import DocumentActions from '../../actions/document.actions';
import documentService from '../../../api/document.service';
import SessionStore from '../../../utils/session-store';

/**
 * Redux Saga generator function for getting transaction statement
 */
function getProductInfoDocuments() {
  return function* (options) {
    try {
      const token = SessionStore.getSession();
      const {
        productId,
        subsystem,
        subsystemHost,
        minimum_investment,
        initiation_fee_rate,
        subsystemLogo,
        irr,
        coc,
        cap_return_rate,
        cap_return_succ_fee_rate,
        success_fee_rate
      } = options.payload;

      const productInfo = yield call(() => documentService.getProductInfoDocument(token, productId, subsystem, subsystemHost, minimum_investment, initiation_fee_rate, subsystemLogo, irr, coc, cap_return_rate,cap_return_succ_fee_rate, success_fee_rate));
      const downloadLink = document.createElement('a');
      downloadLink.href = URL.createObjectURL(productInfo);
      downloadLink.setAttribute(
          'download', `investment_summary.pdf`
      );
      document.body.appendChild(downloadLink)
      downloadLink.click();
      document.body.removeChild(downloadLink)
      const action = DocumentActions.getProductInfoDocumentSuccess(productInfo);
      yield put(action);
    } catch (error) {
      const failureAction = DocumentActions.getProductInfoDocumentFailure(error);
      yield put(failureAction);
    }
  };
}

export const getProductInfoDocument = getProductInfoDocuments();

export function* getProductInfoDocumentWatcher() {
  yield takeLatest(DOCUMENT_TYPES.GET_PRODUCT_INFO_DOCUMENT_BEGIN, getProductInfoDocument);
}
