import Action from './helpers/action';
import { PORTFOLIO_TYPES } from '../types';

export default class PortfolioActions {
  static getByEntity = (entityId, currency) => {
    return Action.create(PORTFOLIO_TYPES.GET_PORTFOLIO_BEGIN, { entityId, currency });
  }

  static success = (portfolios) => {
    return Action.create(PORTFOLIO_TYPES.GET_PORTFOLIO_SUCCESS, { portfolios });
  }

  static error = (errorObj) => {
    return Action.createError(PORTFOLIO_TYPES.GET_PORTFOLIO_FAILURE, { error: errorObj });
  }

  static createIndication = (productId, entityId, currencyIso, amount, fees, agreements, sourcesOfWealth, integrationIdentifier, feesRevision, feesTimestamp) => {
    return Action.create(PORTFOLIO_TYPES.CREATE_IOI_BEGIN, {
      productId,
      entityId,
      currencyIso,
      amount,
      fees,
      agreements,
      sourcesOfWealth,
      integrationIdentifier,
      feesRevision,
      feesTimestamp
    });
  }

  static createIndicationFailure = (errorObj) => {
    return Action.createError(PORTFOLIO_TYPES.CREATE_IOI_ERROR, { error: errorObj });
  }

  static createIndicationClearFailure = () => {
    return Action.createError(PORTFOLIO_TYPES.CREATE_IOI_CLEAR_ERROR, {});
  }

  static createIndicationSuccess = (indication) => {
    return Action.create(PORTFOLIO_TYPES.CREATE_IOI_SUCCESS, { indication });
  }

  static cancelIndicationBegin = (indicationId) => {
    return Action.create(PORTFOLIO_TYPES.CANCEL_IOI_BEGIN, { indicationId });
  }

  static cancelIndicationSuccess = (indicationId) => {
    return Action.create(PORTFOLIO_TYPES.CANCEL_IOI_SUCCESS, { indicationId });
  }

  static cancelIndicationError = (errorObj) => {
    return Action.createError(PORTFOLIO_TYPES.CANCEL_IOI_FAILURE, { error: errorObj });
  }
}

