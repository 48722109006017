import PATH from '../paths';

const name = 'EDPF';
const key = 'edpf';
const imgUrl = `${ PATH.CDN }/whitelabels/${ key }`;
const images = {
  favicon: `${ imgUrl }/favicon.png`,
  logo: `${ imgUrl }/logo.png`,
  logoPDF: `${ imgUrl }/logo-pdf.png`,
  logoSidebar: `${ imgUrl }/logo-sidebar.png`,
  opengraph: false,
  backgrounds: [
    `${ imgUrl }/bg-new.jpg`
  ],
};

export default {

  name,
  key,
  images,
  stylesheet: `

    /* COLOR VARIABLES +++++++++++++++++++++++++++++++++++++++++++++++++++++  */

    --color-cypriot-blue:             #00abcd;
    --color-cypriot-blueDark:         #005893;
    --color-cypriot-yellow:           #ffd300;

    --color-edpf-blue:                #00a3c8;
    --color-edpf-black:               #242526;
    --color-edpf-orange:              #e87d26;

    --color-primary:                  var(--color-cypriot-blue);
    --color-secondary:                var(--color-edpf-black);
    --color-accent:                   var(--color-edpf-blue);

    /* END +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++  */

    /* TYPOGRAPHY ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++  */

    --color-link:                     var(--color-edpf-orange);
    --color-link-hover:               var(--color-secondary);

    /* END +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++  */
    
    /* LAYOUTS +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++  */

    /* ANONYMOUS LAYOUT */

    --anonymous-logo-bg:              var(--color-white);
    --anonymous-copy-bg:              var(--color-white);
    --anonymous-heading-color:        var(--color-edpf-blue);
    --anonymous-subheading-color:     var(--color-edpf-black);
    --anonymous-text-color            var(--color-edpf-black);
    --anonymous-logo-height:          50px;
    --anonymous-bg:                   url(${ images.backgrounds[0] });
    --anonymous-bg-size:              cover;

    /* END */

    /* SIDEBAR */

    --sidebar-bg-color: var(--color-edpf-black);
    --sidebar-logo-bg-color: var(--color-edpf-black);
    --sidebar-logo-height: 45px;
    --sidebar-label-color: rgba(var(--color-white-rgb), 0.7);
    --sidebar-border-color: var(--color);

    --sidebar-button-color: var(--color-accent);
    --sidebar-button-color-active: var(--color-edpf-black);
    --sidebar-button-color-hover: var(--color-edpf-black);
    --sidebar-button-bg-color: transparent;
    --sidebar-button-bg-color-active: var(--color-accent);
    --sidebar-button-bg-color-hover: var(--color-accent);
    --sidebar-button-border-color: var(--color-accent);
    --sidebar-button-border-color-active: var(--color-accent);
    --sidebar-button-border-color-hover: var(--color-accent);

    /* END */

    /* END +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++  */

    /* BUTTONS +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++  */

    /* PRIMARY BUTTON */

    --btn-primary--bg:                var(--color-edpf-black);
    --btn-primary--border:            var(--color-edpf-black);
    --btn-primary--color:             var(--color-edpf-blue);
    --btn-primary--colorH:            var(--color-edpf-black);
    --btn-primary--bgH:               var(--color-edpf-blue);
    --btn-primary--borderH:           var(--color-edpf-blue);

    /* END */

    /* SECONDARY BUTTON */

    --btn-secondary--bg:              transparent;
    --btn-secondary--border:          var(--color-edpf-blue);
    --btn-secondary--color:           var(--color-edpf-black);
    --btn-secondary--bgH:             transparent;
    --btn-secondary--borderH:         var(--color-edpf-blue);
    --btn-secondary--colorH:          var(--color-edpf-blue);

    /* END */

    /* END +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++  */
  `,
  
};
