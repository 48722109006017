export const SESSION_TYPES = {
  SIGNIN_BEGIN: 'SESSION_SIGNIN_BEGIN',
  SIGNIN_FAILURE: 'SESSION_SIGNIN_FAILURE',
  SIGNIN_SUCCESS: 'SESSION_SIGNIN_SUCCESS',

  SIGNUP_BEGIN: 'SESSION_SIGNUP_BEGIN',
  SIGNUP_FAILURE: 'SESSION_SIGNUP_FAILURE',
  SIGNUP_SUCCESS: 'SESSION_SIGNUP_SUCCESS',

  SIGNOUT: 'SESSION_SIGNOUT',

  REFRESH_TOKEN_BEGIN: 'REFRESH_TOKEN_BEGIN',
  REFRESH_TOKEN_FAILURE: 'REFRESH_TOKEN_FAILURE',
  REFRESH_TOKEN_SUCCESS: 'REFRESH_TOKEN_SUCCESS',

  VERIFY_EMAIL_BEGIN: 'VERIFY_EMAIL_BEGIN',
  VERIFY_EMAIL_FAILURE: 'VERIFY_EMAIL_FAILURE',
  VERIFY_EMAIL_SUCCESS: 'VERIFY_EMAIL_SUCCESS',

  REQUEST_PASSWORD_BEGIN: 'REQUEST_PASSWORD_BEGIN',
  REQUEST_PASSWORD_FAILURE: 'REQUEST_PASSWORD_FAILURE',
  REQUEST_PASSWORD_SUCCESS: 'REQUEST_PASSWORD_SUCCESS',

  RESET_PASSWORD_BEGIN: 'RESET_PASSWORD_BEGIN',
  RESET_PASSWORD_FAILURE: 'RESET_PASSWORD_FAILURE',
  RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',

  HYDRATE_STATE_BEGIN : 'HYDRATE_STATE_BEGIN',
  HYDRATE_STATE_FAILURE : 'HYDRATE_STATE_FAILURE',
  HYDRATE_STATE_SUCCESS : 'HYDRATE_STATE_SUCCESS',
};
