export default class Documents {
  static isAssociated = (associated_id, entityId) => associated_id === entityId;

  static getDocsByTags = (associatedId = '', documents = [], tags = []) =>
    documents?.filter(
      (doc) =>
        this.isAssociated(doc.associated_id, associatedId) &&
        !doc.tags?.includes('merged') &&
        doc.tags
          ?.map((t) => t.toLowerCase())
          ?.some((t) =>
            tags.map((t) => t.toLowerCase())?.includes(t.toLowerCase())
          )
    );
}