import React, { useCallback, useMemo } from 'react';
import propTypes from 'prop-types';
import Loader from '@traaidmark/konstruct-part-loader';
import { Link } from 'react-router-dom';
import Sticker from '@traaidmark/konstruct-part-sticker';

import EntitySwitcher from '../EntitySwitcher';

import NumberExtensions from '../../../utils/NumberExtensions';

import { KYC_STATUS, PATH } from '../../../constants';
import UserActions from '../../../redux/actions/user.actions';
import { connect } from 'react-redux';

import './stylesheet.css';

const TopBar = ({
  router,
  dashbar,
  entities,
  activeEntity,
  switchActiveEntity,
  portfolioLoading = false,
  portfolios = [],
  wallets = undefined,
  walletsLoading,
  lister,
  className = '',
  investment,
  bankAccounts = [] ,
  fetchBankAccounts = () => {} 
}) => {

  useMemo(() => {
    activeEntity && fetchBankAccounts(activeEntity._id);
  }, [activeEntity, fetchBankAccounts]);

  const Balances = useCallback(() => {

    const isVerified = activeEntity?.email_confirmation_date !== 0 || activeEntity?.kyc_status === 'KYC Approved';
    if (!dashbar || !isVerified) return null;

    const portfolio = portfolios ? portfolios
      .filter((i) => i.type.toLowerCase() === dashbar.toLowerCase())
      .map(i => {
        return {
          total: NumberExtensions.toFixedString(i.totals.total),
          currency: i.type.toLowerCase(),
          funded: i.orders.length,
          pending: i.indications.length,
        }
      }) : undefined;
    const wallet = wallets ? wallets
      .filter((i) => i.currency_iso.toLowerCase() === dashbar.toLowerCase()) : undefined;

    const availableBalance = wallet.length > 0 ? NumberExtensions.toFixedString(NumberExtensions.availWalletBalance(wallet[0]?.balance, wallet[0]?.reserved)) : undefined;

    const hasPortfolio = portfolio.length === 1 ? true : false;
    const onDealPage = router?.location?.pathname?.toLowerCase()?.includes('deal')
    
    return (
      <>
        {
          wallet.length === 1 && dashbar && 
          (!onDealPage || (onDealPage && investment?.structure_provider?.toLowerCase() === 'wealth migrate')) &&
          (lister?.preferred_currency_iso?.filter(c => c.toLowerCase() === dashbar.toLowerCase()).length > 0) &&
          (
            <li className="u-tab">
              <Link 
                to={ PATH.PORTFOLIO_DETAIL(dashbar.toLowerCase()) } data-pendo='dashbar-investment-total'
              >
                <Sticker
                  label='Total Investments'
                  value={ !hasPortfolio ? 0 : portfolio[0]?.total }
                  prefix={ !hasPortfolio ? '' : portfolio[0]?.currency }
                  size="small"
                />
              </Link>
              <Link 
                to={ PATH.PORTFOLIO_DETAIL(dashbar.toLowerCase()) } data-pendo='dashbar-investment-funded'
              >
                <Sticker
                  label='Funded'
                  value={ !hasPortfolio ? 0 : portfolio[0]?.funded }
                  size="small"
                  className="p-sticker--center"
                />
              </Link>
              <Link 
                to={ PATH.PORTFOLIO_DETAIL(dashbar.toLowerCase()) } data-pendo='dashbar-investment-pending'
              >
                <Sticker
                  label='Pending'
                  value={ !hasPortfolio ? 0 : portfolio[0]?.pending }
                  size="small"
                  className="p-sticker--center"
                />
              </Link>
            </li>
          )
        }
        {
          (lister?.use_wallets === true || lister?.use_wallets === undefined) && wallet.length === 1 && availableBalance !== '0.00' &&
          (!onDealPage || (onDealPage && investment?.structure_provider?.toLowerCase() === 'wealth migrate')) &&
          (lister?.preferred_currency_iso?.filter(c => c.toLowerCase() === dashbar.toLowerCase()).length > 0) && (
            <li className="u-tab">
              <Link to={ PATH.WALLET_DETAIL(wallet[0].currency) }>
                <Sticker
                  label="Available Wallet Balance"
                  value={ availableBalance }
                  prefix={ wallet[0].currency }
                  data-pendo="dashbar-wallets"
                  size="small"
                />
              </Link>
            </li>
          )
        }
        {
          bankAccounts?.filter(b => b.wallet_links.find(wl => wl.currency_iso.toLowerCase() === dashbar.toLowerCase()))?.length === 0 && dashbar && (lister?.preferred_currency_iso?.filter(c => c.toLowerCase() === dashbar.toLowerCase()).length > 0) && 
           activeEntity.kyc_status.toLowerCase() === KYC_STATUS.kyc_approved.toLowerCase() &&
           (lister?.use_wallets === true || lister?.use_wallets === undefined) && 
           (!onDealPage || (onDealPage && investment?.structure_provider?.toLowerCase() === 'wealth migrate')) && 
           (lister?.preferred_currency_iso?.filter(c => c.toLowerCase() === dashbar.toLowerCase()).length > 0) && (
            <li className="">
              <Link 
                to={ `${PATH.PROFILE}/${activeEntity?._id}/4/false` }
                data-pendo='dashbar-profile-link'
                className="p-button p-button--tiny"
              >
                Link { dashbar?.toUpperCase() } Wallet
              </Link>
              <small className="u-margin-left--small"><strong>You need to link your bank account to a { dashbar?.toUpperCase() } wallet before you can invest in { dashbar?.toUpperCase() } deals.</strong></small>
            </li>
          )
        }
        {/**
         * Dont show this notice on indie deal pages.
         * */}
        {
          (availableBalance === '0.00') && 
          bankAccounts?.filter(b => b.wallet_links.find(wl => wl.currency_iso.toLowerCase() === dashbar.toLowerCase()))?.length > 0 &&
          dashbar && activeEntity.kyc_status.toLowerCase() === KYC_STATUS.kyc_approved.toLowerCase() && 
          (!onDealPage || (onDealPage && investment?.structure_provider?.toLowerCase() === 'wealth migrate')) && 
          (lister?.use_wallets === true || lister?.use_wallets === undefined) &&
          (lister?.preferred_currency_iso?.filter(c => c.toLowerCase() === dashbar.toLowerCase()).length > 0)  && (
            <li className="">
              <Link 
                to={ PATH.WALLET_DETAIL(wallet[0].currency_iso) }
                data-pendo='dashbar-wallet-link'
                className="p-button p-button--tiny"
              >
                Fund { dashbar?.toUpperCase() } Wallet
              </Link>
              <small className="u-margin-left--small"><strong>You don't have any available funds in your { dashbar?.toUpperCase() } wallet to invest with.</strong></small>
            </li>
          )
        }
      </>
    );
    
  }, [wallets, portfolios, dashbar, lister, activeEntity, investment, router, bankAccounts]);

  return (
    <aside className={ `c-topbar ${ className }` }>
      <ul className="c-topbar__left u-list-horizontal">
        {
          portfolioLoading || walletsLoading ? (
            <li><Loader className="p-loader--small" /></li>
          ) : <Balances />
        }
      </ul>
      <ul className="c-topbar__right u-list-horizontal">
        <li className="u-tab">
          { activeEntity && (
            <EntitySwitcher
              entities={ entities }
              activeEntity={ activeEntity }
              switchActiveEntity={ (entity) => switchActiveEntity(entity) }
            />
          ) }
        </li>
      </ul>
    </aside>
  )
};

TopBar.propTypes = {
  dashbar: propTypes.string,
  entities: propTypes.array,
  activeEntity: propTypes.object,
  switchActiveEntity: propTypes.func,
  investment: propTypes.object,
  portfolioLoading: propTypes.bool,
  portfolios: propTypes.array,
  wallets: propTypes.array,
  walletsLoading: propTypes.bool,
  lister: propTypes.object,
  className: propTypes.string,
};

const mapStateToProps = (state) => ({
  investment: state.investment.item,
  router: state.router,
  bankAccounts: state.user.bank_accounts,
  portfolios: state.portfolio.portfolios,
})

const mapDispatchToProps = (dispatch) => {
  return ({
    fetchBankAccounts: (entity_id) => { dispatch(UserActions.getBankAccounts(entity_id)); },
  })
};

export default connect(mapStateToProps, mapDispatchToProps)(TopBar);
