export default class Validation {
  /**
   * A function to validate a IBAN
   * @param {*} iban the IBAN to be validated as a string
   * @example const isValid = validIban('LU290106229714389977');
   */
  /* global BigInt */
  static validIban(iban = ''){
    if(iban === ''){
      return false
    } else {
      const n = iban.substring(4).concat(iban.substring(0, 4)).toLowerCase();
      let o = [];
      for(let i = 0; i < n.length; i ++){
        const c = n.charCodeAt(i);
        if (c > 96){
          o.push((n.charCodeAt(i) - 87).toString());
        }
        if (c > 47 && c < 58){
          o.push((n.charCodeAt(i) - 48).toString());
        }
      }
      return (BigInt(o.toString().replace(/,/g, ''))) % BigInt(97) === BigInt(1);
    }
  }
}

