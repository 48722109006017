import { KYC_TYPES } from '../types';
import Action from './helpers/action';

export default class KYCActions {

  static getByISO2Code = (iso_2_code) => {
    return Action.create(KYC_TYPES.GETBANKINGFIELDS_BY_ISO2_BEGIN, { iso_2_code });
  }
  static success = (countryFields) => {
    return Action.create(KYC_TYPES.GETBANKINGFIELDS_BY_ISO2_SUCCESS, { countryFields });
  }
  static error = (errorObj) => {
    return Action.createError(KYC_TYPES.GETBANKINGFIELDS_BY_ISO2_FAILURE, { error: errorObj });
  }
}
