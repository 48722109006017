import { getEntitiesWatcher } from './get-entities.saga';
import { getBankAccountsWatcher } from './get-bank_accounts.saga';
import { updateBankAccountsWatcher } from './update-bank-account.saga';
import { postBankAccountsWatcher } from './create-bank-account.saga';
import { newPersonalEntitiesWatcher } from './create-personal-entity.saga';
import { updatePersonalEntityWatcher } from './update-entity.saga.js';
import { sendVerificationEmailWatcher } from './send-verification-email.saga.js';
import { newCompanyEntityWatcher } from './create-company-entity.saga.js';
import { getCanInvestWatcher } from './get-can-invest.saga.js';
import { getUserLevelsWatcher } from './get-user-levels.saga';
import { getUserAgreementsWatcher } from './get-user-agreements.saga';
import { updateUserAgreementsWatcher } from './update-user-agreements.saga';
import { associateBankStatementWatcher } from './associate_bank_statement.saga.js';

export default [
  associateBankStatementWatcher(),
  getEntitiesWatcher(),
  newPersonalEntitiesWatcher(),
  newCompanyEntityWatcher(),
  updatePersonalEntityWatcher(),
  sendVerificationEmailWatcher(),
  getCanInvestWatcher(),
  getUserLevelsWatcher(),
  getUserAgreementsWatcher(),
  updateUserAgreementsWatcher(),
  getBankAccountsWatcher(),
  updateBankAccountsWatcher(),
  postBankAccountsWatcher(),
];
