export default {
  allStatuses: 'All Statuses',
  open: 'Open',
  underReview: 'Under Review',
  funded: 'Funded',
  exited: 'Exited',
  allDeals: 'All Deals',
  platform: 'Portfolio Deals',
  indie: 'Independent Deals',
  realEstate: 'Real Estate',
  alternative: 'Alternative Investments',
  allMarketplaces: 'All Asset Classes',
  allCurrencies: 'All Deal Currencies',
  zar: 'ZAR',
  usd: 'USD',
  gbp: 'GBP',
  aud: 'AUD',
  eur: 'EUR',
}
