import { takeLatest, put, call } from 'redux-saga/effects';

import { PORTFOLIO_TYPES } from '../../types';

import PortfolioActions from '../../actions/portfolio.actions';
import SessionStore from '../../../utils/session-store';
import BookbuildService from '../../../api/bookbuild.service';


/**
 * Redux Saga generator function for entity portfolio
 */
function cancelIOI() {
  return function* (options) {
    try {
      const token = SessionStore.getSession();
      const {
        indicationId,
      } = options.payload;

      yield call(() => BookbuildService.cancelIndication(token, indicationId))

      yield put(PortfolioActions.cancelIndicationSuccess(indicationId));
    } catch (error) {
      const failureAction = PortfolioActions.cancelIndicationError(error);
      yield put(failureAction);
    }
  };
}

export const getCancelIOI = cancelIOI();

export function* getCancelIOIWatcher() {
  yield takeLatest(PORTFOLIO_TYPES.CANCEL_IOI_BEGIN, getCancelIOI);
}
