import { takeLatest } from 'redux-saga/effects';
import SessionStore from '../../../utils/session-store';
import { USER_TYPES } from '../../types/user.types';

function createSaveState() {
  return function* (options) {
    try {
      SessionStore.setActiveEntity(options.payload.entity._id)
      yield;
    } catch (error) {
      yield;
    }
  };
}

export const saveState = createSaveState();

export function* getActiveEntityChangeWatcher() {
  yield takeLatest(USER_TYPES.SELECT_ACTIVE_ENTITY, saveState);
}
