import { put, call, takeEvery } from 'redux-saga/effects';

import { DOCUMENT_TYPES } from '../../types';
import DocumentActions from '../../actions/document.actions';
import documentService from '../../../api/document.service';
import SessionStore from '../../../utils/session-store';

/**
 * Redux Saga generator function for getting all documents
 */
function createGetAllDocuments() {
  return function* (options) {
    try {
      const token = SessionStore.getSession();
      const associatedId = options.payload.associatedId;
      const ids = options.payload.ids || [];

      const associatedBody = yield call(() => documentService.getDocumentsForListing(token, associatedId));
      const idsBody = yield call(() => documentService.getDocumentsById(token, ids));

      const documents = [...associatedBody.data?.filter(doc => doc.render_inline === false), ...idsBody.data];

      const action = DocumentActions.getAllSuccess(documents);

      yield put(action);
    } catch (error) {
      const failureAction = DocumentActions.getAllError(error);
      yield put(failureAction);
    }
  };
}

export const getAllDocuments = createGetAllDocuments();

export function* getAllDocumentWatcher() {
  yield takeEvery(DOCUMENT_TYPES.GETALLDOCUMENTS_BEGIN, getAllDocuments);
}
