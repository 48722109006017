import 'isomorphic-fetch';

/**
 * Custom error implementation for Api resources.
 */
class ApiError extends Error {
  constructor(message, code = -1, status = 'A system error occurred.', apiErrors = undefined) {
    super(message);

    this.name = this.constructor.name;
    this.code = code;
    this.status = status;
    this.apiErrors = apiErrors;
    this.isApiError = true;

    if (typeof Error.captureStackTrace === 'function') {
      Error.captureStackTrace(this, this.constructor);
    } else {
      this.stack = (new Error(message)).stack;
    }
  }
}

/**
 * Fetch wrapper for an API resource from the platform.
 * @example
 * fetchresource('some endpoint', { fetch config }).then(res => { return res.json() }).then(...)
 * @returns {Promise} Returns a promise containing the result in json format or an Api Error if the item does not exist
 */
const fetchResource = (url, config) => {
  return fetch(url, config)
    .then(response => {
      const contentType = response.headers.get('content-type');
      // handle json response
      if (contentType && contentType.indexOf('application/json') !== -1) {
        return response.json().catch((e) => { throw e; }).then(data => ({ response, data }));
      }
      if (contentType && contentType.indexOf('application/octet-stream') !== -1) {
        return response.blob().catch((e) => { throw e; }).then(data => ({ response, data }));
      }

      // throw text responses, since we only support json
      return response.text()
        .then((text) => {
          throw new ApiError(
            `The Api returned an error result from the server with status ${ response.status }.`,
            response.status,
            text);
        });
    }).catch((err) => {
      return Promise.reject(err);
    }).then((result) => {
      const hasErrors = !!result.data.errors;
      const errorCollection = hasErrors ? result.data.errors : undefined;

      if (!result.response.ok || hasErrors) {
        return Promise.reject(
          new ApiError(`The Api returned an error result from the server with status ${ result.response.status }.`,
            result.response.status,
            'An error occurred on the server.',
            errorCollection));
      }
      return result.data;
    });
};

export { fetchResource };
