import PATH from '../paths';

const name = 'Wealth Migrate';
const key = 'global';
const imgUrl = `${ PATH.CDN }/whitelabels/${ key }`;
const images = {
  favicon: `${ imgUrl }/favicon.png`,
  logo: `${ imgUrl }/logo.png`,
  logoPDF: `${ imgUrl }/logo-pdf.png`,
  logoSidebar: `${ imgUrl }/logo.png`,
};

export default {
  name,
  key,
  images
};
