export const WALLETS_TYPES = {
  GETWALLETS_BYID_BEGIN: 'GETWALLETS_BYID_BEGIN',
  GETWALLETS_BEGIN: 'GETWALLETS_BEGIN',
  GETWALLETS_FAILURE: 'GETWALLETS_FAILURE',
  GETWALLETS_SUCCESS: 'GETWALLETS_SUCCESS',
  GETWALLETTRANSACTIONS_BYID_BEGIN: 'GETWALLETTRANSACTIONS_BYID_BEGIN',
  GETWALLETTRANSACTIONS_BEGIN: 'GETWALLETTRANSACTIONS_BEGIN',
  GETWALLETTRANSACTIONS_FAILURE: 'GETWALLETTRANSACTIONS_FAILURE',
  GETWALLETTRANSACTIONS_SUCCESS: 'GETWALLETTRANSACTIONS_SUCCESS',
  WALLETWITHDRAW_BEGIN: 'WALLETWITHDRAW_BEGIN',
  WALLETWITHDRAW_FAILURE: 'WALLETWITHDRAW_FAILURE',
  WALLETWITHDRAW_SUCCESS: 'WALLETWITHDRAW_SUCCESS',
};
