import { createPatch } from "rfc6902";

export const flattenDeep = (arr) => {
  return arr.reduce((acc, val) => Array.isArray(val) ? acc.concat(flattenDeep(val)) : acc.concat(val), []); // eslint-disable-line
};

/**
 * Restricting user from mistakenly patching parameters
 * @param {[{op: String, path: String, value: String}]} objectDiff
 */
export const greenlightPatches = (objDiffs = [], restrictedPaths = []) => {
  return objDiffs?.filter(ed => !restrictedPaths.includes(ed.path));
}

export const CreateApiFriendlyPatch = (patchObj) => {
  const listParameters = [
    "/product_agreements",
    "/authorized_representatives",
    "/addresses",
    "/bank_accounts",
    "/wallet_links",
  ];

  if (
    typeof patchObj.value === "object" &&
    !listParameters.some((li) => patchObj.path.includes(li))
  ) {
    return createPatch({}, patchObj.value).map((item) => {
      const newPath = `${patchObj.path}${item.path}`;
      const nestedItem = {
        ...item,
        path: newPath,
      };
      return CreateApiFriendlyPatch(nestedItem);
    });
  }

  //esuring replace is used for uninitialized optional values
  if (
    patchObj.op === "add" &&
    !listParameters.some((li) => patchObj.path.includes(li))
  ) {
    const item = {
      ...patchObj,
      op: "replace",
    };
    if (!patchObj.value) {
      item.value = "";
    }
    return item;
  }

  //ensuring we're 'adding' on the correct 'path'
  if (
    patchObj.op === "add" &&
    listParameters.some((li) => patchObj.path.includes(li))
  ) {
    // untill we review the way we allow or deny the removal of certain fields in the BE
    if (patchObj.path.includes("/product_agreements")) {
      const item = {
        ...patchObj,
        path: "/product_agreements",
      };
      return item;
    }
    if (patchObj.path.includes("/authorized_representatives")) {
      if (
        patchObj.path.includes(
          "/content/authorized_representatives/0/date_of_birth"
        )
      ) {
        const item = {
          ...patchObj,
          op: "replace",
        };
        return item;
      }
      const item = {
        ...patchObj,
        value: patchObj.value[0],
        path: "/content/authorized_representatives",
      };
      return item;
    }
    if (patchObj.path.includes("/addresses")) {
      if (typeof patchObj.value[0] !== "object") {
        return undefined;
      }
      const item = {
        ...patchObj,
        value: patchObj.value[0],
        path: "/content/addresses",
      };
      return item;
    }
    if (patchObj.path.includes("/wallet_links")) {
      const item = {
        ...patchObj,
        path: patchObj?.path?.replaceAll(`/-`, ""),
      };
      return item;
    }
  }

  if (patchObj.op === "replace" && !patchObj.value) {
    const item = {
      ...patchObj,
      value: "",
    };
    return item;
  }

  if (
    patchObj.op === "remove" && !listParameters.some((li) => patchObj.path.includes(li))
  ) {
    const item = {
      ...patchObj,
      op: "replace",
      value: "",
    };
    return item;
  }

  return patchObj;
};
