export default {
  public: {
    signIn: {
      'heading': 'Hello',
      'subHeading': 'Marketplace',
      'text': 'Building a diversified portfolio has never been easier.',
    },
    signUp: {
      'heading': 'Get Started',
      'subHeading': 'Become a global investor with',
      'text': 'Building a diversified portfolio has never been easier.',
    },
    passwordReset: {
      'heading': 'Password Reset',
      'text': 'If you require additional help, please contact us at support@wealthpoint.app.',
    },
    emailVerify: {
      'heading': 'Verify your password',
      'text': 'If you require additional help, please contact us at support@wealthpoint.app.',
    },
    unsupportedCountry: {
      'heading': 'Learn about Real Estate investing',
      'text': 'Join the Wealth University, an e-learning platform for those who want their wealth knowledge to take off.',
    },
  }
}
