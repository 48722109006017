import PATH from '../paths';

const name = 'WealthPoint';
const key = 'russellwallett';
const imgUrl = `${ PATH.CDN }/whitelabels/russellwallett`;
const images = {
  logo: `${ imgUrl }/logo.png`,
  logoSidebar: `${ imgUrl }/logo-icon.png`,
  logoPDF: `${ imgUrl }/logo-pdf.png`,
  opengraph: false,
  backgrounds: [
    `${ imgUrl }/background.jpg`
  ],
};

export default {
  name,
  key,
  images,
  stylesheet: `
    /* COLOR VARIABLES +++++++++++++++++++++++++++++++++++++++++++++++++++++  */

    --color-wp-blue-lighter:          160, 205, 232;
    --color-wp-blue-light:            106, 174, 223;
    --color-wp-blue:                  37, 124, 192;
    --color-wp-blue-dark:             32, 52, 102;
    --color-wp-purple:                97, 61, 151;

    --color-primary:                  var(--color-darker);
    --color-secondary:                var(--color-darker);
    --color-accent:                   rgb(var(--color-wp-blue-lighter));

    /* END +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++  */

    /* TYPOGRAPHY ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++  */

    --color-link:                     rgb(var(--color-wp-blue));
    --color-link-hover:               rgb(var(--color-wp-blue-dark));

    /* END +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++  */
   
    /* LAYOUTS +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++  */

    /* ANONYMOUS LAYOUT */

    --anonymous-logo-bg:              var(--color-white);
    --anonymous-copy-bg:              var(--color-white);
    --anonymous-heading-color:        rgb(var(--color-wp-blue-dark));
    --anonymous-subheading-color:     rgb(var(--color-wp-blue));
    --anonymous-text-color            var(--color-dark);
    --anonymous-logo-height:          50px;
    --anonymous-bg:                   url(${ images.backgrounds[0] });
    --anonymous-bg-size:              cover;

    /* END */

    /* SIDEBAR */

    --sidebar-bg-color: rgb(var(--color-wp-blue-dark));
    --sidebar-logo-bg-color: var(--color-white);
    --sidebar-logo-height: 45px;
    --sidebar-label-color: rgba(var(--color-white-rgb), 0.7);
    --sidebar-border-color: var(--color);

    --sidebar-button-color: rgb(var(--color-wp-blue-lighter));
    --sidebar-button-color-active: rgb(var(--color-wp-blue-dark));
    --sidebar-button-color-hover: rgb(var(--color-wp-blue-lighter));
    --sidebar-button-bg-color: transparent;
    --sidebar-button-bg-color-active: rgb(var(--color-wp-blue-lighter));
    --sidebar-button-bg-color-hover: transparent;
    --sidebar-button-border-color: rgb(var(--color-wp-blue-lighter));
    --sidebar-button-border-color-active: rgb(var(--color-wp-blue-lighter));
    --sidebar-button-border-color-hover: rgb(var(--color-wp-blue-lighter));

    /* END */

    /* END +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++  */

    /* BUTTONS +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++  */

    /* PRIMARY BUTTON */

    --btn-primary--bg:                rgb(var(--color-wp-purple));
    --btn-primary--border:            rgb(var(--color-wp-purple));
    --btn-primary--color:             var(--color-white);
    --btn-primary--colorH:            var(--color-white);
    --btn-primary--bgH:               rgb(var(--color-wp-purple));
    --btn-primary--borderH:           rgb(var(--color-wp-purple));

    /* END */

    /* SECONDARY BUTTON */

    --btn-secondary--bg:              transparent;
    --btn-secondary--border:          rgb(var(--color-wp-purple));
    --btn-secondary--color:           rgb(var(--color-wp-purple));
    --btn-secondary--bgH:             transparent;
    --btn-secondary--borderH:         rgb(var(--color-wp-purple));
    --btn-secondary--colorH:          rgb(var(--color-wp-purple));

    /* END */

    /* END +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++  */
  `,
};
