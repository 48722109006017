import React from 'react';
import propTypes from 'prop-types';
import Notification from '@traaidmark/konstruct-part-notification';

import iconSrc from '../../images/icons/notification-green.svg';

const Info = ({ children, className = '' }) => {
  return (
    <Notification className={ `p-notification--success ${ className }`} icon={ iconSrc }>
      { children }
    </Notification>
  )
};

Info.propTypes = {
  children: propTypes.node,
  className: propTypes.string
}

export default Info;
