import 'isomorphic-fetch';
import { fetchResource } from './fetch-resource';

import { API } from '../constants';

/**
 * Country API service
 */
class CountryService {
  /**
   * Retrieves country for listing
   * @example
   * getCountries('someJwt').then(res => { return res.data }).then(...)
   * @returns {Promise} Returns a promise containing the result in json format.
   */
  static getCountries = (jwt) => {
    const requestConfig = {
      cache: 'no-cache',
      mode: 'cors',
      headers: {
        'content-type': 'application/json',
        'authorization': `bearer ${ jwt }`,
      },
      method: 'GET',
    };

    return fetchResource(`${ API.COUNTRIES }?sort=name&limit=5000`, requestConfig);
  }
}

export default CountryService;
