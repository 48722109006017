import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

const MsgSignin = ({
  data = {},
  type = 'global',
  name = 'Wealth Migrate'
}) => {

  const SubHeading = useCallback(() => {
    if (type === 'signIn') {
      return <h4>{ name } { data.subHeading }</h4>;
    };
    if (type === 'signUp') {
      return <h4>{ data.subHeading } { name }</h4>;
    };
    return data.subHeading ? <h4>{ data.subHeading }</h4> : null;
  }, [type])

  if(!data.heading && !data.text && !data.subHeading) return null;

  return (
    <aside className="u-arrow u-arrow--top-left">
      { data.heading && <h1>{ data.heading }</h1> }
      <SubHeading />
      { data.text && <p className='u-lead' dangerouslySetInnerHTML={ { __html: data.text } } /> }
    </aside>
  );
};

MsgSignin.propTypes = {
  data: PropTypes.object,
};

export default MsgSignin;
