import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Logo } from '@wealthmigrate/architect-icons';

import SystemAlert from '../../containers/SystemAlert';

import Footer from '../../components/Footer';

import Content from './Content';
import Quote from './Quote';

import './stylesheet.css';

/**
 * React Layout for authentication views.
 */
const AnonymousLayout = ({ children, ui, name }) => {

  const PaintLogo = useCallback(() => {
    return ui.images ? <img src={ `${ ui.images.logo }` } alt={ `${ ui.name } - powered by Wealth Migrate` } /> : <Logo color="var(--color-white)" />;
  }, [ui]);

  const copy = useCallback(() => {
    if(ui.copy.public[name]) {
      return ui.copy.public[name];
    }
    return {};
  }, [name]);

  return (
    <div className="l-anonymous">
      { <SystemAlert /> }
      <header className="l-anonymous__header">
        <figure>
          <PaintLogo />
        </figure>
        <Content data={ copy() } type={ name } name={ ui.name } />
      </header>

      <main className="l-anonymous__content">
        { children }
      </main>

      { ui.quote && <Quote data={ ui.quote} /> }

      <Footer className="l-anonymous__footer" />

    </div>
  );
};

AnonymousLayout.propTypes = {
  children: PropTypes.node,
  type: PropTypes.string,
  ui: PropTypes.object,
};

const mapStateToProps = (state) => ({
  ui: state.ui.ui,
});

export default connect(mapStateToProps, null)(AnonymousLayout);
