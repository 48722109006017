import { takeLatest } from 'redux-saga/effects';
import SessionStore from '../../../utils/session-store';

const monitoredActions = (action) => {
  return action.type && action.type.includes('LOCATION_CHANGE') && action.payload.action === 'PUSH'
};

function createSaveState() {
  return function* (options) {
    try {
      SessionStore.setLocation(options.payload.location.pathname)
      yield;
    } catch (error) {
      yield;
    }
  };
}

export const saveState = createSaveState();

export function* getLocationChangeWatcher() {
  yield takeLatest(monitoredActions, saveState);
}
