import { takeLatest, put, call } from 'redux-saga/effects';

import { PORTFOLIO_TYPES } from '../../types';

import PortfolioActions from '../../actions/portfolio.actions';
import SessionStore from '../../../utils/session-store';
import SessionContext from '../../../utils/session-context';
import BookbuildService from '../../../api/bookbuild.service';


/**
 * Redux Saga generator function for entity portfolio
 */
function createIOI() {
  return function* (options) {
    try {
      const token = SessionStore.getSession();
      const context = SessionContext.get(token);
      const subsystem = SessionStore.getSubsystem();

      const userId = context.sub;
      const {
        entityId,
        fees,
        currencyIso,
        amount,
        productId,
        agreements,
        sourcesOfWealth,
        integrationIdentifier,
        feesRevision,
        feesTimestamp
      } = options.payload;

      const data = yield call(() => BookbuildService.createEntityIndication(
        token,
        entityId,
        userId,
        amount,
        currencyIso,
        productId,
        fees,
        agreements,
        sourcesOfWealth,
        integrationIdentifier,
        feesRevision,
        feesTimestamp,
        subsystem
        ));

      const successAction = PortfolioActions.createIndicationSuccess(data);

      yield put(successAction);
    } catch (error) {
      const failureAction = PortfolioActions.createIndicationFailure(error);
      yield put(failureAction);
    }
  };
}

export const getCreateIOI = createIOI();

export function* getCreateIOIWatcher() {
  yield takeLatest(PORTFOLIO_TYPES.CREATE_IOI_BEGIN, getCreateIOI);
}
