import PATH from '../paths';

const name = 'APM';
const key = 'apm';
const imgUrl = `${ PATH.CDN }/whitelabels/${ key }`;
const images = {
  favicon: `${ imgUrl }/favicon.png`,
  logo: `${ imgUrl }/logo.png`,
  logoSidebar: `${ imgUrl }/logo-sidebar.png`,
  logoPDF: `${ imgUrl }/logo-pdf.png`,
  opengraph: false,
  backgrounds: [
    `${ imgUrl }/background.jpg`
  ],
};

export default {
  name,
  key,
  images,
  stylesheet: `
    /* COLOR VARIABLES +++++++++++++++++++++++++++++++++++++++++++++++++++++  */


    --color-apm-green:                #38b44a;
    --color-apm-blue:                 #071E2B;
    --color-xusa-blueLight:           #818a91;

    --color-primary:                  var(--color-apm-green);
    --color-secondary:                var(--color-apm-blue);
    --color-accent:                   var(--color-white);

    /* END +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++  */

    /* TYPOGRAPHY ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++  */

    --color-link:                     var(--color-apm-green);
    --color-link-hover:               var(--color-darker);

    /* END +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++  */
   
    /* LAYOUTS +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++  */

    /* ANONYMOUS LAYOUT */

    --anonymous-logo-bg:              var(--color-white);
    --anonymous-copy-bg:              var(--color-apm-blue);
    --anonymous-heading-color:        var(--color-white);
    --anonymous-subheading-color:     var(--color-white);
    --anonymous-text-color            var(--color-white);
    --anonymous-logo-height:          70px;
    --anonymous-bg:                   url(${ images.backgrounds[0] });
    --anonymous-bg-size:              cover;

    /* END */

    /* SIDEBAR */

    --sidebar-bg-color: var(--color-apm-blue);
    --sidebar-logo-bg-color: var(--color-apm-green);
    --sidebar-logo-height: 45px;
    --sidebar-label-color: rgba(var(--color-white-rgb), 0.7);
    --sidebar-border-color: var(--color-xusa-blueLight);

    --sidebar-button-color: var(--color-accent);
    --sidebar-button-color-active: var(--color-white);
    --sidebar-button-color-hover: var(--color-apm-blue);
    --sidebar-button-bg-color: transparent;
    --sidebar-button-bg-color-active: var(--color-apm-green);
    --sidebar-button-bg-color-hover: var(--color-accent);
    --sidebar-button-border-color: var(--color-accent);
    --sidebar-button-border-color-active: var(--color-apm-green);
    --sidebar-button-border-color-hover: var(--color-accent);

    /* END */

    /* END +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++  */

    /* BUTTONS +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++  */

    /* PRIMARY BUTTON */

    --btn-primary--bg:                var(--color-apm-green);
    --btn-primary--border:            var(--color-apm-green);
    --btn-primary--color:             var(--color-white);
    --btn-primary--colorH:            var(--color-white);
    --btn-primary--bgH:               var(--color-apm-blue);
    --btn-primary--borderH:           var(--color-apm-blue);

    /* END */

    /* SECONDARY BUTTON */

    --btn-secondary--bg:              transparent;
    --btn-secondary--border:          var(--color-apm-green);
    --btn-secondary--color:           var(--color-apm-blue);
    --btn-secondary--bgH:             transparent;
    --btn-secondary--borderH:         var(--color-apm-green);
    --btn-secondary--colorH:          var(--color-apm-blue);

    /* END */

    /* END +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++  */
  `,
};
