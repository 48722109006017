// Creates actions in flux standard action format.

class Action {
  /**
   * Creates a new action for use with Redux
   * @example
   * Action.Create('GET_INVESTMENTS', { id: '1234'})
   */
  static create = (actionType, payload = {}, meta = { effects: {} }) => {
    return {
      type: actionType,
      payload,
      meta,
    };
  };

  /**
   * Creates a new action for use with Redux
   * @example
   * Action.createError('GET_INVESTMENTS_FAILURE', { id: '1234'})
   */
  static createError = (actionType, payload = {}, meta = { effects: {} }) => {
    return {
      type: actionType,
      payload,
      meta,
      error: true,
    };
  };

  /**
   * Creates a new action with an effect to allow callbacks from Sagas.
   * @example
   * const action = Action.Create('GET_INVESTMENTS', { id: '1234'})
   * const Action.withEffect(action, Effect.create(...))
   */
  static withEffect(action, effects) {
    return {
      ...action,
      meta: {
        ...action.meta,
        effects,
      },
    };
  }

  /**
   * Creates a new action with an effect to display a friendly display message.
   * @example
   * const action = Action.CreateError('GET_INVESTMENTS_FAILURE', error)
   * const Action.withDisplayMessage(action, 'You suck at computers')
   */
  static withDisplayMessage(action, displayMessage) {
    return {
      ...action,
      meta: {
        ...action.meta,
        displayMessage,
      },
    };
  }
}

export default Action;
