import { takeLatest, put, call, all } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import userService from '../../../api/user.service';
import partnerMappingsService from '../../../api/partner-mapping.service'
import communicationsService from '../../../api/communication-preference.service';
import { SESSION_TYPES } from '../../types';
import SessionActions from '../../actions/session.actions';
import UserActions from '../../actions/user.actions';
import SessionStore from '../../../utils/session-store';
import SessionContext from '../../../utils/session-context';
import Action from '../../actions/helpers/action';

function* tryCreateCommunicationPreferences(jwt, subject, optInMarketingComms, optInOpportunitiesComms) {
  try {
    const body = yield call(() => communicationsService.createCommunicationPreferencesOnSignup(
      jwt,
      subject,
      optInMarketingComms,
      optInOpportunitiesComms));

    return { succeeded: true, data: body };
  } catch (exc) {
    // ignore
  }

  return { succeeded: false };
}

/**
 * Redux Saga generator function for registration
 */
function createSignUp() {
  return function* (options) {
    try {
      const username = options.payload.email;
      const password = options.payload.password;
      const system = process.env.REACT_APP_API_SYSTEM;
      const subsystem = options.payload.subsystem || process.env.REACT_APP_API_SUBSYSTEM;
      const demandPartner = options.payload.demandPartner;
      const channelPartner = options.payload.channelPartner;
      const legacyPartnerCode = options.payload.legacyPartnerCode;
      const campaignCode = options.payload.campaignCode;
      const registrationUri = options.payload.registrationUri;
      const optInMarketingComms = options.payload.receiveMarketing;
      const optInOpportunitiesComms = options.payload.receiveOpportunities;
      const agreements = options.payload.agreements;

      /**
       * Get the partner mapping if only a legacy code is used. If a new link is used, sign the user up with the new
       * codes. This is to prevent locking the user out for 30 days while his invalid legacy code cookie is still
       * active on his browser. (the user will have to contact the affiliate to get the new link)
       */
      if (legacyPartnerCode && !channelPartner && !demandPartner) {
        const pmBody = yield call(() => partnerMappingsService.getByLegacyCode(legacyPartnerCode));
        const pmResult = pmBody.data[0];
        // register a user if a valid mapping exists
        if (pmResult && pmResult.dp_code && pmResult.cp_code) {
          const dpCode = pmResult.dp_code;
          const cpCode = pmResult.cp_code;
          const body = yield call(() => userService.emailRegister(username, password, system, subsystem, dpCode,
            cpCode, campaignCode, registrationUri));
          const result = body.data[0];
          const jwt = result.access_token;
          const context = SessionContext.get(jwt);
          const refresh = result.refresh_token;
          const userId = context.sub;

          SessionStore.setSession(jwt, refresh, subsystem);

          //Get user levels
          yield put(UserActions.getUserLevels(userId));

          //Post Agreements - Needs to happen after jwt has been set by SessionStore.setSession()
          yield put(UserActions.updateUserAgreements(agreements));

          // we don't want to fail registration if these calls don't pass.
          yield all([
            tryCreateCommunicationPreferences(jwt, userId, optInMarketingComms, optInOpportunitiesComms),
          ]);

          const successAction = SessionActions.signupSuccess([], false, username, subsystem);
          yield put(successAction);

          // When no valid mapping exists
        } else {
          const errorMessage = 'Invalid legacy partner code used in the signup process.';
          const failureAction = SessionActions.signupFailure({ errorMessage });

          yield put(Action.withDisplayMessage(failureAction, errorMessage));
        }

        // normal registration
      } else {
        const body = yield call(() => userService.emailRegister(username, password, system, subsystem, demandPartner,
          channelPartner, campaignCode, registrationUri));
        const result = body.data[0];
        const jwt = result.access_token;
        const refresh = result.refresh_token;
        const context = SessionContext.get(jwt);

        SessionStore.setSession(jwt, refresh, subsystem);

        //Get user levels
        yield put(UserActions.getUserLevels(context.sub));

        //Post Agreements - Needs to happen after jwt has been set by SessionStore.setSession()
        yield put(UserActions.updateUserAgreements(agreements));

        // we don't want to fail registration if these calls don't pass.
        yield all([
          tryCreateCommunicationPreferences(jwt, context.sub, optInMarketingComms, optInOpportunitiesComms),
        ]);

        const successAction = SessionActions.signupSuccess([], false, username, subsystem);
        yield put(successAction);
      }
    } catch (error) {
      const ACCOUNT_EXISTS_CODE = 100;
      const failureAction = SessionActions.signupFailure(error);

      if (error.apiErrors && error.apiErrors.some((ele) => ele.code === ACCOUNT_EXISTS_CODE)) {
        const alertMessage = 'The specified account already exists.';
        yield put(Action.withDisplayMessage(failureAction, alertMessage));
      } else {
        yield put(failureAction);
      }
    }
  };
}

export const signUp = createSignUp();

export function* getSignUpWatcher() {
  yield takeLatest(SESSION_TYPES.SIGNUP_BEGIN, signUp);
}
