import React, { useMemo, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import {
  WalletIcon,
  MarketplaceIcon,
  PortfolioIcon,
  UserIcon,
  HelpIcon,
  CloseIcon,
  BurgerIcon,
  SignOutIcon,
  TickIcon,
} from '@wealthmigrate/architect-icons';

import { useOnClickOutside } from '../../hooks';

import wpLogoSrc from '../../images/logo-wealthpoint.svg';

import Link from '../Link';
import Switcher from '../EntitySwitcher';

import { PATH } from '../../../constants';

import './stylesheet.css';

/**
 * Partial for sidebar layout.
 */
const Sidebar = ({
  ui,
  entities = [],
  activeEntity = undefined,
  lister,
  className = '',
  switchActiveEntity,
}) => {

  const [isNavOpen, setNavState] = useState(false);
  const [hasIssues, setIssues] = useState(false);

  const ref = useRef();

  useOnClickOutside(ref, () => setNavState(false));

  useMemo(() => {
    const entityFilter = entities.filter((i,k) => i.kyc_status !== 'KYC Approved');
    if (entityFilter.length > 0 && !hasIssues) {
      return setIssues(true);
    };
    if (entityFilter.length === 0 && hasIssues) {
      return setIssues(false);
    };
    return null;
  }, [entities, hasIssues]);


  return (
    <aside className={ `c-sidebar ${ className }` }>

      <header className="c-sidebar__logo">
        <NavLink
          to={ PATH.MARKETPLACE }
        >
          {
            ui.images && ui.images.logoSidebar ? <img src={ `${ ui.images.logoSidebar }` } alt={ `${ ui.name } - powered by WealthPoint` } /> : null
          }
        </NavLink>
      </header>

      <nav className={ `c-sidebar__nav ${ isNavOpen ? 'c-sidebar__nav--active' : '' }`} ref={ ref }>
        <ul className="u-list-vertical c-sidebar__nav__main">
          <li>
            <button
              className="p-icon-button p-icon-button--burger"
              onClick={ () => isNavOpen ? setNavState(false) : setNavState(true) }
            >
              { isNavOpen ? <CloseIcon /> : <BurgerIcon /> }
            </button>
          </li>
          { activeEntity && (
            <li className="c-sidebar__nav--mobile-link">
              <Switcher
                entities={ entities }
                activeEntity={ activeEntity }
                switchActiveEntity={ (entity) => switchActiveEntity(entity) }
              />
            </li>
          ) }
          <li data-pendo="sidebar-marketplace">
            <Link
              to={ PATH.MARKETPLACE }
              className="p-icon-button p-icon-button--sidebar"
            >
              <MarketplaceIcon />
              <small>Marketplace</small>
            </Link>
            <small>Marketplace</small>
          </li>
          <li data-pendo="sidebar-portfolio">
            <Link
              to={ PATH.PORTFOLIO_DETAIL('usd') }
              className="p-icon-button p-icon-button--sidebar"
            >
              <PortfolioIcon />
              <small>Portfolio</small>
            </Link>
            <small>Portfolio</small>
          </li>
        {
          (lister?.use_wallets === true || lister?.use_wallets === undefined) && (
            <li data-pendo="sidebar-wallets">
              <Link
                to={ PATH.WALLET_DETAIL( !lister?.preferred_currency_iso ? 'usd' : lister?.preferred_currency_iso.sort()?.[0]?.toLowerCase())}
                className="p-icon-button p-icon-button--sidebar"
              >
                <WalletIcon />
                <small>Wallets</small>
              </Link>
              <small>Wallets</small>
            </li>
            )
          }
          <li data-pendo="sidebar-manage-profile">
            <Link
              to={ PATH.PROFILE_DETAIL(activeEntity?._id) }
              className="p-icon-button p-icon-button--sidebar"
            >
              { hasIssues ? <i className="u-badge u-badge--red">!</i> : <i  className="u-badge u-badge--green"><TickIcon /></i> }
              <UserIcon />
              <small>Manage Profile</small>
            </Link>
            <small>Manage Profile</small>
          </li>
        </ul>
        <ul className="u-list-vertical c-sidebar__nav__system">
          <li data-pendo="sidebar-education">
            <Link
              to={ PATH.RESOURCES }
              target="_blank"
              rel="noopener noreferrer"
              className="p-icon-button p-icon-button--sidebar-secondary"
            >
              <HelpIcon />
              <small>Help</small>
            </Link>
            <small>Help</small>
          </li>
          <li data-pendo="sidebar-signout">
            <Link
              to={ PATH.LOGOUT }
              className="p-icon-button p-icon-button--sidebar-secondary"
            >
              <SignOutIcon />
              <small>Sign Out</small>
            </Link>
            <small>Sign Out</small>
          </li>
        </ul>

      </nav>

      <button
        className="p-icon-button p-icon-button--burger"
        onClick={ () => isNavOpen ? setNavState(false) : setNavState(true) }
      >
        { isNavOpen ? <CloseIcon /> : <BurgerIcon /> }
      </button>

      

      <figure className="c-sidebar__footer">
        <small>powered by</small>
        <img src={ wpLogoSrc } alt="Powered by Wealthpoint" />
      </figure>

    </aside>
  );
};

Sidebar.propTypes = {
  ui: PropTypes.object,
  entities: PropTypes.array,
  activeEntity: PropTypes.object,
  lister: PropTypes.object,
  switchActiveEntity: PropTypes.func,
};

export default Sidebar;
