export const USER_TYPES = {
  GET_ENTITIES_BEGIN: 'GET_ENTITIES_BEGIN',
  GET_ENTITIES_FAILURE: 'GET_ENTITIES_FAILURE',
  GET_ENTITIES_SUCCESS: 'GET_ENTITIES_SUCCESS',

  CREATE_PERSONAL_ENTITY_BEGIN: 'CREATE_PERSONAL_ENTITY_BEGIN',
  CREATE_PERSONAL_ENTITY_FAILURE: 'CREATE_PERSONAL_ENTITY_FAILURE',
  CREATE_PERSONAL_ENTITY_SUCCESS: 'CREATE_PERSONAL_ENTITY_SUCCESS',

  CREATE_COMPANY_ENTITY_BEGIN: 'CREATE_COMPANY_ENTITY_BEGIN',
  CREATE_COMPANY_ENTITY_FAILURE: 'CREATE_COMPANY_ENTITY_FAILURE',
  CREATE_COMPANY_ENTITY_SUCCESS: 'CREATE_COMPANY_ENTITY_SUCCESS',

  //TODO: Refactor - Move Bank accounts to their own TYPES/ACTIONS/REDUCERS

  CREATE_BANK_ACCOUNT_BEGIN: 'CREATE_BANK_ACCOUNT_BEGIN',
  CREATE_BANK_ACCOUNT_FAILURE: 'CREATE_BANK_ACCOUNT_FAILURE',
  CREATE_BANK_ACCOUNT_SUCCESS: 'CREATE_BANK_ACCOUNT_SUCCESS',

  /**
   * Used for when user tries to upload bank statement when bank account creation is still in progress.
   * ie. when no bank_account._id is present to pass as the associated id for the document uploaded
   */
  ASSOCIATE_BANK_STATEMENT_BEGIN: 'ASSOCIATE_BANK_STATEMENT_BEGIN',
  ASSOCIATE_BANK_STATEMENT_FAILURE: 'ASSOCIATE_BANK_STATEMENT_FAILURE',
  ASSOCIATE_BANK_STATEMENT_SUCCESS: 'ASSOCIATE_BANK_STATEMENT_SUCCESS',

  GET_BANK_ACCOUNT_BEGIN: 'GET_BANK_ACCOUNT_BEGIN',
  GET_BANK_ACCOUNT_FAILURE: 'GET_BANK_ACCOUNT_FAILURE',
  GET_BANK_ACCOUNT_SUCCESS: 'GET_BANK_ACCOUNT_SUCCESS',
  
  UPDATE_BANK_ACCOUNT_BEGIN: 'UPDATE_BANK_ACCOUNT_BEGIN',
  UPDATE_BANK_ACCOUNT_FAILURE: 'UPDATE_BANK_ACCOUNT_FAILURE',
  UPDATE_BANK_ACCOUNT_SUCCESS: 'UPDATE_BANK_ACCOUNT_SUCCESS',

  UPDATE_PERSONAL_ENTITY_BEGIN: 'UPDATE_PERSONAL_ENTITY_BEGIN',
  UPDATE_PERSONAL_ENTITY_FAILURE: 'UPDATE_PERSONAL_ENTITY_FAILURE',
  UPDATE_PERSONAL_ENTITY_SUCCESS: 'UPDATE_PERSONAL_ENTITY_SUCCESS',

  VERIFICATION_EMAIL_SEND_BEGIN: 'VERIFICATION_EMAIL_SEND_BEGIN',
  VERIFICATION_EMAIL_SEND_FAILURE: 'VERIFICATION_EMAIL_SEND_FAILURE',
  VERIFICATION_EMAIL_SEND_SUCCESS: 'VERIFICATION_EMAIL_SEND_SUCCESS',

  UPDATE_USER_AGREEMENTS_BEGIN : 'UPDATE_USER_AGREEMENTS_BEGIN',
  UPDATE_USER_AGREEMENTS_SUCCESS : 'UPDATE_USER_AGREEMENTS_SUCCESS',
  UPDATE_USER_AGREEMENTS_FAILURE : 'UPDATE_USER_AGREEMENTS_FAILURE',

  GET_USER_AGREEMENTS_BEGIN : 'GET_USER_AGREEMENTS_BEGIN',
  GET_USER_AGREEMENTS_SUCCESS : 'GET_USER_AGREEMENTS_SUCCESS',
  GET_USER_AGREEMENTS_FAILURE : 'GET_USER_AGREEMENTS_FAILURE',

  GET_CAN_INVEST_BEGIN: 'GET_CAN_INVEST_BEGIN',
  GET_CAN_INVEST_FAILURE: 'GET_CAN_INVEST_FAILURE',
  GET_CAN_INVEST_SUCCESS: 'GET_CAN_INVEST_SUCCESS',

  GET_USER_LEVELS_BEGIN: 'GET_USER_LEVELS_BEGIN',
  GET_USER_LEVELS_FAILURE: 'GET_USER_LEVELS_FAILURE',
  GET_USER_LEVELS_SUCCESS: 'GET_USER_LEVELS_SUCCESS',

  SELECT_ACTIVE_ENTITY: 'SELECT_ACTIVE_ENTITY',

  CREATE_NON_PERSONAL_ENTITY_BEGIN: 'CREATE_NON_PERSONAL_ENTITY_BEGIN',
  CREATE_NON_PERSONAL_ENTITY_SUCCESS: 'CREATE_NON_PERSONAL_ENTITY_SUCCESS',

  DISPLAY_DEFAULT_STEP_BEGIN: 'DISPLAY_DEFAULT_STEP_BEGIN',
  DISPLAY_DEFAULT_STEP_SUCCESS: 'DISPLAY_DEFAULT_STEP_SUCCESS',
};

