import 'isomorphic-fetch';
import { fetchResource } from './fetch-resource';

import { API } from '../constants';

/**
 * Product API service
 */
class WalletTransactionsService {
  /**
   * Retrieves wallet transactions for a specific entity by specified currency
   * @example
   * getProductsForListing('someJwt').then(res => { return res.data }).then(...)
   * @returns {Promise} Returns a promise containing the result in json format.
   */
  static getTransactions = (jwt) => {
    const requestConfig = {
      cache: 'no-cache',
      mode: 'cors',
      headers: {
        'content-type': 'application/json',
        'authorization': `bearer ${ jwt }`,
      },
      method: 'GET',
    };

    return fetchResource(`${ API.WALLETSTRANSACTIONS }?limit=1000&sort=dsc`, requestConfig); //temp increasing limit until we've implemented pagination on the FE
  }

  static getTransactionsByEntityID = (jwt, entity_id) => {
    const requestConfig = {
      cache: 'no-cache',
      mode: 'cors',
      headers: {
        'content-type': 'application/json',
        'authorization': `bearer ${ jwt }`,
      },
      method: 'GET',
    };

    return fetchResource(`${ API.WALLETSTRANSACTIONS }?entity=${ entity_id }&limit=1000&sort=dsc`, requestConfig); //temp increasing limit until we've implemented pagination on the FE
  }

}

export default WalletTransactionsService;
