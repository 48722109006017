import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Section from '@traaidmark/konstruct-bucket-section';

import {
  Warning
} from '../../components/Notifications';


import UserActions from '../../../redux/actions/user.actions';
import KYCActions from '../../../redux/actions/kyc.actions';

const LayoutMessages = ({ requestVerifyEmail, activeEntity, countryFields, getCountryFields, loading, domainName, lister, pathname }) => {

  const entityBankAccount = activeEntity?.content?.bank_account
  useMemo(() => {
    if (entityBankAccount?.address?.country) {
      getCountryFields(entityBankAccount?.address?.country)
    }
  }, [entityBankAccount?.address?.country, getCountryFields])
  
  const resetEmail = !!activeEntity && activeEntity.email && activeEntity.email_confirmation_date === 0;

  const countryField = countryFields?.find(c => c.iso_2_code === entityBankAccount?.address?.country)
  const updateBankingDetails = !loading && activeEntity?.kyc_status?.toLowerCase() === 'kyc approved' &&
    ((countryField?.expect_iban ? !entityBankAccount?.iban?.length > 0 : false) || (countryField?.expect_routing_num ? !entityBankAccount?.branch_code?.length > 0 : false));
  const kycApprovedAndWalletSubsystem = !loading && (pathname?.toLowerCase()?.includes("wallet") ||  pathname?.toLowerCase()?.includes("marketplace")) &&
    activeEntity?.kyc_status?.toLowerCase() === 'kyc approved' && lister?.use_wallets === true //used for LW banking details update
    if (!resetEmail && !updateBankingDetails && !kycApprovedAndWalletSubsystem) return null;

    return (
      <Section
        className="b-section--noBottom"
      >
        {
          resetEmail && !pathname?.toLowerCase().includes('profile/new') && (
            <Warning>
              <p><>Please verify your email. <a role="button" onClick={() => requestVerifyEmail(activeEntity.email, domainName)}>Click here to resend.</a></></p>
            </Warning>
          )
        }
        {
          updateBankingDetails && (
            <Warning>
              <p>Depending on your bank country selected, Lemon Way, our payment service provider, now requires additional banking information in order to process a withdrawal. Please update your banking information under Step 4 of your profile and simply click on Get Verified on Step 5 to submit your bank details.</p>
            </Warning>
          )
        }
      </Section>
    );
}

LayoutMessages.propTypes = {
  activeEntity: PropTypes.object,
  requestVerifyEmail: PropTypes.func,
  countryFields: PropTypes.array,
  getCountryFields: PropTypes.func,
  loading: PropTypes.bool,
  lister: PropTypes.object,
  pathname: PropTypes.string,
};

const mapStateToProps = (state) => ({
  loading: state.kyc.loading,
  activeEntity: state.user.active_entity,
  countryFields: state.kyc.countryFields,
  domainName: state?.ui?.ui?.domainName,
  listerStatus: state.lister.status,
  lister: state.lister.data?.[0],
  pathname: state?.router?.location?.pathname
});

const mapDispatchToProps = (dispatch) => {
  return ({
    requestVerifyEmail: (email, domainName) => { dispatch(UserActions.sendVerificationEmail(email, domainName)); },
    getCountryFields: (iso2Code) => { dispatch(KYCActions.getByISO2Code(iso2Code)); },
  });
};

export default connect(mapStateToProps, mapDispatchToProps)(LayoutMessages);
