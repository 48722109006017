import { Map } from "immutable";
import state from "../sagas/state";

import { USER_TYPES } from "../types/user.types";

const initialState = {
  loading: false,
  entities: [],
  user_levels: undefined,
  active_entity: undefined,
  bank_accounts: [],
  can_invest: undefined,
  new_non_personal_entity: false,
  display_default_step: true,
  agreements: undefined,
};

const actionsMap = {
  [USER_TYPES.CREATE_PERSONAL_ENTITY_BEGIN]: (state) => ({
    ...state,
    loading: true,
    error: undefined,
  }),
  [USER_TYPES.CREATE_PERSONAL_ENTITY_FAILURE]: (state, action) => ({
    ...state,
    loading: false,
    error: action.payload.error,
  }),
  [USER_TYPES.CREATE_PERSONAL_ENTITY_SUCCESS]: (state, action) => {
    const entities = state.entities;
    const new_entities =
      entities && entities.length > 0
        ? [...entities, action.payload.entity]
        : [action.payload.entity]; //eslint-disable-line

    return {
      ...state,
      loading: false,
      entities: new_entities,
      active_entity: action.payload.entity,
    };
  },
  [USER_TYPES.GET_CAN_INVEST_BEGIN]: (state) => ({
    ...state,
    loading: true,
  }),
  [USER_TYPES.GET_CAN_INVEST_FAILURE]: (state, action) => ({
    ...state,
    loading: false,
    error: action.payload.error,
  }),
  [USER_TYPES.GET_CAN_INVEST_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    can_invest: action.payload.canInvest,
  }),
  [USER_TYPES.GET_USER_LEVELS_BEGIN]: (state) => ({
    ...state,
    loading: true,
  }),
  [USER_TYPES.GET_USER_LEVELS_FAILURE]: (state, action) => ({
    ...state,
    loading: false,
    error: action.payload.error,
  }),
  [USER_TYPES.GET_USER_LEVELS_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    user_levels: action.payload.userLevels,
  }),
  [USER_TYPES.CREATE_COMPANY_ENTITY_BEGIN]: (state) => ({
    ...state,
    loading: true,
  }),
  [USER_TYPES.CREATE_COMPANY_ENTITY_FAILURE]: (state, action) => ({
    ...state,
    loading: false,
    error: action.payload.error,
  }),
  [USER_TYPES.CREATE_COMPANY_ENTITY_SUCCESS]: (state, action) => {
    const entities = state.entities;
    const new_entities =
      entities && entities.length > 0
        ? [...entities, action.payload.entity]
        : [action.payload.entity]; //eslint-disable-line

    return {
      ...state,
      loading: false,
      entities: new_entities,
      active_entity: action.payload.entity,
    };
  },
  [USER_TYPES.GET_ENTITIES_BEGIN]: (state) => ({
    ...state,
    loading: true,
  }),
  [USER_TYPES.GET_ENTITIES_FAILURE]: (state, action) => ({
    ...state,
    loading: false,
    error: action.payload.error,
  }),
  [USER_TYPES.GET_ENTITIES_SUCCESS]: (state, action) => ({
    ...state,
    active_entity: action.payload.entities.find(e => e?._id === state.active_entity?._id) || action.payload.entities[0],
    entities: action.payload.entities,
    loading: false,
  }),
  [USER_TYPES.UPDATE_PERSONAL_ENTITY_BEGIN]: (state) => ({
    ...state,
    loading: true,
    error: undefined,
  }),
  [USER_TYPES.UPDATE_PERSONAL_ENTITY_FAILURE]: (state, action) => ({
    ...state,
    loading: false,
    error: action.payload.error,
  }),
  [USER_TYPES.UPDATE_PERSONAL_ENTITY_SUCCESS]: (state, action) => {
    const updatedEntities = () => {
      const entities = state.entities;
      if (entities && entities.length > 0) {
        const filteredList = entities?.filter(
          (entity) => entity._id !== action.payload.entity._id
        ); // eslint-disable-line
        return [...filteredList, action.payload.entity];
      }
      return [action.payload.entity];
    };

    return {
      ...state,
      active_entity: action.payload.entity,
      entities: updatedEntities(),
      loading: false,
    };
  },

  [USER_TYPES.GET_USER_AGREEMENTS_BEGIN]: (state) => ({
    ...state,
    loading: true,
  }),
  [USER_TYPES.GET_USER_AGREEMENTS_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    agreements: action.payload.agreements,
  }),
  [USER_TYPES.GET_USER_AGREEMENTS_FAILURE]: (state, action) => ({
    ...state,
    loading: true,
    error: action.payload.error,
  }),
  [USER_TYPES.UPDATE_USER_AGREEMENTS_BEGIN]: (state) => ({
    ...state,
    loading: true,
  }),
  [USER_TYPES.UPDATE_USER_AGREEMENTS_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    agreements: action.payload.agreements,
  }),
  [USER_TYPES.UPDATE_USER_AGREEMENTS_FAILURE]: (state, action) => ({
    ...state,
    loading: false,
    error: action.payload.error,
  }),
  [USER_TYPES.VERIFICATION_EMAIL_SEND_BEGIN]: (state) => ({
    ...state,
    loading: true,
  }),
  [USER_TYPES.VERIFICATION_EMAIL_SEND_FAILURE]: (state, action) => ({
    ...state,
    loading: false,
    error: action.payload.error,
  }),
  [USER_TYPES.VERIFICATION_EMAIL_SEND_SUCCESS]: (state) => ({
    ...state,
    loading: false,
  }),
  [USER_TYPES.CREATE_BANK_ACCOUNT_BEGIN]: (state) => ({
    ...state,
    loading: true,
  }),
  [USER_TYPES.CREATE_BANK_ACCOUNT_FAILURE]: (state, action) => ({
    ...state,
    loading: false,
    error: action.payload.error,
  }),
  [USER_TYPES.CREATE_BANK_ACCOUNT_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    bank_accounts: [...state.bank_accounts, action.payload.bankAccount],
  }),
  [USER_TYPES.GET_BANK_ACCOUNT_BEGIN]: (state) => ({
    ...state,
    loading: true,
  }),
  [USER_TYPES.GET_BANK_ACCOUNT_FAILURE]: (state, action) => ({
    ...state,
    loading: false,
    error: action.payload.error,
  }),
  [USER_TYPES.GET_BANK_ACCOUNT_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    bank_accounts: action.payload.bankAccounts,
  }),
  [USER_TYPES.UPDATE_BANK_ACCOUNT_BEGIN]: (state) => ({
    ...state,
    loading: true,
  }),
  [USER_TYPES.UPDATE_BANK_ACCOUNT_FAILURE]: (state, action) => ({
    ...state,
    loading: false,
    error: action.payload.error,
  }),
  [USER_TYPES.UPDATE_BANK_ACCOUNT_SUCCESS]: (state, action) => {
    // state?.bank_accounts?.filter(b => b?._id !== action.payload?.bankAccount?._id)
    return {
      ...state,
      loading: false,
      bankAccounts: [
        ...state?.bank_accounts?.filter(
          (b) => b?._id !== action.payload?.bankAccount?._id
        ),
        action.payload?.bankAccount,
      ],
    };
  },
  [USER_TYPES.SELECT_ACTIVE_ENTITY]: (state, action) => ({
    ...state,
    active_entity: action.payload.entity,
  }),
  [USER_TYPES.CREATE_NON_PERSONAL_ENTITY_BEGIN]: (state, action) => ({
    ...state,
    new_non_personal_entity: true,
  }),
  [USER_TYPES.CREATE_NON_PERSONAL_ENTITY_SUCCESS]: (state, action) => ({
    ...state,
    new_non_personal_entity: false,
  }),
  [USER_TYPES.DISPLAY_DEFAULT_STEP_BEGIN]: (state, action) => ({
    ...state,
    display_default_step: true,
  }),
  [USER_TYPES.DISPLAY_DEFAULT_STEP_SUCCESS]: (state, action) => ({
    ...state,
    display_default_step: false,
  }),
};

export default function reducer(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
