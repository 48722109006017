import { Map } from 'immutable';
import { PLEDGE_TYPES } from '../types';

import { PORTFOLIO_TYPES } from '../types/portfolio.types';

/**
 * Redux Reducer for portfolios
 */

const initialState = {
  loading: false,
  portfolios: [],
};

const actionsMap = {
  [PORTFOLIO_TYPES.GET_PORTFOLIO_BEGIN]: (state) =>
    ({
      ...state,
      loading: true,
      error: undefined,
    }),
  [PORTFOLIO_TYPES.GET_PORTFOLIO_FAILURE]: (state, action) =>
    ({
      ...state,
      loading: false,
      error: action.payload.error,
    }),
  [PORTFOLIO_TYPES.GET_PORTFOLIO_SUCCESS]: (state, action) =>
  {
      return {
      ...state,
      portfolios: [...action.payload.portfolios],
      loading: false,
      error: undefined,
    }},
  [PORTFOLIO_TYPES.CREATE_IOI_ERROR]: (state, action) =>
    ({
      ...state,
      loading: false,
      error: action.payload.error,
    }),
  [PORTFOLIO_TYPES.CREATE_IOI_CLEAR_ERROR]: (state) =>
    ({
      ...state,
      loading: false,
      error: undefined,
    }),
  [PORTFOLIO_TYPES.CREATE_IOI_BEGIN]: (state) =>
    ({
      ...state,
      loading: true,
      error: undefined,
    }),
  [PORTFOLIO_TYPES.CREATE_IOI_SUCCESS]: (state) =>
    ({
      ...state,
      loading: false,
      error: undefined,
    }),
  [PORTFOLIO_TYPES.CANCEL_IOI_FAILURE]: (state, action) =>
    ({
      ...state,
      loading: false,
      error: action.payload.error,
    }),
  [PORTFOLIO_TYPES.CANCEL_IOI_BEGIN]: (state) =>
    ({
      ...state,
      loading: true,
      error: undefined,
    }),
  [PORTFOLIO_TYPES.CANCEL_IOI_SUCCESS]: (state, action) => {
    const portfolio = state.portfolios.find(p => p.indications.find(i => i?._id === action.payload?.indicationId))
    const indications = portfolio?.indications;
    const newindications = indications.filter(i => i?._id !== action.payload?.indicationId)
    const newPortfolio = {...portfolio, indications: newindications}
    const newPortfolios = [...state.portfolios.filter(p => p.type !== portfolio.type), newPortfolio]

    return {
      ...state,
      loading: false,
      error: undefined,
      indications: state.indications?.filter(i => i._id !== action.payload?.indicationId),
      portfolios: [...newPortfolios.filter(p => p.type !== 'indie').sort((a, b) => a?.type - b?.type), ...newPortfolios.filter(p => p.type === 'indie')]
    }
  },

    //Bringing PLEDGE Delete in here
    [PLEDGE_TYPES.DELETE_DEPOSIT_REFERENCE_SUCCESS]: (state, action) => {

      const indiePortfolio = state.portfolios.find(p => p.type === 'indie')
      const deposit_references = indiePortfolio?.pledges; // eslint-disable-line
      const new_deposit_references = deposit_references && deposit_references.length > 0 ? // eslint-disable-line
        deposit_references?.filter(d => d._id !== action.payload.depositReference._id) : [action.payload.depositReference]; // eslint-disable-line
      const newIndiePortfolio = {...state.portfolios.find(p => p.type === 'indie'), pledges: new_deposit_references}
      const result = [...state.portfolios.filter(p => p.type !== 'indie').sort((a, b) => a?.type - b?.type), newIndiePortfolio]
      return {
        ...state,
        loading: false,
        portfolios: result,
      };
    },
}

export default function reducer(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
