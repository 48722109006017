import PATH from '../paths';

const name = 'Wealth Migrate Focus';
const key = 'focus';
const imgUrl = `${ PATH.CDN }/whitelabels/global`;
const images = {
  favicon: `${ imgUrl }/favicon.png`,
  logo: `${ imgUrl }/logo.png`,
  logoPDF: `${ imgUrl }/logo-pdf.png`,
  logoSidebar: `${ imgUrl }/logo.png`,
};

export default {
  name,
  key,
  images
};
