import { takeLatest, put, call } from 'redux-saga/effects';
import { DEMAND_PARTNERS_TYPES } from '../../types';
import DemandPartnersActions from '../../actions/demand-partners.actions';
import SessionStore from '../../../utils/session-store';
import demandPartnerService from '../../../api/demand-partner.service';

/**
 * Redux Saga generator function for getting demandPartners
 */
function createGetDemandPartners() {
  return function* (options) { // eslint-disable-line
    try {
      const token = SessionStore.getSession();

      const demandPartners = yield call(() => demandPartnerService.getAll(token));
      const action = DemandPartnersActions.success(demandPartners.data);

      yield put(action);
    } catch (error) {
      const failureAction = DemandPartnersActions.error(error);

      yield put(failureAction);
    }
  };
}

export const getDemandPartners = createGetDemandPartners();

export function* getDemandPartnersWatcher() {
  yield takeLatest(DEMAND_PARTNERS_TYPES.GET_ALL_DEMAND_PARTNERS, getDemandPartners);
}

export default [
  getDemandPartnersWatcher(),
];
