import { PLEDGE_TYPES } from '../types';
import Action from './helpers/action';

export default class PledgeActions {
  // Generate a deposit Reference
  static generateDepositReference = (productId, amount, entityId, currency, baseFee, structureFee, marketingFee, blocktradeFee, agreements, sourcesOfWealth, feesRevision, feesTimestamp) => {
    return Action.create(PLEDGE_TYPES.GENERATE_DEPOSIT_REFERENCE_BEGIN, {
      productId, amount, entityId, currency, baseFee, structureFee, marketingFee, blocktradeFee, agreements, sourcesOfWealth, feesRevision, feesTimestamp
    });
  }

  static generateDepositReferenceSuccess = (depositReference) => {
    return Action.create(PLEDGE_TYPES.GENERATE_DEPOSIT_REFERENCE_SUCCESS, { depositReference });
  }

  static generateDepositReferenceFailure = (errorObj) => {
    return Action.create(PLEDGE_TYPES.GENERATE_DEPOSIT_REFERENCE_FAILURE, { error: errorObj });
  }

  // update Deposit Reference Amount
  static updateDepositReference = (referenceId, amount, baseFee, structureFee, capFee, blocktradeFee) => {
    return Action.create(PLEDGE_TYPES.UPDATE_DEPOSIT_REFERENCE_BEGIN, {
      referenceId, amount, baseFee, structureFee, capFee, blocktradeFee
    });
  }

  static updateDepositReferenceSuccess = (depositReference) => {
    return Action.create(PLEDGE_TYPES.UPDATE_DEPOSIT_REFERENCE_SUCCESS, { depositReference });
  }

  static updateDepositReferenceFailure = (errorObj) => {
    return Action.create(PLEDGE_TYPES.UPDATE_DEPOSIT_REFERENCE_FAILURE, { error: errorObj });
  }

  // delete Deposit Reference
  static deleteDepositReference = (referenceId) => {
    return Action.create(PLEDGE_TYPES.DELETE_DEPOSIT_REFERENCE_BEGIN, { referenceId });
  }

  static deleteDepositReferenceSuccess = (depositReference) => { // Return the deleted reference so we can remove it from redux in the reducer
    return Action.create(PLEDGE_TYPES.DELETE_DEPOSIT_REFERENCE_SUCCESS, { depositReference });
  }

  static deleteDepositReferenceFailure = (errorObj) => {
    return Action.create(PLEDGE_TYPES.DELETE_DEPOSIT_REFERENCE_FAILURE, { error: errorObj });
  }

  // Get Deposit references specific to the user

  static getDepositReferences = () => {
    return Action.create(PLEDGE_TYPES.GET_DEPOSIT_REFERENCES_BEGIN, { });
  }

  static getDepositReferencesSuccess = (depositReferences) => {
    return Action.create(PLEDGE_TYPES.GET_DEPOSIT_REFERENCES_SUCCESS, { depositReferences });
  }

  static getDepositReferencesFailure = (errorObj) => {
    return Action.create(PLEDGE_TYPES.GET_DEPOSIT_REFERENCES_FAILURE, { error: errorObj });
  }
}
