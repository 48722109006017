import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Loadable from 'react-loadable';
import Loader from '@traaidmark/konstruct-part-loader';

import UIActions from '../../../redux/actions/ui.actions';

const WalletFund = Loadable({
  loader: () => import('../../tasks/WalletFund'),
  loading: Loader,
});
const WalletWithdraw = Loadable({
  loader: () => import('../../tasks/WalletWithdraw'),
  loading: Loader,
});
const Invest = Loadable({
  loader: () => import('../../tasks/Invest'),
  loading: Loader,
});
const DealCancel = Loadable({
  loader: () => import('../../tasks/DealCancel'),
  loading: Loader,
});

const TaskContainer = (props) => {

  switch (props.taskName) {
    case 'wallet_fund':
      return <WalletFund />;
    case 'wallet_withdraw':
      return <WalletWithdraw />;
    case 'invest':
      return <Invest />;
    case 'deal-cancel':
      return (
        <DealCancel
          step={ props.taskStep }
          product={ props.taskData.product }
          navigateToStep={ (stepNumber, product) => props.goToPledgeCancelStep(stepNumber, product) }
        />
      );
    default:
      return null;
  }
};

TaskContainer.propTypes = {
  isVisible: PropTypes.bool,
  taskName: PropTypes.string,
  taskStep: PropTypes.number,
  taskData: PropTypes.object,
  goToPledgeCancelStep: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => {
  return ({
    goToPledgeCancelStep: (step, product, investmentAmount) => {
      return dispatch(UIActions.toggleTask(
        {
          drawer: false,
          task: true,
          taskName: 'pledge-cancel',
          taskStep: step,
          taskData: { product, investmentAmount },
        }
      ));
    },
  });
};

const mapStateToProps = (state) => ({
  taskName: state.ui.taskName,
  taskStep: state.ui.taskStep,
  taskData: state.ui.taskData,
  task: state.ui.task,
});

export default connect(mapStateToProps, mapDispatchToProps)(TaskContainer);

