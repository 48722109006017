import { Map } from 'immutable';

import { COUNTRIES_TYPES } from '../types/countries.types';

/**
 * Redux Reducer for countries
 */

const initialState = {
  items: [],
  loading: false,
};

const actionsMap = {
  [COUNTRIES_TYPES.GET_ALL_COUNTRIES]: (state) =>
    ({
      ...state,
      loading: true,
      error: undefined,
    }),
  [COUNTRIES_TYPES.ERROR]: (state, action) =>
    ({
      ...state,
      loading: false,
      error: action.payload.error,
    }),
  [COUNTRIES_TYPES.GET_ALL_COUNTRIES_SUCCESS]: (state, action) =>
    ({
      ...state,
      items: action.payload.countries,
      loading: false,
    }),
};

export default function reducer(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
