import 'isomorphic-fetch';
import { fetchResource } from './fetch-resource';

import { API } from '../constants';

/**
 * Product API service
 */
class DocumentService {
  /**
   * Retrieves documents for listing on product profile page
   * @example
   * getDocumentsForListing('someJwt', 'id').then(res => { return res.data }).then(...)
   * @returns {Promise} Returns a promise containing the result in json format.
   */
  static getDocumentsForListing = (jwt, associatedId) => {
    const requestConfig = {
      cache: 'no-cache',
      mode: 'cors',
      headers: {
        'content-type': 'application/json',
        'authorization': `bearer ${ jwt }`,
      },
      method: 'GET',
    };

    return fetchResource(`${ API.DOCUMENTS }?associated_id=${ associatedId }&limit=5000`, requestConfig);
  }

  /**
   * Retrieves multiple documents based on the ids specified
   * @example
   * getDocumentsById('someJwt', ['95614388-dc35-40bf-b59d-922c6db3200e', '95614388-dc35-40bf-b59d-922c6db3200e']).then(res => { return res.data }).then(...)
   * @returns {Promise} Returns a promise containing the result in json format.
   */
  static getDocumentsById = (jwt, ids) => {
    const requestConfig = {
      cache: 'no-cache',
      mode: 'cors',
      headers: {
        'content-type': 'application/json',
        'authorization': `bearer ${ jwt }`,
      },
      method: 'GET',
    };

    const idList = JSON.stringify(ids).replace(/\s+|\[|\]|"/g, '');

    return fetchResource(`${ API.DOCUMENTS }?_id=(${ idList })&limit=5000`, requestConfig);
  }

  /**
   * Uploads a file
   * @example
   * @returns {Promise} Returns a promise containing the result in json format.
   */
  static uploadFile = (jwt, file, associated_id, tags, country_of_issue, encrypted = true, render_inline = false) => { //eslint-disable-line
    const formData = new FormData();

    formData.append('file', file);

    const requestConfig = {
      cache: 'no-cache',
      mode: 'cors',
      headers: {
        'Authorization': `bearer ${ jwt }`,
        'associated_id': associated_id,
        'encrypted': encrypted,
        'render_inline': render_inline,
        'tags': tags,
        country_of_issue
      },
      method: 'POST',
      body: formData,
    };

    return fetchResource(`${ API.DOCUMENTS }`, requestConfig);
  }

  static getTransactionDocumentById = (jwt, entityId, currency, startDate, endDate, timezoneOffset) => {
    const body = {
      entity_id: entityId,
      currency_iso: currency,
      start_date: startDate,
      end_date: endDate,
      timezone_offset: timezoneOffset
    }
    
    const requestConfig = {
      cache: 'no-cache',
      mode: 'cors',
      headers: {
        'Authorization': `Bearer ${ jwt }`,
        'Content-Type': 'application/json'        
      },
      method: 'POST',
      body: JSON.stringify(body),
    };

    return fetchResource(`${ API.RENDER_DOCUMENTS }/transaction_statement/pdf`, requestConfig);
  }

  static getProductInfoDocument = (jwt, productId, subsystem, subsystemHost, minimum_investment, initiation_fee_rate,
    subsystemLogo, irr, coc, cap_return_rate,cap_return_succ_fee_rate, success_fee_rate) => {
    const body = {
      subsystem,
      subsystemHost: `https://${subsystemHost}`,
      minimum_investment,
      initiation_fee_rate,
      subsystem_logo: subsystemLogo,
      irr,
      coc,
      cap_return_rate,
      cap_return_succ_fee_rate,
      success_fee_rate
    }

    const requestConfig = {
      cache: 'no-cache',
      mode: 'cors',
      headers: {
        'Authorization': `Bearer ${ jwt }`,
        'Content-Type': 'application/json'
      },
      method: 'POST',
      body: JSON.stringify(body),
    };

    return fetchResource(`${ API.RENDER_DOCUMENTS }/product_info/${ productId }/pdf`, requestConfig);
  }

  /**
   * deletes a document.
   * @example
   * @returns {Promise} Returns a promise containing the result in json format.
   */
  static deleteDocument = (jwt, documentId) => {
    const requestConfig = {
      cache: 'no-cache',
      mode: 'cors',
      headers: {
        'content-type': 'application/json',
        'authorization': `bearer ${ jwt }`,
      },
      method: 'DELETE',
    };

    return fetchResource(`${ API.DOCUMENTS }/${ documentId }`, requestConfig);
  }
}

export default DocumentService;
