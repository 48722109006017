import 'isomorphic-fetch';
import { fetchResource } from './fetch-resource';

import { API } from '../constants';
import _ from 'lodash';

/**
 * Product API service
 */
class ProductService {
  /**
   * Retrieves real estate products for listing in the marketplace
   * @example
   * getRealEstateProducts('someJwt').then(res => { return res.data }).then(...)
   * @returns {Promise} Returns a promise containing the result in json format.
   */
  static getRealEstateProducts = (jwt) => {
    const requestConfig = {
      cache: 'no-cache',
      mode: 'cors',
      headers: {
        'content-type': 'application/json',
        'authorization': `bearer ${ jwt }`,
      },
      method: 'GET',
    };

    return fetchResource(`${ API.PRODUCTS }?published=true&limit=5000`, requestConfig);
  }

  /**
   * Retrieves products for listing in the marketplace filtered by a specific lister id.
   * @example
   * getProductsForListing('someJwt').then(res => { return res.data }).then(...)
   * @returns {Promise} Returns a promise containing the result in json format.
   */
  static getProductsForListingByListerId = (jwt, listerId) => {
    const requestConfig = {
      cache: 'no-cache',
      mode: 'cors',
      headers: {
        'content-type': 'application/json',
        'authorization': `bearer ${ jwt }`,
      },
      method: 'GET',
    };

    return fetchResource(`${ API.PRODUCTS }?published=true&lister_id=${ listerId }`, requestConfig);
  }

  /**
   * Retrieves products by a collection of Ids
   * @example
   * getProductsForListing('someJwt' ['id1','id2']).then(res => { return res.data }).then(...)
   * @returns {Promise} Returns a promise containing the result in json format.
   */
  static getProductsByIdCollection = (jwt, productIdCollection = [], fields = undefined) => {

    const ids = productIdCollection && productIdCollection?.join(',');
    const requestConfig = {
      cache: 'no-cache',
      mode: 'cors',
      headers: {
        'content-type': 'application/json',
        'authorization': `bearer ${ jwt }`,
      },
      method: 'GET',
    };

    return fetchResource(`${ API.PRODUCTS }?_id=(${ ids })&limit=1000${fields ? `&fields=${fields?.join(',')}` : ''}`, requestConfig);
  }

  /**
   * Retrieve product for the id specified
   * @param {Token} jwt 
   * @param {product id} id the product id for the product to get.
   * @returns {Promise} Returns a promise containing the result in json format.
   */
  static getProductById = (jwt, id) => {
    const requestConfig = {
      cache: 'no-cache',
      mode: 'cors',
      headers: {
        'content-type': 'application/json',
        'authorization': `bearer ${ jwt }`,
      },
      method: 'GET',
    };
    return fetchResource(`${API.PRODUCTS}?_id=${id}`, requestConfig);
  }
}

export default ProductService;
