import { takeLatest, put, call } from 'redux-saga/effects';

import DepositReferenceService from '../../../api/deposit-reference.service';
import { PLEDGE_TYPES } from '../../types/pledge.types';
import PledgeActions from '../../actions/pledge.actions';

import SessionStore from '../../../utils/session-store';
import SessionContext from '../../../utils/session-context';

function generateDepositReference() {
  return function* (options) {
    try {
      const token = SessionStore.getSession();
      const userId = SessionContext.get(token).sub;
      const subsystem = SessionStore.getSubsystem();
      const { 
        productId,
        currency,
        baseFee,
        structureFee,
        marketingFee,
        blocktradeFee,
        amount,
        entityId,
        agreements,
        sourcesOfWealth,
        feesRevision,
        feesTimestamp,
      } = options.payload;

      const body = yield call(() => DepositReferenceService.generateDepositReference(token, userId, productId, amount, entityId, currency, baseFee, structureFee, marketingFee, blocktradeFee, agreements, sourcesOfWealth, feesRevision, feesTimestamp, subsystem));

      const result = body.data[0];

      const successAction = PledgeActions.generateDepositReferenceSuccess(result);

      yield put(successAction);
    } catch (error) {
      const failureAction = PledgeActions.generateDepositReferenceFailure(error);
      yield put(failureAction);
    }
  };
}

const newDepositReference = generateDepositReference();

export function* newDepositReferenceWatcher() {
  yield takeLatest(PLEDGE_TYPES.GENERATE_DEPOSIT_REFERENCE_BEGIN, newDepositReference);
}
