import PATH from '../paths';

const name = 'Private Wealth Global';
const key = 'privatewealth';
const imgUrl = `${ PATH.CDN }/whitelabels/${ key }`;
const images = {
  favicon: `${ imgUrl }/favicon.png`,
  logo: `${ imgUrl }/logo-dark.svg`,
  logoPDF: `${ imgUrl }/logo-pdf.png`,
  logoSidebar: `${ imgUrl }/logo-sidebar.svg`,
  opengraph: false,
  backgrounds: [
    `${ imgUrl }/background.jpg`
  ],
};

export default {

  name,
  key,
  images,
  stylesheet: `

    /* COLOR VARIABLES +++++++++++++++++++++++++++++++++++++++++++++++++++++  */

    --color-pwg-blueDark:       #0e173b;
    --color-pwg-blueDark-rgb:   14, 23, 59;
    --color-pwg-blue:           #1c2a56;
    --color-pwg-blue-rgb:       28, 42, 86;
    --color-pwg-tealDark:       #647a88;
    --color-pwg-gray:           #6c717c;
    --color-pwg-gold:           #cab36d;
    --color-pwg-goldDark:       #977a3c;
    --color-pwg-grayLight:      #f1f1ef;
    --color-pwg-grayLight-rgb:  241,241,239;

    --color-primary:                  var(--color-pwg-blueDark);
    --color-secondary:                var(--color-pwg-blueDark);
    --color-accent:                   var(--color-pwg-gold);

    /* END +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++  */

    /* TYPOGRAPHY ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++  */

    --color-link:                     var(--color-pwg-gold);
    --color-link-hover:               var(--color-pwg-blue);

    /* END +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++  */

    /* LAYOUTS +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++  */

    /* ANONYMOUS LAYOUT */

    --anonymous-logo-bg:              var(--color-white);
    --anonymous-copy-bg:              var(--color-white);
    --anonymous-heading-color:        var(--color-pwg-blue);
    --anonymous-subheading-color:     var(--color-pwg-gold);
    --anonymous-text-color            var(--color-pwg-tealDark);
    --anonymous-logo-height:          50px;
    --anonymous-bg:                   url(${ images.backgrounds[0] });
    --anonymous-bg-size:              cover;

    /* END */

    /* SIDEBAR */

    --sidebar-bg-color: var(--color-pwg-blueDark);
    --sidebar-logo-bg-color: var(--color-pwg-blue);
    --sidebar-logo-height: 45px;
    --sidebar-label-color: rgba(var(--color-white-rgb), 0.7);
    --sidebar-border-color: var(--color-pwg-blue);

    --sidebar-button-color: var(--color-accent);
    --sidebar-button-color-active: var(--color-pwg-blueDark);
    --sidebar-button-color-hover: var(--color-pwg-blueDark);
    --sidebar-button-bg-color: transparent;
    --sidebar-button-bg-color-active: var(--color-accent);
    --sidebar-button-bg-color-hover: var(--color-accent);
    --sidebar-button-border-color: var(--color-accent);
    --sidebar-button-border-color-active: var(--color-accent);
    --sidebar-button-border-color-hover: var(--color-accent;

    /* END */

    /* END +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++  */

    /* BUTTONS +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++  */

    --button-radius: 0!important;

    /* PRIMARY BUTTON */

    --btn-primary--bg:                var(--color-cashbox-blue);
    --btn-primary--border:            var(--color-cashbox-blue);
    --btn-primary--color:             var(--color-cashbox-gray);
    --btn-primary--colorH:            var(--color-cashbox-gray);
    --btn-primary--bgH:               var(--color-cashbox-gray);
    --btn-primary--borderH:           var(--color-cashbox-gray);
    --btn-primary--colorH:            var(--color-cashbox-blue);

    /* END */

    /* SECONDARY BUTTON */

    --btn-secondary--bg:              transparent;
    --btn-secondary--border:          var(--color-cashbox-blue);
    --btn-secondary--color:           var(--color-cashbox-gray);
    --btn-secondary--bgH:             transparent;
    --btn-secondary--borderH:         var(--color-cashbox-blue);
    --btn-secondary--colorH:          var(--color-cashbox-blue);

    /* END */

    /* END +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++  */

  `,
  
};
