import { getProductsWatcher } from './get-products.saga';
import { getProductByIdWatcher } from './get-productbyid.saga';
import { getMinimumsWatcher } from './get-investment-mins.saga';
import { getFeesWatcher } from './get-fees.saga';
import { getOrdersByProductIdWatcher } from './get-ordersbyproduct.saga';

export default [
  getProductsWatcher(),
  getProductByIdWatcher(),
  getMinimumsWatcher(),
  getFeesWatcher(),
  getOrdersByProductIdWatcher(),
];
