import ListerActions from "../actions/lister.actions";

import listerService from "../../api/lister.service";

import SessionStore from "../../utils/session-store";

export default {
  fetchLister: () => async (dispatch) => {
    dispatch(ListerActions.fetchListerBegin());

    const token = SessionStore.getSession();
    const listerName = SessionStore.getSubsystem();

    try {
      const lister = await listerService.getListerBySubsystem(
        token,
        listerName
      );
      dispatch(ListerActions.fetchListerSuccess(lister.data));
    } catch (err) {
      dispatch(ListerActions.fetchListerError(err));
    }
  },
};
