
export default class Logger {
  static initialize = () => {
    return undefined;
  };

  static identify = (id, name = '', email = '', meta = {}) => {
    const payload = { userId: id, name, email, meta };

    return payload;
  };

  static enabled = () => {
    return process.env.LOGROCKET_APP_ID &&
      process.env.LOGROCKET_ENABLED_BOOL &&
      process.env.LOGROCKET_ENABLED_BOOL === 'true' &&
      process.env.NODE_ENV === 'production';
  }
}
