import { CURRENCY_TYPES } from '../types';

const initialState = {
  status: 'idle',
  value: 'USD',
  modified: false,
  errors: undefined
};

const actionsMap = {
  [CURRENCY_TYPES.SET_CURRENCY_BEGIN]: (state) =>
    ({
      ...state,
      status: 'fetching',
    }),
  [CURRENCY_TYPES.SET_CURRENCY_SUCCESS]: (state, action) =>
    ({
      ...state,
      status: 'idle',
      value: action.payload.payload.currency,
      modified: action.payload.payload.isModified,
      errors: undefined,
    }),
  [CURRENCY_TYPES.SET_CURRENCY_ERROR]: (state, action) =>
    ({
      ...state,
      status: 'idle',
      value: undefined,
      modified: false,
      errors: action.payload,
    }),
};

export default function reducer(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
