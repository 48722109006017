export const DOCUMENT_TYPES = {
  DOWNLOADDOCUMENT_BEGIN: 'DOWNLOADDOCUMENT_BEGIN',
  DOWNLOADDOCUMENT_FAILURE: 'DOWNLOADDOCUMENT_FAILURE',
  DOWNLOADDOCUMENT_SUCCESS: 'DOWNLOADDOCUMENT_SUCCESS',
  GETDOCUMENTS_BEGIN: 'GETDOCUMENTS_BEGIN',
  GETDOCUMENTS_FAILURE: 'GETDOCUMENTS_FAILURE',
  GETDOCUMENTS_SUCCESS: 'GETDOCUMENTS_SUCCESS',
  GETALLDOCUMENTS_SUCCESS: 'GETALLDOCUMENTS_SUCCESS',
  GETALLDOCUMENTS_BEGIN: 'GETALLDOCUMENTS_BEGIN',
  GETALLDOCUMENTS_FAILURE: 'GETALLDOCUMENTS_FAILURE',
  GETDOCUMENTS_BY_ID_BEGIN: 'GETDOCUMENTS_BY_ID_BEGIN',
  GETDOCUMENTS_BY_ID_SUCCESS: 'GETDOCUMENTS_BY_ID_SUCCESS',
  GETDOCUMENTS_BY_ID_FAILURE: 'GETDOCUMENTS_BY_ID_FAILURE',
  GET_TRANSACTION_DOCUMENTS_BY_ID_BEGIN: 'GET_TRANSACTION_DOCUMENTS_BY_ID_BEGIN',
  GET_TRANSACTION_DOCUMENTS_BY_ID_SUCCESS: 'GET_TRANSACTION_DOCUMENTS_BY_ID_SUCCESS',
  GET_TRANSACTION_DOCUMENTS_BY_ID_FAILURE: 'GET_TRANSACTION_DOCUMENTS_BY_ID_FAILURE',
  GET_PRODUCT_INFO_DOCUMENT_BEGIN: 'GET_PRODUCT_INFO_DOCUMENT_BEGIN',
  GET_PRODUCT_INFO_DOCUMENT_SUCCESS: 'GET_PRODUCT_INFO_DOCUMENT_SUCCESS',
  GET_PRODUCT_INFO_DOCUMENT_FAILURE: 'GET_PRODUCT_INFO_DOCUMENT_FAILURE',
  UPLOAD_DOCUMENT_BEGIN: 'UPLOAD_DOCUMENT_BEGIN',
  UPLOAD_DOCUMENT_FAILURE: 'UPLOAD_DOCUMENT_FAILURE',
  UPLOAD_DOCUMENT_SUCCESS: 'UPLOAD_DOCUMENT_SUCCESS',
  DELETE_DOCUMENT_BEGIN: 'DELETE_DOCUMENT_BEGIN',
  DELETE_DOCUMENT_FAILURE: 'DELETE_DOCUMENT_FAILURE',
  DELETE_DOCUMENT_SUCCESS: 'DELETE_DOCUMENT_SUCCESS',
};
