import { takeLatest, put, call } from 'redux-saga/effects';
import { push } from 'connected-react-router'

import SessionStore from '../../../utils/session-store';
import SessionContext from '../../../utils/session-context';
import SessionActions from '../../actions/session.actions';
import EntityService from '../../../api/entity.service';
import UserActions from '../../actions/user.actions';
import { SESSION_TYPES } from '../../types/session.types'


function createHydrateState() {
  return function* (options) {
    try {
      const jwt = SessionStore.getSession();
      const context = SessionContext.get(jwt);
      const cachedEntityId = SessionStore.getActiveEntityId();

      const entitiesBody = yield call(() => EntityService.getEntitiesForUser(jwt, context.sub));
      const entities = entitiesBody.data;
      const activeEntity = entities.find(e => e._id === cachedEntityId) || entities[0];

      //sign user in
      yield put(SessionActions.signinSuccess(context.entities, context.email, context.accountVerified, context.legacy,
        context.demandPartner, context.channelPartner, SessionStore.getSubsystem()));
      //set active entity
      yield put(UserActions.getEntities());
      yield put(UserActions.selectActiveEntity(activeEntity));

      yield put(SessionActions.hydrateStateSuccess())
    } catch (error) {
      yield put(SessionActions.hydrateStateFailure())
    }
  };
}

export const hydrateState = createHydrateState();

export function* getHydrateStateWatcher() {
  yield takeLatest(SESSION_TYPES.HYDRATE_STATE_BEGIN, hydrateState);
}
