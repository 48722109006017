import { Map } from 'immutable';
import state from '../sagas/state';

import { INVESTMENT_TYPES } from '../types/investment.types';

/**
 * Redux Reducer for investments
 */

const initialState = {
  loading: false,
  item: undefined,
  fees: undefined,
  orders: undefined
};

const actionsMap = {
  [INVESTMENT_TYPES.GETINVESTMENTBYID_BEGIN]: (state) =>
    ({
      ...state,
      loading: true,
      error: undefined,
    }),
  [INVESTMENT_TYPES.GETINVESTMENTBYID_FAILURE]: (state, action) =>
    ({
      ...state,
      loading: false,
      error: action.payload.error,
    }),
  [INVESTMENT_TYPES.GETINVESTMENTBYID_SUCCESS]: (state, action) =>
    ({
      ...state,
      item: action.payload.investment,
      loading: false,
      error: undefined,
    }),
  [INVESTMENT_TYPES.GET_FEES_BEGIN]: (state) =>
    ({
      ...state,
      loading: true,
      error: undefined,
    }),
  [INVESTMENT_TYPES.GET_FEES_FAILURE]: (state, action) =>
    ({
      ...state,
      fees: false,
      loading: false,
      error: action?.payload?.error,
    }),
  [INVESTMENT_TYPES.GET_FEES_SUCCESS]: (state, action) =>
    ({
      ...state,
      fees: action?.payload?.fees,
      loading: false,
      error: undefined,
    }),
  [INVESTMENT_TYPES.GETORDERSBYPRODUCTID_BEGIN]: (state) =>
    ({
      ...state,
      loading: true,
      error: undefined,
    }),
  [INVESTMENT_TYPES.GETORDERSBYPRODUCTID_FAILURE]: (state, action) =>
    ({
      ...state,
      loading: false,
      error: action.payload.error,
    }),
  [INVESTMENT_TYPES.GETORDERSBYPRODUCTID_SUCCESS]: (state, action) =>
    ({
      ...state,
      orders: action.payload.orders,
      loading: false,
      error: undefined,
    }),
};

export default function reducer(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
