import state from '../sagas/state';

import { DOCUMENT_TYPES } from '../types/document.types';

/**
 * Redux Reducer for documents
 */

const initialState ={
  loading: false,
  items: [],
};

function onlyUnique(value, index, self) { 
  return self.indexOf(value) === index;
}

const actionsMap = {
  [DOCUMENT_TYPES.GETDOCUMENTS_BEGIN]: (state) =>
  { 
      return {
      ...state,
      loading: true,
      error: undefined,
    }},
  [DOCUMENT_TYPES.GETDOCUMENTS_FAILURE]: (state, action) =>
    ({
      ...state,
      loading: false,
      error: action.payload.error,
    }),
  [DOCUMENT_TYPES.GETDOCUMENTS_SUCCESS]: (state, action) =>
  {
      const allDocs = [...state.items, ...action.payload.documents];
      const uniqueDocs = allDocs.map(i => i._id).filter(onlyUnique).map(id => allDocs.find(d => d._id === id))
      return {
      ...state,
      items: uniqueDocs,
      loading: false,
      error: undefined,
    }},
  [DOCUMENT_TYPES.GETALLDOCUMENTS_BEGIN]: (state) =>
  { 
      return {
      ...state,
      loading: true,
      error: undefined,
    }},
  [DOCUMENT_TYPES.GETALLDOCUMENTS_FAILURE]: (state, action) =>
    ({
      ...state,
      loading: false,
      error: action.payload.error,
    }),
  [DOCUMENT_TYPES.GETALLDOCUMENTS_SUCCESS]: (state, action) =>
  {
      const allDocs = [...action.payload.documents];
      const uniqueDocs = allDocs.map(i => i._id).filter(onlyUnique).map(id => allDocs.find(d => d._id === id))
      return {
      ...state,
      items: uniqueDocs,
      loading: false,
      error: undefined,
    }},
  [DOCUMENT_TYPES.GETDOCUMENTS_BY_ID_BEGIN]: (state) =>
    ({
      ...state,
      loading: true,
      error: undefined,
    }),
  [DOCUMENT_TYPES.GETDOCUMENTS_BY_ID_SUCCESS]: (state, action) =>
    ({
      ...state,
      items: action.payload.documents || [],
      loading: false,
      error: undefined,
    }),
  [DOCUMENT_TYPES.GETDOCUMENTS_BY_ID_FAILURE]: (state, action) =>
    ({
      ...state,
      loading: false,
      error: action.payload.error,
    }),
[DOCUMENT_TYPES.GET_TRANSACTION_DOCUMENTS_BY_ID_BEGIN]: (state) =>
    ({
      ...state,
      loading: true,
      error: undefined,
    }),
  [DOCUMENT_TYPES.GET_TRANSACTION_DOCUMENTS_BY_ID_SUCCESS]: (state) =>
    ({
      ...state,
      loading: false,
      error: undefined,
    }),
  [DOCUMENT_TYPES.GET_TRANSACTION_DOCUMENTS_BY_ID_FAILURE]: (state, action) =>
    ({
      ...state,
      loading: false,
      error: action.payload.error,
    }),
[DOCUMENT_TYPES.GET_PRODUCT_INFO_DOCUMENT_BEGIN]: (state) =>
    ({
      ...state,
      loading: true,
      error: undefined,
    }),
  [DOCUMENT_TYPES.GET_PRODUCT_INFO_DOCUMENT_SUCCESS]: (state) =>
    ({
      ...state,
      loading: false,
      error: undefined,
    }),
  [DOCUMENT_TYPES.GET_PRODUCT_INFO_DOCUMENT_FAILURE]: (state, action) =>
    ({
      ...state,
      loading: false,
      error: action.payload.error,
    }),
  [DOCUMENT_TYPES.UPLOAD_DOCUMENT_BEGIN]: (state) =>
    ({
      ...state,
      loading: true,
      error: undefined,
    }),
  [DOCUMENT_TYPES.UPLOAD_DOCUMENT_SUCCESS]: (state, action) => {
    const documents = state.items; // eslint-disable-line
    const new_documents = documents && documents.length > 0 ? // eslint-disable-line
    [...documents, action.payload.document] : [action.payload.document]; // eslint-disable-line

    return {
      ...state,
      items: new_documents,
      loading: false,
      error: undefined,
    }
  },
  [DOCUMENT_TYPES.UPLOAD_DOCUMENT_FAILURE]: (state, action) =>
    ({
      ...state,
      loading: false,
      error: action.payload.error,
    }),
  // delete reducers
  [DOCUMENT_TYPES.DELETE_DOCUMENT_BEGIN]: (state) =>
    ({
      ...state,
      loading: true,
      errors: undefined,
    }),
  [DOCUMENT_TYPES.DELETE_DOCUMENT_SUCCESS]: (state, action) => {
    const documents = state.items;
    const new_documents = documents && documents.length > 0 ?
      documents?.filter(d => d._id !== action.payload.documentId) : [];
    return {
      ...state,
        loading: false,
        items: new_documents,
        errors: undefined,
    }
  },
  [DOCUMENT_TYPES.DELETE_DOCUMENT_FAILURE]: (state, action) =>
    ({
      ...state,
      loading: false,
      errors: action.payload.error,
    }),
};

export default function reducer(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
