import { Map } from 'immutable';
import state from '../sagas/state';

import { WALLETS_TYPES } from '../types/wallets.types';

/**
 * Redux Reducer for investments
 */

const initialState = {
  transactionsLoading: false,
  walletsLoading: false,
  fundSuccess: false,
  wallet: [],
};

const actionsMap = {
  [WALLETS_TYPES.GETWALLETS_BEGIN]: (state) =>
    ({
      ...state,
      walletsLoading: true,
      fundSuccess: false,
      error: undefined,
    }),
  [WALLETS_TYPES.GETWALLETS_FAILURE]: (state, action) =>
    ({
      ...state,
      walletsLoading: false,
      fundSuccess: false,
      error: action.payload.error,
    }),
  [WALLETS_TYPES.GETWALLETS_SUCCESS]: (state, action) =>
    ({
      ...state,
      wallet: action.payload.wallets,
      walletsLoading: false,
      fundSuccess: false,
      error: undefined,
    }),
  [WALLETS_TYPES.GETWALLETTRANSACTIONS_BEGIN]: (state) =>
    ({
      ...state,
      transactionsLoading: true,
      fundSuccess: false,
      error: undefined,
    }),
  [WALLETS_TYPES.GETWALLETTRANSACTIONS_FAILURE]: (state, action) =>
    ({
      ...state,
      transactionsLoading: false,
      fundSuccess: false,
      error: action.payload.error,
    }),
  [WALLETS_TYPES.GETWALLETTRANSACTIONS_SUCCESS]: (state, action) => 
    ({
      ...state,
      wallet: {
        ...state?.wallet,
        wallets: state?.wallet?.wallets?.map(w => 
          ({ ...w, transactions: action.payload.transactions.filter(ts => ts.currency_iso === w.currency_iso)[0]?.transactions || [] })
        ),
      },
      transactionsLoading: false,
      fundSuccess: false,
      error: undefined,
    }),
  [WALLETS_TYPES.WALLETWITHDRAW_BEGIN]: (state) =>
    ({
      ...state,
      transactionsLoading: true,
      fundSuccess: false,
      error: undefined,
    }),
  [WALLETS_TYPES.WALLETWITHDRAW_FAILURE]: (state, action) =>
    ({
      ...state,
      transactionsLoading: false,
      fundSuccess: false,
      error: action.payload.error,
    }),
  [WALLETS_TYPES.WALLETWITHDRAW_SUCCESS]: (state) =>
    ({
      ...state,
      fundSuccess: true,
      transactionsLoading: false,
      error: undefined,
    }),
};

export default function reducer(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
