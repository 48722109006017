import React from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';

import AuthenticatedLayout from '../../assets/layouts/authenticated';

/**
 * Wrapper around react router routes which require authenticated users.
 * @example
 * <AuthenticatedRoute
 *  path={ '/some-path' }
 *  RenderComponent={ (props) => <AnyComponent { ...props } /> }
 *  FallbackComponent={ (props) => <SomeOtherComponentIfNotAuthenticated /> }
 *  isLoggedIn={ loggedInState }
 */
const AuthenticatedRoute = ({
  path,
  RenderComponent,
  FallbackComponent,
  isLoggedIn,
  theme_name,
  ...rest
}) => {
  return (
    <AuthenticatedLayout
      theme={ theme_name }
    >
      <Route
        { ...rest }
        render={ (props) => (isLoggedIn === true
          ? RenderComponent(props)
          : FallbackComponent(props)) }
      />
    </AuthenticatedLayout>
  );
};

AuthenticatedRoute.propTypes = {
  RenderComponent: PropTypes.func,
  FallbackComponent: PropTypes.func,
  isLoggedIn: PropTypes.bool,
  theme_name: PropTypes.string,
};

export default AuthenticatedRoute;
