import { all } from 'redux-saga/effects';
import countrySagas from './countries';
import sessionSagas from './session';
import investmentsSagas from './investments';
import userSagas from './user';
import portfolioSagas from './portfolio';
import walletSagas from './wallets';
import globalSagas from './global';
import documentSagas from './documents';
import pledgeSagas from './pledges';
import demandPartnerSagas from './demand-partners';
import stateSagas from './state';
import kycSagas from './kyc';

export default function* rootSaga() {
  yield all([
    ...pledgeSagas,
    ...countrySagas,
    ...sessionSagas,
    ...kycSagas,
    ...investmentsSagas,
    ...userSagas,
    ...portfolioSagas,
    ...walletSagas,
    ...documentSagas,
    ...globalSagas,
    ...demandPartnerSagas,
    ...stateSagas,
  ]);
}
