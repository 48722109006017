import { takeLatest, put, call } from 'redux-saga/effects';

import { WALLETS_TYPES } from '../../types';
import WalletActions from '../../actions/wallets.actions';
import walletService from '../../../api/wallets.service';
import SessionStore from '../../../utils/session-store';

/**
 * Redux Saga generator function for withdrawing funds
 */
function createWithdrawWallet() {
  return function* (options) {
    try {
      const token = SessionStore.getSession();
      const { entity_id, currencyIso, amount, integrationId } = options.payload

      yield call(() => walletService.withdrawFunds(token, entity_id, currencyIso, amount, integrationId));
      yield put(WalletActions.successWalletWithdraw());
    } catch (error) {
      const failureAction = WalletActions.errorWalletWithdraw(error);
      yield put(failureAction);
    }
  };
}

export const withdrawWallet = createWithdrawWallet();

export function* withdrawWalletWatcher() {
  yield takeLatest(WALLETS_TYPES.WALLETWITHDRAW_BEGIN, withdrawWallet);
}
