import { takeLatest, put, call, all } from 'redux-saga/effects';

import entityService from '../../../api/entity.service';
import { USER_TYPES } from '../../types';
import AlertActions from '../../actions/alert.actions';
import UserActions from '../../actions/user.actions';
import Effect from '../../actions/helpers/effect';

import SessionStore from '../../../utils/session-store';
import SessionContext from '../../../utils/session-context';
import ERROR_MESSAGES from '../../../constants/error-message';

/**
 * Redux Saga generator function for getting user entities
 */
function createGetEntities() {
  return function* (options) {
    try {
      const token = SessionStore.getSession();
      const userId = SessionContext.get(token).sub;

      const body = yield call(() => entityService.getEntitiesForUser(token, userId));
      const entities = body.data;

      if (entities[0]) {
        yield put(UserActions.getCanInvest(entities[0]._id)); //eslint-disable-line
      }
      yield put(UserActions.getEntitiesSuccess(entities));
    } catch (error) {
      const failureAction = UserActions.getEntitiesError(error);
      yield put(failureAction);
    }
  };
}

export const getEntities = createGetEntities();

export function* getEntitiesWatcher() {
  yield takeLatest(USER_TYPES.GET_ENTITIES_BEGIN, getEntities);
}
