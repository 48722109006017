import 'isomorphic-fetch';
import { fetchResource } from './fetch-resource';

import { API } from '../constants';

class UserLevelsService {
  /**
   * Gets the user levels for a specific user
   * @example
   * emailLogin('some email', 'some password').then(res => { return res.data }).then(...)
   * @returns {Promise} Returns a promise containing the result of the HTTP operation.
   */
  static getUserLevels = (jwt, userId) => {

    const requestConfig = {
      cache: 'no-cache',
      mode: 'cors',
      headers: {
        'content-type': 'application/json',
        'authorization': `bearer ${ jwt }`,
      },
      method: 'GET',
    };

    return fetchResource(`${ API.USER_LEVELS }/users/${ userId }`, requestConfig);
  }
}

export default UserLevelsService;
