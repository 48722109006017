import { takeLatest, put, call } from 'redux-saga/effects';

import userService from '../../../api/user.service';
import { SESSION_TYPES } from '../../types';
import SessionActions from '../../actions/session.actions';
import SessionStore from '../../../utils/session-store';
import Action from '../../actions/helpers/action';
import SessionContext from '../../../utils/session-context';

/**
 * Redux Saga generator function for sign in
 */
function createResetPassword() {
  return function* (options) {
    try {
      const token = options.payload.token;
      const newPassword = options.payload.password;

      const system = process.env.REACT_APP_API_SYSTEM;
      const subsystem = options.payload.subsystem || process.env.REACT_APP_API_SUBSYSTEM;

      const body = yield call(() => userService.passwordResetComplete(token, newPassword, system, subsystem));
      const result = body.data[0];

      const successAction = SessionActions.resetPasswordSuccess();

      yield put(successAction);

      SessionStore.setSession(result.access_token, result.refresh_token, subsystem);
      const context = SessionContext.get(result.access_token);

      const setSignedInAction = SessionActions.signinSuccess(context.entities, context.email, context.accountVerified, context.legacy);

      yield put(setSignedInAction);
    } catch (error) {
      SessionActions.resetPasswordFailure(Action.withDisplayMessage(error, 'Verification mails are only valid for 24 hours. It is possible your token has expired or is invalid.'));
    }
  };
}

export const resetPassword = createResetPassword();

export function* getResetPasswordWatcher() {
  yield takeLatest(SESSION_TYPES.RESET_PASSWORD_BEGIN, resetPassword);
}
