import { getDocumentWatcher } from './get-documents.saga';
import { getAllDocumentWatcher } from './get-all-documents.saga';
import { getDocumentsByIdWatcher } from './get-documents-by-id.saga';
import { getDocumentUploadWatcher } from './upload-documents.saga';
import { deleteDocumentWatcher } from './delete-documents.saga';
import { getTransactionDocumentsByIdWatcher } from './get-transaction-documents-by-id.saga';
import { getProductInfoDocumentWatcher } from './get-product-info-document.saga';


export default [
  getDocumentWatcher(),
  getDocumentsByIdWatcher(),
  getTransactionDocumentsByIdWatcher(),
  getDocumentUploadWatcher(),
  deleteDocumentWatcher(),
  getProductInfoDocumentWatcher(),
  getAllDocumentWatcher(),
];
