/*
  A collection of Success and Failure messages displayed when user completes steps in the 'My Profile' section.
*/

const PROFILE_FLOW_MESSAGES = {
  country_of_incorporation_success: 'Country of Incorporation details successfully submitted.',
  company_basic_info: 'Basic info successfully submitted.',
  accreditation_success: 'Entity information submitted successfully.',
  personal_info_success: 'Personal Info successfully submitted.',
  physical_address_success: 'Physical address successfully submitted.',
  banking_details_success: 'Banking details successfully submitted.',
  document_submission_success: 'Documents successfully submitted for review.',
  form_submission_error: 'An error occured while submitting the form.',
  investor_profile_error: 'The current investor profile is either incomplete or not currently supported due to legislation.',
};

export default PROFILE_FLOW_MESSAGES;
