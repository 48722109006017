class Fees {
  /**
   * A function to apply fees to an amount supplied by the client.
   */
  static applyFees = (feeRates, subTotal) => {
    return {
      base: this.round((((feeRates?.base || 0) / 100) * subTotal)),
      structure: this.round((((feeRates?.structure || 0) / 100) * subTotal)),
      cap: this.round((((feeRates?.cap || 0) / 100) * subTotal)),
      blocktrade_fee: this.round((((feeRates?.blocktrade_fee || 0) / 100) * subTotal)),
    };
  }

  /**
   * A function to floor floating point numbers to 2 decimals
   * ex 10.299999 -> 10.29
   */
  static round = (number) => {
    return Math.round(number * 100) / 100
  } 
}

export default Fees;
