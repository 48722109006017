const DOCUMENT_TAGS = {
  passport: 'Passport',
  drivers: 'Drivers Licence',
  drivers_front: 'Drivers Licence Front',
  drivers_back: 'Drivers Licence Back',
  id_document: 'ID Document',
  id_document_front: 'ID Document Front',
  id_document_back: 'ID Document Back',
  proof_of_address: 'Proof Of Physical Address',
  bank_statement: 'Bank Statement/Cancelled Cheque',
  certificate_of_incorp: 'Certificate Of Incorporation',
  articles_of_association: 'Articles Of Association',
  proof_of_shareholding: 'Proof Of Shareholding',
  proof_of_identity: 'Proof Of Identity',
  trust_deed: 'Trust Deed',
  other: 'Other',
  selfie_proof_of_identification: 'Selfie - Proof of Identity',
  selfie_proof_of_address: 'Selfie - Proof of Address',
  source_of_wealth: 'Source Of Wealth',
  cig_articles_of_association: 'CIG - Articles of Association',
  cig_certificate_of_incorp: 'CIG - Certificate of Incorporation',
  cig_bank_statement: 'CIG - Company Bank Statement',
  cig_proof_of_address: 'CIG - Proof of Address',
  cig_resolution: 'CIG - Resolution',
  cig_source_of_funds: 'CIG - Source of Funds',
  cig_trust_bank_statement: 'CIG - Trust Bank Statement',
  cig_trust_deed_last_page: 'CIG - Trust Deed Last Page',
  cig_trust_deed_first_page: 'CIG - Trust Deed Page 1',
};

export default DOCUMENT_TAGS;