export const INVESTMENTS_TYPES = {
  GET_INVESTMENTS_BEGIN: 'GET_INVESTMENTS_BEGIN',
  GET_INVESTMENTS_FAILURE: 'GET_INVESTMENTS_FAILURE',
  GET_INVESTMENTS_SUCCESS: 'GET_INVESTMENTS_SUCCESS',

  GET_PRODUCT_MINIMUMS_BEGIN: 'GET_PRODUCT_MINIMUMS_BEGIN',
  GET_PRODUCT_MINIMUMS_FAILURE: 'GET_PRODUCT_MINIMUMS_FAILURE',
  GET_PRODUCT_MINIMUMS_SUCCESS: 'GET_PRODUCT_MINIMUMS_SUCCESS',

  GET_PRODUCT_FEES_BEGIN: 'GET_PRODUCT_FEES_BEGIN',
  GET_PRODUCT_FEES_FAILURE: 'GET_PRODUCT_FEES_FAILURE',
  GET_PRODUCT_FEES_SUCCESS: 'GET_PRODUCT_FEES_SUCCESS',
};
