import { takeEvery, put, call } from "redux-saga/effects";

import { USER_TYPES } from "../../types";
import SessionStore from "../../../utils/session-store";
import EntityService from "../../../api/entity.service";
import UserActions from "../../actions/user.actions";
import DocumentService from "../../../api/document.service";
import DOCUMENT_TAGS from "../../../constants/document-tags";
import DocumentAction from "../../actions/document.actions";

/**
 * Used for when user tries to upload bank statement when bank account creation is still in progress.
 * ie. when no bank_account._id is present to pass as the associated id for the document uploaded
 */
function createAssociateBankSatement() {
  return function* (options) {
    try {
      const token = SessionStore.getSession();
      const { bankAccount, statement } = options.payload;

      const body = yield call(() =>
        EntityService.createBankAccountsforEntity(token, bankAccount)
      );
      const bankAcc = body.data?.[0];
      const docBody = yield call(() =>
        DocumentService.uploadFile(
          token,
          statement,
          bankAcc?._id,
          [DOCUMENT_TAGS.bank_statement],
          "",
          true,
          false
        )
      );

      yield put(UserActions.createBankAccountSuccess(bankAcc));
      yield put(DocumentAction.uploadDocumentSuccess(docBody.data[0]));
      yield put(UserActions.associateBankStatementSuccess());
    } catch (error) {
      const failureAction = UserActions.associateBankStatementFailure(error);
      yield put(failureAction);
    }
  };
}

export const associateBankStatement = createAssociateBankSatement();

export function* associateBankStatementWatcher() {
  yield takeEvery(
    USER_TYPES.ASSOCIATE_BANK_STATEMENT_BEGIN,
    associateBankStatement
  );
}
